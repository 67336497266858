import React, { useState, useEffect } from "react";

const Shop = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [cart, setCart] = useState([]);
    const [showGiftModal, setShowGiftModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [filter, setFilter] = useState({
        category: "",
        name: "",
        sort: "",
        available: "",
    });
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        let loadedCount = 0;
        const totalRequests = 3; // Общее количество запросов

        const updateLoadingProgress = () => {
            loadedCount++;
            const progressPercentage = (loadedCount / totalRequests) * 100;
            setLoadingProgress(progressPercentage);
        };

        fetch("/shop/json")
            .then((res) => res.json())
            .then((data) => {
                setProducts(data[0]);
                updateLoadingProgress(); // Обновляем прогресс после успешной загрузки данных
            })
            .catch((error) => {
                console.error("Ошибка загрузки данных:", error);
            });

        fetch("/shop/json/ShopCategory")
            .then((res) => res.json())
            .then((data) => {
                setCategories(data[0]);
                updateLoadingProgress();
            })
            .catch((error) => {
                console.error("Ошибка загрузки категорий:", error);
            });

        fetch("/users/userListHomeJson")
            .then((res) => res.json())
            .then((data) => {
                setUsers(data);
                updateLoadingProgress();
            })
            .catch((error) => {
                console.error("Ошибка загрузки пользователей:", error);
            });
    }, []);

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const handleAddToCart = (product) => {
        const existingProductIndex = cart.findIndex(
            (item) => item.id === product.id
        );

        if (existingProductIndex !== -1) {
            const updatedCart = [...cart];
            updatedCart[existingProductIndex].quantity += 1;
            setCart(updatedCart);
        } else {
            setCart([...cart, { ...product, quantity: 1 }]);
        }

        setShowModal(true);
        setModalMessage(`${product.name} добавлен в корзину.`);
    };

    const handleRemoveFromCart = (productId) => {
        const updatedCart = cart.filter((item) => item.id !== productId);
        setCart(updatedCart);
    };

    const handleGift = (product) => {
        setSelectedProduct(product);
        setShowGiftModal(true);
    };

    const handleGiftPurchase = (userId) => {
        if (!userId) {
            setModalMessage("Пожалуйста, выберите пользователя для подарка.");
            return;
        }

        const giftData = {
            productId: selectedProduct.id,
            recipientId: userId,
        };

        fetch("/shop/gift", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(giftData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setSelectedProduct(null);
                    setShowGiftModal(false);
                    fetch("/shop/json")
                        .then((response) => response.json())
                        .then((data) => setProducts(data));
                    setModalMessage("Товар успешно подарен.");
                } else {
                    setModalMessage(
                        "Ошибка при покупке в подарок. Пожалуйста, попробуйте позже."
                    );
                }
            })
            .catch((error) => {
                console.error("Ошибка при покупке в подарок:", error);
                setModalMessage(
                    "Ошибка при покупке в подарок. Пожалуйста, попробуйте позже."
                );
            });
    };

    const token = document.head.querySelector(
        'meta[name="csrf-token"]'
    ).content;

    const handlePurchase = (productId, forSelf) => {
        const purchaseData = {
            productId,
            forSelf,
            selectedUserId: selectedUserId, // Получаем значение из состояния
        };

        fetch("/shop/purchase", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": token,
            },
            body: JSON.stringify(purchaseData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    fetch("/shop/json")
                        .then((response) => response.json())
                        .then((data) => setProducts(data));
                    setModalMessage("Товар успешно куплен.");
                } else {
                    setModalMessage(
                        "Ошибка при покупке. Пожалуйста, попробуйте позже."
                    );
                }
            })
            .catch((error) => {
                console.error("Ошибка при покупке:", error);
                setModalMessage(
                    "Ошибка при покупке. Пожалуйста, попробуйте позже."
                );
            });
    };

    const getTotalPrice = () => {
        return cart.reduce(
            (total, item) => total + item.summ * item.quantity,
            0
        );
    };

    const openCart = () => {
        setShowGiftModal(false);
        setShowModal(true);
        setModalMessage("Открываем корзину.");
    };

    const filteredProducts = products.filter((product) => {
        return (
            (filter.name
                ? product.name.toLowerCase().includes(filter.name.toLowerCase())
                : true) &&
            (filter.category
                ? product.cat === Number(filter.category)
                : true) &&
            (filter.available
                ? filter.available === "да"
                    ? product.shop === "да"
                    : product.shop === "нет"
                : true)
        );
    });

    // Добавляем сортировку, если нужно
    if (filter.sort === "price-asc") {
        filteredProducts.sort((a, b) => a.summ - b.summ);
    } else if (filter.sort === "price-desc") {
        filteredProducts.sort((a, b) => b.summ - a.summ);
    }

    const ProductModal = ({ product, onClose }) => {
        const handleUserSelect = (e) => {
            setSelectedUserId(e.target.value);
        };

        return (
            <div
                className="modal show d-block"
                tabIndex="-1"
                style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{product.name}</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <img
                                src={`/storage/${product.image}`}
                                className="img-fluid mx-auto d-block"
                                alt={product.name}
                                style={{ maxHeight: "50vh" }}
                            />
                            <h4>
                                Цена: {product.summ}{" "}
                                <img
                                    className="meow-coin"
                                    src="/img/meow.svg"
                                />{" "}
                            </h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.body,
                                }}
                            />
                            <div className="card-footer">
                                <div>
                                    <label htmlFor="userSelect">
                                        Выберите пользователя:
                                    </label>
                                    <select
                                        id="userSelect"
                                        className="form-control"
                                        value={selectedUserId}
                                        onChange={handleUserSelect}
                                    >
                                        <option value="">Выбрать...</option>

                                        {Array.isArray(users) &&
                                            users.map((user) => (
                                                <option
                                                    key={user.id}
                                                    value={user.id}
                                                >
                                                    {user.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>

                                <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                        handlePurchase(product.id, true)
                                    }
                                >
                                    Купить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const openConfirmationModal = (action, product, forSelf = false) => {
        setConfirmationAction({ action, product, forSelf });
        setShowConfirmationModal(true);
    };

    return (
        <div className="container mt-3">
            {loadingProgress < 100 ? (
                <div className="card-body">
                    Загружаем МяуМаркет...
                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow={loadingProgress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${loadingProgress}%` }}
                        >
                            {loadingProgress.toFixed(0)}%
                        </div>
                    </div>
                </div>
            ) : null}

            {loadingProgress === 100 ? (
                <div className="row">
                    {/* Фильтры */}
                    <div className="col-md-3">
                        <div className="border  position-fixed filters m-1 p-5">
                            <input
                                type="text"
                                className="form-control mb-2"
                                name="name"
                                placeholder="Название"
                                onChange={handleFilterChange}
                            />
                            <select
                                className="form-select mb-2"
                                name="category"
                                onChange={handleFilterChange}
                            >
                                <option value="">Выберите категорию</option>
                                {categories.map((category) => (
                                    <option
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                            <select
                                className="form-select mb-2"
                                name="available"
                                onChange={handleFilterChange}
                            >
                                <option value="">Все товары</option>
                                <option value="да">В наличии</option>
                                <option value="нет">Нет в наличии</option>
                            </select>
                            <select
                                className="form-select mb-2"
                                name="sort"
                                onChange={handleFilterChange}
                            >
                                <option value="">Сортировать</option>
                                <option value="price-asc">
                                    Цена: Низкая - Высокая
                                </option>
                                <option value="price-desc">
                                    Цена: Высокая - Низкая
                                </option>
                            </select>

                            {/* Кнопка корзины */}
                            <div className="my-3">
                                <button
                                    className="btn btn-warning"
                                    onClick={openCart}
                                >
                                    Открыть корзину
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Карточки товаров */}
                    <div className="col-md-9">
                        <div className="row">
                            {filteredProducts.map((product) => (
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="col-md-4 mb-4"
                                    key={product.id}
                                    onClick={() => setSelectedProduct(product)}
                                >
                                    <div className="card h-100">
                                        <img
                                            src={`/storage/${product.image}`}
                                            className="card-img-top"
                                            alt={product.name}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                {product.name}
                                            </h5>
                                        </div>
                                        <div className="card-footer">
                                            {product.shop === "да"
                                                ? "Доступен для покупки"
                                                : "Недоступен для покупки"}
                                            <h4 className="card-text">
                                                <small className="text-muted">
                                                    Цена: {product.summ}{" "}
                                                    <img
                                                        className="meow-coin"
                                                        src="/img/meow.svg"
                                                    />{" "}
                                                </small>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
            {selectedProduct && (
                <ProductModal
                    product={selectedProduct}
                    onClose={() => setSelectedProduct(null)}
                />
            )}

            {/* Модальное окно корзины */}
            {showModal && (
                <div
                    className="modal show d-block"
                    tabIndex="-1"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Корзина</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <h3>Список всех ваших покупок</h3>
                                {cart.length === 0 ? (
                                    <p>Корзина пуста</p>
                                ) : (
                                    <>
                                        <ul className="list-group">
                                            {cart.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    {item.name} x{item.quantity}
                                                    <span>
                                                        {item.summ *
                                                            item.quantity}{" "}
                                                    </span>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() =>
                                                            handleRemoveFromCart(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        Удалить
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="my-2">
                                            Общая сумма: {getTotalPrice()} руб.
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                alert(
                                                    "Переход к оформлению заказа"
                                                )
                                            }
                                        >
                                            Оформить заказ
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Фон модального окна, закрывает страницу при активном модальном окне */}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default Shop;
