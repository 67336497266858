import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NewDialogForm = ({ onDialogCreated }) => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [dialogName, setDialogName] = useState('');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        axios.get('/users/json')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Ошибка при загрузке пользователей:', error));
    }, []);

    const handleUserSelection = (userId) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter(id => id !== userId)
                : [...prevSelectedUsers, userId]
        );
    };

    const handleDepartmentChange = (e) => {
        setFilterDepartment(e.target.value);
        filterUsers(e.target.value, filterName);
    };

    const handleNameChange = (e) => {
        setFilterName(e.target.value);
        filterUsers(filterDepartment, e.target.value);
    };

    const filterUsers = (department, name) => {
        const filtered = users.filter(user => {
            if (department && user.user_details && user.user_details.department) {
                return user.user_details.department.name.toLowerCase().includes(department.toLowerCase());
            }
            if (name) {
                return user.name.toLowerCase().includes(name.toLowerCase());
            }
            return true;
        });
        setFilteredUsers(filtered);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('/chat/dialogs', {
            name: dialogName,
            participants: selectedUsers
        })
        .then(response => {
            setDialogName('');
            setSelectedUsers([]);
            onDialogCreated(response.data.dialog);
        })
        .catch(error => console.error('Ошибка при создании диалога:', error));
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                                type="text"
                                value={dialogName}
                                onChange={e => setDialogName(e.target.value)}
                                className="form-control"
                                placeholder="Название диалога"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                value={filterDepartment}
                                onChange={handleDepartmentChange}
                                className="form-control"
                                placeholder="Фильтр по отделу"
                            />
                            <input
                                type="text"
                                value={filterName}
                                onChange={handleNameChange}
                                className="form-control"
                                placeholder="Фильтр по имени"
                            />
                        </div>
                        <div className="mb-3">
                            {filteredUsers.map(user => (
                                <div key={user.id} className="d-flex align-items-center mb-2">
                                    <input
                                        type="checkbox"
                                        value={user.id}
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => handleUserSelection(user.id)}
                                        className="form-check-input me-2"
                                    />
                                    <div>
                                        <h6>{user.name}</h6>
                                        {user.user_details && user.user_details.department && (
                                            <p>Отдел: {user.user_details.department.name}</p>
                                        )}
                                    </div>
                                    <img
                                        src={user.avatar ? user.avatar.original_url : '/img/sk-logo.svg'}
                                        alt={`${user.name}'s Avatar`}
                                        className="rounded-circle img-fluid ms-auto"
                                        style={{ width: '48px', height: '48px' }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="mb-3">
                            <h5>Выбранные пользователи:</h5>
                            {selectedUsers.map(userId => (
                                <div key={userId}>
                                    {users.map(user => {
                                        if (user.id === userId) {
                                            return (
                                                <div key={user.id} className="d-flex align-items-center mb-2">
                                                    <input
                                                        type="checkbox"
                                                        value={user.id}
                                                        checked={true}
                                                        onChange={() => handleUserSelection(user.id)}
                                                        className="form-check-input me-2"
                                                    />
                                                    <div>
                                                        <h6>{user.name}</h6>
                                                        {user.user_details && user.user_details.department && (
                                                            <p>Отдел: {user.user_details.department.name}</p>
                                                        )}
                                                    </div>
                                                    <img
                                                        src={user.avatar ? user.avatar.original_url : '/img/sk-logo.svg'}
                                                        alt={`${user.name}'s Avatar`}
                                                        className="rounded-circle img-fluid ms-auto"
                                                        style={{ width: '48px', height: '48px' }}
                                                    />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            ))}
                        </div>
                        <button type="submit" className="btn btn-primary">Создать диалог</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewDialogForm;
