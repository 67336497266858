const BadgeSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="badge">
                <path
                    id="Vector"
                    d="M12.6668 12.6666H3.3335C2.22893 12.6666 1.3335 11.7712 1.3335 10.6666V5.33331C1.3335 4.22874 2.22892 3.33331 3.33349 3.33331H12.6668C13.7714 3.33331 14.6668 4.22874 14.6668 5.33331V5.99998V10.6666C14.6668 11.7712 13.7714 12.6666 12.6668 12.6666Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="B"
                    d="M6.3335 11C5.78121 11 5.3335 10.5523 5.3335 10V6C5.3335 5.44772 5.78121 5 6.3335 5H8.14593C8.86619 5 9.41495 5.14286 9.79223 5.42857C10.1695 5.70857 10.3581 6.08571 10.3581 6.56C10.3581 6.88 10.2838 7.15429 10.1352 7.38286C9.98659 7.60571 9.78652 7.78 9.535 7.90571C9.28919 8.02571 9.02053 8.08571 8.72899 8.08571L8.88334 7.77714C9.2206 7.77714 9.52356 7.84 9.79223 7.96571C10.0609 8.08571 10.2724 8.26286 10.4267 8.49714C10.5868 8.73143 10.6668 9.02286 10.6668 9.37143C10.6668 9.88571 10.4696 10.2857 10.0752 10.5714C9.68076 10.8571 9.09484 11 8.31742 11H6.3335ZM6.44818 10.1257H8.24882C8.66611 10.1257 8.98623 10.0571 9.20917 9.92C9.4321 9.78286 9.54357 9.56286 9.54357 9.26C9.54357 8.96286 9.4321 8.74571 9.20917 8.60857C8.98623 8.46571 8.66611 8.39429 8.24882 8.39429H6.36244V7.52857H8.02589C8.4146 7.52857 8.71185 7.46 8.91763 7.32286C9.12914 7.18571 9.23489 6.98 9.23489 6.70571C9.23489 6.42571 9.12914 6.21714 8.91763 6.08C8.71185 5.94286 8.4146 5.87429 8.02589 5.87429H6.44818V10.1257Z"
                    fill="#202227"
                />
            </g>
        </svg>
    );
};
export default BadgeSVG;
