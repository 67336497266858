import React, { useState } from "react";
import PdfSVG from "../PostField/assets/PdfSVG.jsx";
import XlsSVG from "../PostField/assets/XlsSVG.jsx";
import PptSVG from "../PostField/assets/PptSVG.jsx";
import CloseBtnSVG from "../PostField/assets/CloseBtnSVG.jsx";
import WordSVG from "../PostField/assets/WordSVG.jsx";
import OtherSVG from "../PostField/assets/OtherSVG.jsx";
import VideoPlayer from "./VideoPlayer.jsx";
import AudioPlayer from "./AudioPlayer.jsx";
import { getFileType } from "../../functions.js";

function FilePreview({ files = [], handleRemoveFile }) {
    function renderFile(file, index) {
        const fileType = getFileType(file);
        let iconComponent;

        if (fileType === "pdf") {
            iconComponent = <PdfSVG file={file} />;
        } else if (fileType === "xls") {
            iconComponent = <XlsSVG file={file} />;
        } else if (fileType === "ppt") {
            iconComponent = <PptSVG file={file} />;
        } else if (fileType === "doc") {
            iconComponent = <WordSVG file={file} />;
        } else if (fileType === "video") {
            iconComponent = <VideoPlayer file={file} />;
        } else if (fileType === "audio") {
            iconComponent = <AudioPlayer file={file} />;
        } else {
            iconComponent = <OtherSVG file={file} />;
        }

        return (
            <>
                <div className="d-flex align-items-center justify-content-center gap-1">
                    {iconComponent}
                </div>
            </>
        );
    }

    const fileGroups = {};

    files?.forEach((file, index) => {
        const fileType = getFileType(file);
        if (!fileGroups[fileType]) {
            fileGroups[fileType] = [];
        }
        fileGroups[fileType].push(file);
    });

    const formatFileSize = (size) => {
        if (size < 1024) return size + " bytes";
        else if (size >= 1024 && size < 1048576)
            return (size / 1024).toFixed(1) + " KB";
        else if (size >= 1048576) return (size / 1048576).toFixed(1) + " MB";
    };

    return (
        <div className="file-preview d-flex flex-column gap-1">
            {Object.keys(fileGroups).map((fileType) => (
                <div key={fileType} className="d-flex flex-row gap-1 flex-wrap">
                    {fileGroups[fileType].map((file, index) => (
                        <div key={index} style={{ maxWidth: "350px" }}>
                            {fileType === "media" || fileType === "video" ? (
                                <div className="position-relative">
                                    {fileType !== "video" ? (
                                        <img
                                            src={
                                                file.medium_file_path ||
                                                URL.createObjectURL(file)
                                            }
                                            alt={file.name}
                                            className="h-132 object-fit-cover bg-white"
                                            role="button"
                                            data-srcset={
                                                file.original_file_path ||
                                                URL.createObjectURL(file)
                                            }
                                            style={{ maxWidth: "350px" }}
                                            loading="lazy"
                                        />
                                    ) : (
                                        renderFile(file)
                                    )}
                                    {handleRemoveFile ? (
                                        <CloseBtnSVG
                                            handleClick={() =>
                                                handleRemoveFile(file)
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center gap-1">
                                    {renderFile(file)}
                                    {fileType !== "audio" && (
                                        <div className="d-flex flex-column gap-1">
                                            <span
                                                className="text-truncate"
                                                style={{
                                                    maxWidth: "150px",
                                                }}
                                            >
                                                {file.name}
                                                {!file.name &&
                                                    file.original_file_name}
                                            </span>
                                            <span className="text-muted">
                                                {file.size &&
                                                    formatFileSize(file.size)}
                                                {file.file_size &&
                                                    !file.size &&
                                                    formatFileSize(
                                                        file.file_size
                                                    )}
                                            </span>
                                        </div>
                                    )}
                                    {handleRemoveFile ? (
                                        <CloseBtnSVG
                                            handleClick={() =>
                                                handleRemoveFile(file)
                                            }
                                            absolute={false}
                                            backgroundStatic={false}
                                            strokeStatic="black"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default FilePreview;
