const CloseSVG = ({ onClick, transparent = false }) => {
    return (
        <svg
            width="16.000000"
            height="16.000000"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            onClick={onClick}
            role="button"
        >
            <defs>
                <clipPath id="clip2932_2310">
                    <rect
                        id="close-16px"
                        rx="4.000000"
                        width="16.000000"
                        height="16.000000"
                        fill="white"
                        fillOpacity="0"
                    />
                </clipPath>
            </defs>
            <rect
                id="close-16px"
                rx="4.000000"
                width="16.000000"
                height="16.000000"
                fill={transparent ? "none" : "#EEEEEE"}
                fillOpacity="1.000000"
            />
            <g clipPath="url(#clip2932_2310)">
                <path
                    id="Vector"
                    d="M4.66663 4.6665L8 7.99976L11.3334 11.3333M8 7.99976L11.3334 4.6665M8 7.99976L4.66663 11.3333"
                    stroke="#202227"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default CloseSVG;
