import React, { useRef, useState, useEffect } from "react";
import SendMessageSVG from "./PostField/assets/SendMessageSVG";
import SmileSVG from "./PostField/assets/SmileSVG";
import SmilesPopover from "./SmilesPopover";
import FilePreview from "./Stena/FilePreview";
import FileUploadComponent from "./Stena/FileUploadComponent";
import UserLogo from "./Stena/UserLogo";
import ContentEditable from "react-contenteditable";
import { useSelector } from "react-redux";
import ReusableModal from "./ReusableModal";

const ReusableCommentInput = ({
    startingInputValue,
    handleSendBtnClick,
    postId,
    commentId,
    fileLimit = 0,
    extraFormControl = false,
    sendMessageBtn = true,
    getInputValue,
    isInputFocused,
    setIsInputFocused,
}) => {
    const [errors, setErrors] = useState([]);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(startingInputValue);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const inputRef = useRef(null);
    const targetRef = useRef(null);

    const currentUser = useSelector((state) => state.users?.currentUser);

    const handleClick = () => {
        // console.log(inputRef.current);
        setIsEditing(true);
        setIsEmojiPickerOpen(false);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setIsEmojiPickerOpen(false);
        setIsEditing(true);
        setInputValue(value);
        if (getInputValue && typeof getInputValue === "function") {
            getInputValue(value);
        }

        setTimeout(() => {
            if (inputRef.current) {
                const inputElement = inputRef.current;
                const range = document.createRange();
                const selection = window.getSelection();
                const savedSelection = selection.getRangeAt(0).cloneRange();
                inputElement.focus();

                range.selectNodeContents(inputElement);
                range.collapse(false);
                selection.removeAllRanges();
                if (value.length > 1) {
                    selection.addRange(savedSelection);
                } else {
                    selection.addRange(range);
                }
            }
        }, 0);
    };

    function handleRemoveFile(file) {
        const updatedFiles = selectedFiles.filter(
            (f) =>
                f.name !== file.name &&
                f.size !== file.size &&
                f.lastModified !== file.lastModified
        );

        setSelectedFiles(updatedFiles);
    }

    function handleSmileIconClick({ target }) {
        if (!isEmojiPickerOpen) {
            setIsEmojiPickerOpen(true);
        } else {
            setIsEmojiPickerOpen(false);
        }
        targetRef.current = target;
    }

    const closeModal = () => {
        handleCancelBtnClick();
    };

    const handleCancelBtnClick = () => {
        setIsModalOpen(false);
        setSelectedFiles(false);
        setErrors((prevError) =>
            prevError.filter((e) => e.type !== "file.limit")
        );
    };

    const handleContinueBtnClick = () => {
        setIsModalOpen(false);
        setSelectedFiles((prevFiles) => {
            if (prevFiles.length) {
                const newFiles = [];
                for (let i = 0; i < 2; i++) {
                    if (prevFiles[i]) {
                        newFiles.push(prevFiles[i]);
                    } else {
                        break;
                    }
                }
                return newFiles;
            } else return [];
        });
        setErrors((prevError) =>
            prevError.filter((e) => e.type !== "file.limit")
        );
    };

    const handleInputPaste = (e) => {
        e.preventDefault();

        // Получаем данные из буфера обмена
        const clipboardData = e.clipboardData || window.clipboardData;
        const textData = clipboardData.getData("text/plain");

        // Проверяем, содержит ли вставленные данные только текст
        if (textData) {
            // Вы можете обработать и вставить текст в ваш компонент
            setInputValue((prevValue) => prevValue + textData);
        } else {
            handlePaste(e);
        }
    };

    const handlePopoverMouseLeave = () => {
        setIsEmojiPickerOpen(false);
    };

    const handleFileLimit = (files) => {
        if (files.length + selectedFiles.length > fileLimit * 1) {
            // setErrors([...errors, "Вы можете загрузить не более 2 файлов"]);
            setErrors((prevError) => {
                const newError = {
                    message: `Вы можете прикрепить к сообщению не более ${fileLimit} файлов.`,
                    type: "file.limit",
                };
                return [...prevError, newError];
            });
            setIsModalOpen(true);
        }
    };

    const processFiles = (files) => {
        handleFileLimit(files);
        setSelectedFiles((prevFiles) => {
            if (prevFiles.length) {
                return [...prevFiles, ...files];
            } else return files;
        });
    };

    const handleFileChange = (e) => {
        console.log("target in handleFileChange", e.target);
        const postId = e.target.dataset.postid;
        const commentId = e.target.dataset.commentid || null;
        // console.log("e.target.files", e.target.files);

        for (const file of e.target.files) {
            file.postId = postId;
            if (commentId) {
                file.commentId = commentId;
            }
        }
        processFiles([...e.target.files]);
        e.target.value = null;
    };

    const handleDrop = (e) => {
        e.preventDefault();
        processFiles([...e.dataTransfer.files]);
    };

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const files = clipboardData.files;
        if (files.length > 0) {
            processFiles([...files]);
        }
    };

    const handleUpload = async () => {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("files[]", file);
            // formData.append("contentType", contentType);
            // formData.append("contentId", contentId);
        });

        // Выводим данные формы в консоль для отладки
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        try {
            const response = await onFileUpload(formData); // Функция отправки файлов на сервер
            if (response?.success) {
                // Выводим уведомление об успешной загрузке
                alert("Обработка успешной загрузки");
            } else {
                setErrors([...errors, response.message || "Ошибка загрузки"]);
            }
        } catch (error) {
            setErrors([...errors, error.message || "Ошибка сети"]);
        }
    };

    async function onFileUpload(formData) {
        const res = axios.post("/comments", {
            token,
        });
    }

    function handleSendCommentClick() {
        handleSendBtnClick(inputValue, selectedFiles);
        setInputValue("");
        setSelectedFiles([]);
    }

    useEffect(() => {
        //отработка нажатия на клавишу ctrl+Enter
        const handleKeyDown = (event) => {
            if (isInputFocused && event.ctrlKey && event.key === "Enter") {
                const settings = JSON.parse(localStorage.getItem("settings"));
                const settingsChecked = settings?.find((s) => s.id === 6);
                if (settings && settingsChecked.checked) {
                    handleSendCommentClick();
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [inputValue, selectedFiles]);

    return (
        <div className="d-flex flex-column sk-comment-input">
            <div className="d-flex justify-content-between gap-3">
                <div className="d-flex  gap-2 flex-grow-1">
                    {currentUser?.name ? (
                        <UserLogo user={currentUser} size="small" />
                    ) : (
                        ""
                    )}
                    <div className="d-flex flex-column gap-2 flex-grow-1">
                        <div className="d-flex ms-1 text-muted  border rounded justify-content-between">
                            {isEditing || startingInputValue ? (
                                <>
                                    <div className="input-group d-flex ">
                                        <ContentEditable
                                            innerRef={inputRef}
                                            html={inputValue}
                                            onChange={handleChange}
                                            onClick={handleClick}
                                            className={
                                                "text form-control p-1 border-0 w-20vw " +
                                                (extraFormControl
                                                    ? "form-control-extra mh-132"
                                                    : "")
                                            }
                                            onPaste={handleInputPaste}
                                            onBlur={() =>
                                                setIsInputFocused(false)
                                            }
                                            onFocus={() =>
                                                setIsInputFocused(true)
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <input
                                    className={
                                        "form-control p-1 border-0 w-20vw flex-grow-1" +
                                        (extraFormControl
                                            ? " form-control-extra mh-132"
                                            : "") +
                                        (window.innerWidth <= 768
                                            ? " text-14"
                                            : "")
                                    }
                                    placeholder="Написать комментарий..."
                                    value={inputValue}
                                    onChange={handleChange}
                                />
                            )}
                            <div className="4-icons d-flex gap-2 py-1 pe-2 d-none d-md-flex ">
                                <SmileSVG handleClick={handleSmileIconClick} />

                                <FileUploadComponent
                                    handleDrop={handleDrop}
                                    handlePaste={handlePaste}
                                    handleFileChange={handleFileChange}
                                    type="photo"
                                    postId={postId}
                                    commentId={commentId}
                                />
                                <FileUploadComponent
                                    handleDrop={handleDrop}
                                    handlePaste={handlePaste}
                                    handleFileChange={handleFileChange}
                                    type="all"
                                    postId={postId}
                                    commentId={commentId}
                                />
                            </div>
                            <div className="4-icons gap-2 p-1 d-md-none ">
                                <SmileSVG handleClick={handleSmileIconClick} />

                                <FileUploadComponent
                                    handleDrop={handleDrop}
                                    handlePaste={handlePaste}
                                    handleFileChange={handleFileChange}
                                    type="photo"
                                    postId={postId}
                                    commentId={commentId}
                                />
                                <FileUploadComponent
                                    handleDrop={handleDrop}
                                    handlePaste={handlePaste}
                                    handleFileChange={handleFileChange}
                                    type="all"
                                    postId={postId}
                                    commentId={commentId}
                                />
                            </div>
                        </div>
                        {selectedFiles.length ? (
                            <FilePreview
                                files={selectedFiles}
                                handleRemoveFile={handleRemoveFile}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {sendMessageBtn && (
                    <div>
                        <SendMessageSVG
                            disabled={
                                !inputValue.length && !selectedFiles.length
                            }
                            gray={!inputValue.length && !selectedFiles.length}
                            handleClick={handleSendCommentClick}
                        />
                    </div>
                )}
            </div>
            <div className="__errors_smiles">
                {errors.length > 0 && (
                    <div>
                        {errors.map((error, index) => {
                            console.log(error);
                            if (error.type === "file.limit") {
                                return (
                                    <ReusableModal
                                        title="Ошибка"
                                        content={error.message}
                                        show={isModalOpen}
                                        onClose={closeModal}
                                        key={index}
                                        firstBtn={{
                                            title: "Отмена",
                                            class: "btn btn-sm",
                                            handleClick: handleCancelBtnClick,
                                        }}
                                        secondBtn={{
                                            title: "Продолжить",
                                            class: "btn btn-primary btn-sm",
                                            handleClick: handleContinueBtnClick,
                                        }}
                                        large={true}
                                    />
                                );
                            }
                        })}
                    </div>
                )}
                {isEmojiPickerOpen ? (
                    <SmilesPopover
                        onEmojiClick={(e) => {
                            // console.log(e.getImageUrl());
                            setIsEditing(true);
                            setInputValue(
                                (prevValue) =>
                                    prevValue +
                                    `<img class="w-20 h-20" src="${e.imageUrl}" alt="${e.emoji}" />`
                            );
                        }}
                        target={targetRef}
                        handlePopoverMouseLeave={handlePopoverMouseLeave}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default ReusableCommentInput;
