const GiftSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2933_309)">
                <path
                    d="M13.3333 8V14.0667C13.3333 14.398 13.0647 14.6667 12.7333 14.6667H3.26666C2.93529 14.6667 2.66666 14.398 2.66666 14.0667V8"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.0667 4.6665H1.93334C1.60197 4.6665 1.33334 4.93513 1.33334 5.2665V7.39984C1.33334 7.73121 1.60197 7.99984 1.93334 7.99984H14.0667C14.398 7.99984 14.6667 7.73121 14.6667 7.39984V5.2665C14.6667 4.93513 14.398 4.6665 14.0667 4.6665Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 14.6665V4.6665"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.00001 4.66683H5.00001C4.55798 4.66683 4.13406 4.49123 3.8215 4.17867C3.50894 3.86611 3.33334 3.44219 3.33334 3.00016C3.33334 2.55814 3.50894 2.13421 3.8215 1.82165C4.13406 1.50909 4.55798 1.3335 5.00001 1.3335C7.33334 1.3335 8.00001 4.66683 8.00001 4.66683Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 4.66683H11C11.442 4.66683 11.866 4.49123 12.1785 4.17867C12.4911 3.86611 12.6667 3.44219 12.6667 3.00016C12.6667 2.55814 12.4911 2.13421 12.1785 1.82165C11.866 1.50909 11.442 1.3335 11 1.3335C8.66667 1.3335 8 4.66683 8 4.66683Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2933_309">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GiftSVG;
