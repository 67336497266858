import { useState } from "react";
import MeowSVG from "../Icons/MeowSVG";

const BadgeCard = ({ badge, onBadgeClick, selectedBadgeId }) => {
    const [hover, setHover] = useState(false);
    const selected = badge.id === selectedBadgeId;

    return (
        <>
            <div
                className={
                    "badge_card d-flex flex-column  align-items-center p-2 my-2 gap-2 col-3 rounded d-none d-md-block" +
                    (hover && !selected ? " bg-gray-2" : "") +
                    (selected ? " bg-disabled" : "")
                }
                key={badge.id}
                role="button"
                onClick={() => {
                    onBadgeClick(badge);
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div>
                    <img
                        style={{ width: "148px", height: "66px" }}
                        src={badge.image}
                        alt={badge.title}
                    />
                </div>
                <div className="d-flex gap-1">
                    <MeowSVG />
                    <small className="text-gray-6-text text-primary">
                        {badge.meow} <span>Мяукоинов</span>
                    </small>
                </div>
            </div>
            <div
                className={
                    "badge_card d-flex flex-column align-items-center p-1 my-2 gap-2  rounded d-md-none " +
                    (hover && !selected ? " bg-gray-2" : "") +
                    (selected ? " bg-disabled" : "")
                }
                key={badge.id}
                role="button"
                onClick={() => {
                    onBadgeClick(badge);
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div>
                    <img
                        style={{ width: "148px", height: "66px" }}
                        src={badge.image}
                        alt={badge.title}
                    />
                </div>
                <div className="d-flex gap-1">
                    <MeowSVG />
                    <small className="text-gray-6-text text-primary">
                        {badge.meow} <span>Мяукоинов</span>
                    </small>
                </div>
            </div>
        </>
    );
};

export default BadgeCard;
