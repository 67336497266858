import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTransition, animated } from 'react-spring';

const UserRandom = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [phrases, setPhrases] = useState({});
  const [isAnimating, setIsAnimating] = useState(true);
  const [magicText, setMagicText] = useState('Magic рандома...');
  const [magicTextIndex, setMagicTextIndex] = useState(0);

  // Функция для перемещения всех аватаров в случайные места
  const shuffleAvatars = () => {
    setUsers((currentUsers) =>
      currentUsers.map((user) => ({
        ...user,
        style: {
          ...user.style,
          transform: `translate(${Math.random() * 80}vw, ${Math.random() * 80}vh)`,
          transition: 'transform 0.7s ease',
        },
      }))
    );
  };

  useEffect(() => {
    axios
      .get('/users/getRandomUsers')
      .then((response) => {
        setUsers(
          response.data.allUsers.map((user) => ({
            ...user,
            style: {
              position: 'absolute',
              transform: 'translate(0, 0)',
              opacity: 1,
              zIndex: `${Math.floor(Math.random() * 100)}`,
            },
          }))
        );
        setSelectedUsers(response.data.selectedUsers);
        setPhrases(response.data.phrases);
      })
      .catch((error) => {
        console.error('ну в рот мне ноги...', error);
      });


      const updateRedikCount = () => {
        axios.get('/users/redikValue')
          .then((response) => {
            const redikCount = response.data;
            const redikCountElement = document.getElementById('rediki-count');
            if (redikCountElement) {
              redikCountElement.textContent = redikCount;
            }
          })
          .catch((error) => console.error('Ошибка при получении данных о редиках', error));
      };
      updateRedikCount(); // Обновляем количество редиков

    // Устанавливаем флаг isAnimating в false после 9 секунд
    const timeoutId = setTimeout(() => setIsAnimating(false), 12000);
    return () => clearTimeout(timeoutId);
  }, []);

  // Перемешиваем аватары каждые 700 мс
  useEffect(() => {
    const intervalId = setInterval(shuffleAvatars, 700);
    return () => clearInterval(intervalId);
  }, []);

  const transitions = useTransition(isAnimating ? [] : selectedUsers, {
    from: { transform: 'translate3d(0,-60%,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,60%,0)', opacity: 0 },
    unique: true,
    config: { tension: 220, friction: 20 },
    trail: 100,
  });

  // Функция для анимации текста "Magic рандома..." по буквам
  const animateText = () => {
    if (magicTextIndex < magicText.length) {
      setMagicTextIndex((prevIndex) => prevIndex + 1);
    } else {
      setIsAnimating(false);
    }
  };

  useEffect(() => {
    const textIntervalId = setInterval(animateText, 100); // Измените интервал по желанию
    return () => clearInterval(textIntervalId);
  }, []);

  return (
    <div className="col-12">
      <div className="row">
        {isAnimating ? (
          <div className="col-12 text-center mb-2">
            <h1>
              {magicText.slice(0, magicTextIndex)}
            </h1>
            {users.map((user) => (
              <animated.div key={user.id} id={`avatar-${user.id}`} className="avatar-shuffle" style={user.style}>
                {user.avatar && user.avatar.thumbnail_url && (
                  <img
                    src={`/${user.avatar.thumbnail_url}`}
                    alt={user.name}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </animated.div>
            ))}
          </div>
        ) : (
          transitions((style, user) => (
            <animated.div className="col-8 col-md-6 col-lg-3 mb-8" style={{ ...style }} key={user.id}>
              <div className="card rounded-circle mb-12 mb-2">
                <img
                  src={user.avatar && user.avatar.thumbnail_url ? `/${user.avatar.thumbnail_url}` : '/img/sk-logo.svg'}
                  alt={user.name}
                  className="rounded-circle img-fluid"
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
                <div className="card-body text-center">
                  <b>{user.name}</b>
                  {phrases[user.id] && (
                    <p className="card-body text">{phrases[user.id]}</p>
                  )}
                </div>
              </div>
            </animated.div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserRandom;
