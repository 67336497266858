import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useDispatch } from "react-redux";
import { addFilter } from "../redux/stenasSlice";

const SettingsPopover = ({ target, show = false }) => {
    const [popperRef, setPopperRef] = useState(null);
    const [showPopover, setShowPopover] = useState(show);
    const [arrowElement, setArrowElement] = useState(null);
    const dispatch = useDispatch();
    const targetRef = useRef(target);
    const { styles, attributes } = usePopper(targetRef.current, popperRef, {
        placement: "bottom",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [0, 5] } },
        ],
    });

    const handlePopoverToggle = ({ target }) => {
        if (
            !target.closest(".stena-settings-popover") &&
            !target.closest("#stena_settings")
        ) {
            setShowPopover(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handlePopoverToggle);

        return () => {
            document.removeEventListener("click", handlePopoverToggle);
        };
    }, []);

    const initialSettings = localStorage.getItem("settings")
        ? JSON.parse(localStorage.getItem("settings"))
        : [
              {
                  id: 1,
                  name: "Случайные благодарности",
                  type: "random",
                  checked: false,
              },
              { id: 2, name: "Медали", type: "reward", checked: true },
              { id: 3, name: "Бейджи", type: "badge", checked: true },
              { id: 4, name: "Подарки", type: "gift", checked: true },
              { id: 5, name: "Благодарности", type: "thanks", checked: true },
              {
                  id: 6,
                  name: "Отправлять сообщения с Ctrl+Enter",
                  checked: false,
              },
          ];
    const [settings, setSettings] = useState(initialSettings);

    function handleCheckboxChange(setting) {
        const newSettings = settings.map((s) => {
            if (s.id === setting.id) {
                s.checked = !s.checked;
            }
            return s;
        });
        setSettings(newSettings);
        localStorage.setItem("settings", JSON.stringify(newSettings));
        if (setting.id !== 6) {
            // для всех кроме отправки сообщений с Ctrl+Enter
            dispatch(addFilter({ data: newSettings }));
        }
    }

    return (
        <>
            {showPopover ? (
                <div
                    className={`bg-gray-2 bs-popover-auto popover stena-settings-popover show`}
                    style={styles.popper}
                    {...attributes.popper}
                    ref={setPopperRef}
                    role="button"
                >
                    <div
                        className="popover-arrow"
                        ref={setArrowElement}
                        style={styles.arrow}
                    ></div>

                    <div className="d-flex flex-column gap-2 p-3">
                        {settings.map((setting) => (
                            <div
                                key={setting.id}
                                data-id={setting.id}
                                className="d-flex align-items-center gap-2 sk-hover-gray-3"
                            >
                                <input
                                    type="checkbox"
                                    checked={setting.checked}
                                    onChange={() =>
                                        handleCheckboxChange(setting)
                                    }
                                    className={
                                        "form-check-input m-0 border-0 " +
                                        (setting.checked
                                            ? "bg-primary"
                                            : "bg-white")
                                    }
                                />
                                <span
                                    onClick={() =>
                                        handleCheckboxChange(setting)
                                    }
                                >
                                    {setting.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default SettingsPopover;
