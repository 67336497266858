import React from "react";

function VideoPlayer({ file }) {
    return (
        <div
            className={file.original_file_path ? "file_original_file_path" : ""}
            data-path={file.original_file_path}
            data-type="video"
        >
            <video height="132" controls>
                <source
                    src={
                        file instanceof File
                            ? URL.createObjectURL(file)
                            : file.original_file_path
                    }
                    type={file.type}
                />
                Ваш браузер не поддерживает видео элемент.
            </video>
        </div>
    );
}

export default VideoPlayer;
