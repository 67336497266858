const LikeSVG = ({ isLiked }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.3334 7.38518C18.3334 8.67395 17.8386 9.91177 16.9548 10.8274C14.9207 12.9359 12.9479 15.1344 10.8378 17.1664C10.3541 17.6254 9.58681 17.6086 9.12402 17.1289L3.04482 10.8274C1.20731 8.92267 1.20731 5.84769 3.04482 3.94298C4.90039 2.01954 7.9233 2.01954 9.77887 3.94298L9.99983 4.17201L10.2207 3.9431C11.1104 3.02042 12.322 2.5 13.5878 2.5C14.8535 2.5 16.0651 3.02037 16.9548 3.94298C17.8386 4.8587 18.3334 6.09647 18.3334 7.38518Z"
                stroke={isLiked ? "#BF1B2C" : "#828282"}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LikeSVG;
