import { useSelector } from "react-redux";
import ShopCard from "./ShopCard";

const ShopList = ({
    shopList = [],
    handleItemSelect,
    presentCostOfGifts = 0,
}) => {
    const currentUser = useSelector((state) => state.users?.currentUser);

    const favArray = JSON.parse(localStorage.getItem("favorites")) || [];

    return (
        <div className="shop-list row row-cols-1 row-cols-md-3 g-4">
            {shopList.map((item) => (
                <ShopCard
                    item={item}
                    key={item.id}
                    active={
                        item.summ * 1 <=
                        currentUser?.meow * 1 - presentCostOfGifts
                    }
                    favorite={favArray?.includes(item.id)}
                    onBtnClick={handleItemSelect}
                />
            ))}
        </div>
    );
};

export default ShopList;
