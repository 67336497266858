const CrownSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="crown">
                <path
                    id="Vector"
                    d="M12.8 11.3334L14 4.66669L9.8 6.66669L8 4.66669L6.2 6.66669L2 4.66669L3.2 11.3334H12.8Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
export default CrownSVG;
