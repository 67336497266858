import React, { useEffect } from 'react';
 
function DynamicStatsHandler({ data }) {
    useEffect(() => {
        // Обновляем каждый элемент статистики
        const updateElementText = (elementId, newData) => {
            const element = document.getElementById(elementId);
            if (element && newData !== undefined) {
                element.textContent = newData;
            }
        };

        // Обновляем различные статистики
        updateElementText('notifications-count', data.notifications);
        updateElementText('emails-sent-count', data.emailsSent);
        updateElementText('online-users-count', data.onlineUsers);
        updateElementText('unique-visitors-count', data.uniqueVisitors);
        updateElementText('thank-yous-count', data.thankYous);
        updateElementText('thank-yous-sum', data.thankYouSum);
    }, [data]);

    return null;
}

export default DynamicStatsHandler;
