import React, { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";

const FileUploadComponent = ({
    handleDrop,
    handlePaste,
    handleFileChange,
    type = "all",
    postId,
    commentId,
}) => {
    // const [crop, setCrop] = useState({ aspect: 1 });
    // const [fileAccess, setFileAccess] = useState("private");

    const updateAcceptValue = () => {
        let acceptValue = "";

        if (type === "all") {
            acceptValue =
                ".jpg, .jpeg, .png, .gif, .bmp, .svg, .webp, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .ods, .odp, .mp3, .mp4, .ogg, .wav, .webm, .zip, .rar, .tar, .gz, .7z, .txt, .rtf, .csv, .xml, .json";
        } else if (type === "photo") {
            acceptValue = "image/*";
        } else if (type === "documents") {
            acceptValue = ".doc, .docx, .txt";
        } else if (type === "images") {
            acceptValue = "image/*";
        } else if (type === "pdf") {
            acceptValue = ".pdf";
        } else if (type === "audio") {
            acceptValue = "audio/*";
        } // Добавьте другие варианты, если необходимо

        return acceptValue;
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onPaste={handlePaste}
            className="d-flex"
        >
            <label
                htmlFor={
                    `fileInput-${type}-${postId}` +
                    (commentId ? `-${commentId}` : "")
                }
                className={type + "-icon w-20 h-20"}
            ></label>
            <input
                type="file"
                accept={updateAcceptValue()}
                multiple
                onChange={handleFileChange}
                className="file-input"
                id={
                    `fileInput-${type}-${postId}` +
                    (commentId ? `-${commentId}` : "")
                }
                data-postid={postId}
                data-commentid={commentId}
            />
        </div>
    );
};

export default FileUploadComponent;
