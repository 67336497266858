import React from 'react';

function GratitudeLeaders({ data }) {
    // Проверяем, что data.gratitudeLeaders существует и является массивом
    if (!data || !Array.isArray(data.gratitudeLeaders)) {
      console.error('Data is not valid');
      return;
    }

    const leadersHtml = data.gratitudeLeaders.map((leader, index) => `
      <a href="/user/${leader.user.id}" class="text-reset link-decoration-none text-decoration-none">
        <div class="d-flex justify-content-between align-items-center mb-1 ${index === 0 ? 'bg-highlight' : 'bg-gray'} p-2 shadow-sm rounded">
        <div class="d-flex align-items-center">
        ${leader.avatar ? `<img src="/${leader.avatar}" alt="${leader.name}" class="rounded-circle w-48 h-48 object-fit-cover me-2">`
                         : `<div class="rounded-circle w-48 h-48 bg-primary text-white d-flex align-items-center justify-content-center me-2">${leader.short_name}</div>`}
        <span class="text-dark fw-bold text-truncate">${leader.formatted_name}</span>
      </div>
          <div class="d-flex align-items-center ms-3">
            <b class="text-gray-600 me-2">${leader.total}</b>
            <img src="/img/meow.svg" alt="Мяукоин" />
          </div>
        </div>
      </a>
    `).join('');

    const gratitudeLeadersElement = document.getElementById('gratitudeLeaders');
    if (gratitudeLeadersElement) {
      gratitudeLeadersElement.innerHTML = leadersHtml;
    }
  }


export default GratitudeLeaders;
