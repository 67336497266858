const GiftSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="gift" clipPath="url(#clip0_2355_342)">
                <path
                    id="Vector"
                    d="M13.3332 8V14.0667C13.3332 14.398 13.0645 14.6667 12.7332 14.6667H3.2665C2.93513 14.6667 2.6665 14.398 2.6665 14.0667V8"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M14.0668 4.66669H1.9335C1.60213 4.66669 1.3335 4.93532 1.3335 5.26669V7.40002C1.3335 7.73139 1.60213 8.00002 1.9335 8.00002H14.0668C14.3982 8.00002 14.6668 7.73139 14.6668 7.40002V5.26669C14.6668 4.93532 14.3982 4.66669 14.0668 4.66669Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_3"
                    d="M8 14.6667V4.66669"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_4"
                    d="M8.00016 4.66665H5.00016C4.55814 4.66665 4.13421 4.49105 3.82165 4.17849C3.50909 3.86593 3.3335 3.44201 3.3335 2.99998C3.3335 2.55795 3.50909 2.13403 3.82165 1.82147C4.13421 1.50891 4.55814 1.33331 5.00016 1.33331C7.3335 1.33331 8.00016 4.66665 8.00016 4.66665Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_5"
                    d="M8 4.66665H11C11.442 4.66665 11.866 4.49105 12.1785 4.17849C12.4911 3.86593 12.6667 3.44201 12.6667 2.99998C12.6667 2.55795 12.4911 2.13403 12.1785 1.82147C11.866 1.50891 11.442 1.33331 11 1.33331C8.66667 1.33331 8 4.66665 8 4.66665Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2355_342">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default GiftSVG;
