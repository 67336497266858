import React from "react";

function AttachmentsComponent({ attachments }) {
    return (
        <div className="row pt-2 ">
            <div
                className="gallery"
                style={{
                    display: "grid",
                    gridTemplateColumns:
                        "repeat(auto-fill, minmax(120px, 1fr))",
                    gridAutoRows: "120px",
                    gridGap: "1rem",
                }}
            >
                {attachments?.map((attachment, index) => {
                    let span = "1";

                    if (attachments.length === 8) {
                        if (index === 0) {
                            span = "2 / span 2";
                        }
                        if (index === 1 || index === 2) {
                            span = "1 / span 2";
                        }
                        if (index === 3) {
                            span = "2 / span 2";
                        }
                        if (index >= 4 && index <= 7) {
                            span = "auto";
                        }
                    }

                    return (
                        <div
                            className="gallery-item image"
                            data-image-url={attachment.original_file_path}
                            style={{
                                gridColumn: `span ${span}`,
                                gridRow: `span ${span}`,
                            }}
                            key={index}
                        >
                            <img
                                src={attachment.medium_file_path}
                                alt={attachment.original_file_name}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                data-srcset={attachment.original_file_path}
                                role="button"
                                className="bg-white"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default AttachmentsComponent;
