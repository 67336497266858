import React, { useState, useEffect } from "react";
// очень сильно отдоленный от реальности компанет = просто заглушка и пример.

//Кирилл: пока вообще не уверен в такой реализации этого.
//Мне нужно больше погрузиться в то, как работает сокет сейчас, какие данные нужно через сокет прокидвать,
//а какие нужно через fetch. И что вообще должно быть в этом компоненте. Если смотреть буквально, то функция
//NotificationsListUpdate - это функция, а не компонент. Ее суть (как и у LikeEventHandle, например) в том, чтобы
//просто обновлять данные в DOM. То есть, по факту, это даже не jsx, а просто js. Но если мы в рамках этой функции
//выводим какой-то jsx (html), то это будет уже полноценный компонент. И тогда надо будет подключать состояние (useState),
//например, для управления тем, что уведомление было просмотрено.
//Пока просто обновил слегка разметку в выоде из компонента.

// Каждый объект в массиве представляет уведомление.
// Поле id - уникальный идентификатор уведомления.
// Поле type - тип уведомления (например, 'new_post', 'personal_message').
// Поле data - данные, специфичные для типа уведомления.
// Поле viewed - показывает, было ли уведомление просмотрено.

function NotificationsListUpdate({ notifications, onNotificationsViewed }) {
    // Предположим, что каждое уведомление имеет уникальный идентификатор (id)
    // и статус просмотра (viewed)

    // Функция для обработки клика по колокольчику
    const handleBellClick = () => {
        // Отправка POST-запроса для обновления статуса уведомлений
        fetch("http://example.com/api/notifications/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notificationIds: notifications
                    .filter((n) => !n.viewed)
                    .map((n) => n.id),
            }),
        });

        // Вызов функции обратного вызова для обновления состояния в родительском компоненте
        onNotificationsViewed();
    };

    // Отображение уведомления в зависимости от его типа
    const renderNotification = (notification) => {
        switch (notification.type) {
            case "new_post":
                return `Новая запись в разделе "${notification.data.section}": ${notification.data.title}`;
            case "personal_message":
                return `Новое личное сообщение от ${notification.data.sender}: "${notification.data.message}"`;
            case "mention":
                return `Вы упомянуты в "${notification.data.context}"`;
            case "like":
                return `Ваша запись "${notification.data.postTitle}" получила лайк`;
            case "comment":
                return `Новый комментарий к вашему посту "${notification.data.postTitle}": "${notification.data.comment}"`;
            case "quote":
                return `Ваш комментарий был процитирован в "${notification.data.context}"`;
            // ... добавьте другие case для различных типов уведомлений
            default:
                return `Новое уведомление: ${notification.type}`;
        }
    };

    return (
        <>
            <div
                class="d-flex position-relative ms-3 ps-1"
                onClick={handleBellClick}
            >
                <img src="img/bell.svg" />
                <div class="number badge position-absolute top-0 end-0 bg-primary rounded-circle">
                    {notifications.length}
                </div>
            </div>
            <div className="notifications-list">
                {notifications.map((notification, index) => (
                    <div key={index} className="notification-item">
                        {renderNotification(notification)}
                    </div>
                    //ну и тут изображения какие-то надо выводить, если они есть
                ))}
            </div>
        </>
    );
}

export default NotificationsListUpdate;
