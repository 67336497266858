import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class UserActivityTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: true,
            toastShown: false, // Добавленное состояние
        };
        this.inactivityTimeout = null;
        this.activityInterval = null;
        this.checkInterval = 600000; // 600 секунд
    }

    componentDidMount() {
        this.restartInactivityTimer();
        window.addEventListener("mousemove", this.handleUserActivity);
        window.addEventListener("keydown", this.handleUserActivity);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.handleUserActivity);
        window.removeEventListener("keydown", this.handleUserActivity);
        clearTimeout(this.inactivityTimeout);
        clearInterval(this.activityInterval);
    }

    handleUserActivity = () => {
        if (!this.state.isActive) {
            this.setState({ isActive: true, toastShown: false }); // Сброс toastShown
        }
        this.restartInactivityTimer();
    };

    startInactivityTimer = () => {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            this.setState({ isActive: false });
            this.showInactivityToast();
            clearInterval(this.activityInterval);
        }, this.checkInterval);
    };

    restartInactivityTimer = () => {
        this.startInactivityTimer();
    };

    sendUserActivityToServer = () => {
        axios
            .get("/api/user/activity")
            .then((response) => {
                console.log("Отправлен запрос на сервер.");
            })
            .catch((error) => {
                console.error("Ошибка при отправке запроса на сервер.", error);
            });
    };

    showInactivityToast = () => {
        const toastId = "inactivity-toast";
        if (!toast.isActive(toastId) && !this.state.toastShown) {
            this.setState({ toastShown: true });

            // Получение данных со страницы
            const uniqueToday =
                document.getElementById("unique_today").innerText;
            const onlineUsers =
                document.getElementById("online_users").innerText;
            const thanksGiven =
                document.getElementById("thanks_given").innerText;

            // Функции для склонения слов
            const getWordForm = (number, one, two, five) => {
                let n = Math.abs(number) % 100;
                let n1 = n % 10;
                if (n > 10 && n < 20) return five;
                if (n1 > 1 && n1 < 5) return two;
                if (n1 == 1) return one;
                return five;
            };

            const usersWord = getWordForm(
                onlineUsers,
                "сотрудник",
                "сотрудника",
                "сотрудников"
            );
            const thanksWord = getWordForm(
                thanksGiven,
                "благодарность",
                "благодарности",
                "благодарностей"
            );

            const creativeTexts = [
                uniqueToday > 0
                    ? `Сегодня на портале было ${uniqueToday} уникальных посетителей. Не пропустите обсуждения и новости коллег!`
                    : `Станьте первым посетителем сегодня и задайте тон обсуждениям!`,
                onlineUsers > 0
                    ? `Прямо сейчас онлайн ${onlineUsers} ${usersWord}. Зайдите, чтобы узнать последние новости и обновления!`
                    : `Станьте первым активным пользователем сегодня и начните диалог!`,
                thanksGiven > 0
                    ? `Сегодня коллеги выразили ${thanksGiven} ${thanksWord}. Войдите, чтобы поддержать их инициативы!`
                    : `Будьте первым, кто выразит благодарность сегодня! Ваш жест может вдохновить других!`,
                onlineUsers === 1
                    ? `В сети только один коллега. Идеальное время для индивидуального общения!`
                    : onlineUsers > 1
                    ? `Сейчас онлайн ${onlineUsers} ${usersWord}. Присоединяйтесь к дискуссиям и делитесь мнениями!`
                    : `Пока никого нет в сети. Ваше участие может привлечь коллег!`,
                uniqueToday === 1
                    ? `Сегодня вы единственный посетитель портала. Ваше мнение может стать началом интересного диалога!`
                    : `Сегодня ${uniqueToday} уникальных посетителей. Ваши идеи могут стать важным вкладом!`,
                thanksGiven === 1
                    ? `Сегодня была выражена одна благодарность. Присоединяйтесь и выразите своё признание!`
                    : `Было выражено ${thanksGiven} ${thanksWord}. Ваша благодарность будет значимым вкладом!`,
                `Хотите быть в курсе последних событий? ${uniqueToday} сотрудников уже ознакомились с новостями сегодня.`,
                onlineUsers > 1
                    ? `Кроме вас, сейчас онлайн ${
                          onlineUsers - 1
                      } ${usersWord}. Присоединяйтесь к активному обсуждению и делитесь своим мнением!`
                    : onlineUsers === 1
                    ? `Кроме вас, в сети никого нет. Ваше участие может стать началом интересного диалога.`
                    : `Пока что вы единственный в сети. Возможно, ваша активность привлечет коллег!`,
                `Сегодня ваша команда проявила активность: ${uniqueToday} посещений. Присоединитесь к ним и поделитесь своими идеями!`,
                `На портале уже ${uniqueToday} активных участников сегодня. Не упустите возможность общения и сотрудничества!`,
                `Ваше мнение важно: ${uniqueToday} сотрудников уже высказались сегодня. Выскажите и вы свои мысли!`,
                `Портал живет вашим участием: сейчас ${onlineUsers} ${usersWord} в сети. Поделитесь своим опытом и знаниями!`,
                `Сегодня особенный день: ${thanksGiven} ${thanksWord} выражено. Присоединяйтесь и покажите свою благодарность!`,
                `Будьте в авангарде: сегодня ${uniqueToday} сотрудников уже внесли свой вклад. Внесите и вы свой!`,
                `Создайте диалог: уже ${onlineUsers} ${usersWord} в сети, обмениваются идеями и мнениями. Ваш голос тоже важен!`,
            ];

            // Выбор случайного текста
            const randomIndex = Math.floor(
                Math.random() * creativeTexts.length
            );
            const creativeMessage = creativeTexts[randomIndex];

            toast(
                <div style={{ textDecoration: "none", color: "inherit" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src="/img/tost/cat_no_active.webp"
                            alt="Sleepy Cat"
                            style={{ marginRight: "10px", borderRadius: "5%" }}
                        />
                        <div>
                            <div>
                                <strong>Кажется, вы отдыхаете</strong>
                            </div>
                            <div>{creativeMessage}</div>
                        </div>
                    </div>
                </div>,
                {
                    toastId: toastId,
                    position: "bottom-left",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 60,
                    hideProgressBar: false,
                    newestOnTop: true,
                }
            );
        }
    };
    //        <p>Статус активности пользователя: {this.state.isActive ? 'Активен' : 'Неактивен'}</p>

    render() {
        return <div></div>;
    }
}

export default UserActivityTracker;
