import React from 'react';

function CurrencyUpdateHandler({ data }) {

    const animateValueChange = (element, start, end, duration) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            element.textContent = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    };

    // Мауконы
    const mauconyElement = document.getElementById('maucony-count');
    if (mauconyElement && data.meow !== undefined) {
        const currentValue = parseInt(mauconyElement.textContent) || 0;
        const newValue = data.meow;
        if (newValue !== currentValue) {
            const duration = Math.abs(newValue - currentValue) * 50; // скорость зависит от разницы
            animateValueChange(mauconyElement, currentValue, newValue, duration);
        }
    }

    // Редики
    const redikiElement = document.getElementById('rediki-count');
    if (redikiElement && data.redik !== undefined) {
        const currentValue = parseInt(redikiElement.textContent) || 0;
        const newValue = data.redik;
        if (newValue !== currentValue) {
            const duration = Math.abs(newValue - currentValue) * 50; // скорость зависит от разницы
            animateValueChange(redikiElement, currentValue, newValue, duration);
        }

        const blagoCollegElement = document.getElementById('blago_colleg');

        // Обновляем содержимое блока в зависимости от количества редиков
        if (blagoCollegElement && newValue > 0) {
            blagoCollegElement.innerHTML = `
                <div class="bg-white rounded p-4">
                    <div class="d-flex flex-column justify-content-between gap-3">
                        <h5 class="text-gray-6-text">Поблагодарить коллег</h5>
                        <div>
                            <span>Не знаете, кого поблагодарить? Скажите «Спасибо» за вклад в общее дело ${newValue}</span>
                        </div>
                        <div>
                            <a href='/users/randomusers' class="btn btn-sm btn-outline-secondary">Спасибо</a>
                        </div>
                    </div>
                </div>
            `;
        } else{
        if (blagoCollegElement){
            blagoCollegElement.innerHTML = `
                <div class="bg-white rounded p-4">
                    <div class="d-flex flex-column justify-content-between gap-3">
                        <h5 class="text-gray-6-text">Реддики закончились</h5>
                        <div>
                            <span>Завтра вам их снова начислят!</span>
                        </div>
                    </div>
                </div>
            `;
        }
    }


    }

    return null;

    // blago_colleg

}

export default CurrencyUpdateHandler;
