const MedalSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="medal">
                <path
                    id="Vector"
                    d="M9.51452 6.96332L12 1.33331M6.21075 7.08816L3.33333 1.33331M8.50769 6.69856L5.89 1.33331M9.57333 1.33331L8.88 2.99998M4 10.6666C4 12.8758 5.79086 14.6666 8 14.6666C10.2091 14.6666 12 12.8758 12 10.6666C12 8.45751 10.2091 6.66665 8 6.66665C5.79086 6.66665 4 8.45751 4 10.6666Z"
                    stroke="#202227"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default MedalSVG;
