import CloseSVG from "../Icons/CloseSVG";
import ColoredCircleSmall from "../Icons/ColoredCircleSmall";
import MeowSVG from "../Icons/MeowSVG";

const GiftsList = ({
    items,
    handleUserClick,
    removeAwardFromUser,
    handleAddMoreClick,
}) => {
    return (
        <div className="d-flex gap-1 align-items-top">
            <div className="d-flex gap-1 flex-wrap align-items-center">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className="d-flex gap-1 align-items-center"
                    >
                        <span
                            role="button"
                            onClick={handleUserClick}
                            data-id={item.user.id}
                        >
                            {"@" + item.user?.name}
                        </span>{" "}
                        <div>
                            <ColoredCircleSmall imageHref={item.image} />
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <span className="text-secondary">
                                {item.totalSumm}
                            </span>
                            <MeowSVG />
                        </div>
                        <CloseSVG
                            onClick={() => removeAwardFromUser(item, "gift")}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GiftsList;
