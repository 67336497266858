import { useState } from "react";

const ArrowLeftSVG = () => {
    const [hovered, setHovered] = useState(false);
    return (
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <path
                d="M19.9999 37.1666C29.2047 37.1666 36.6666 29.7047 36.6666 20.4999C36.6666 11.2952 29.2047 3.83325 19.9999 3.83325C10.7952 3.83325 3.33325 11.2952 3.33325 20.4999C3.33325 29.7047 10.7952 37.1666 19.9999 37.1666Z"
                stroke={!hovered ? "#333333" : "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                fill={hovered ? "#333333" : "none"}
                strokeLinejoin="round"
            />
            <path
                d="M19.9999 13.8333L13.3333 20.4999L19.9999 27.1666"
                stroke={!hovered ? "#333333" : "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.6666 20.5H13.3333"
                stroke={!hovered ? "#333333" : "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowLeftSVG;
