const CheckCircleSVG = () => {
    return (
        <svg
            width="89"
            height="88"
            viewBox="0 0 89 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.1667 45.833L37.1667 56.833L62.8334 31.1663"
                stroke="#D9D9D9"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44.4999 80.6663C64.7504 80.6663 81.1666 64.2501 81.1666 43.9997C81.1666 23.7492 64.7504 7.33301 44.4999 7.33301C24.2495 7.33301 7.83325 23.7492 7.83325 43.9997C7.83325 64.2501 24.2495 80.6663 44.4999 80.6663Z"
                stroke="#D9D9D9"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CheckCircleSVG;
