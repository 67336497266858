import React, { useState } from "react";
import AttachmentsComponent from "../../Trash/AttachmentsComponent";
import LikeButton from "../LikeButton";
import UserLogo from "../UserLogo";
import FilePreview from "../FilePreview";
import PostBodyComponent from "../PostBodyComponent";
const CommentComponent = ({
    comment,
    children = [],
    stena,
    isChildOfAChild = false,
    handleCommentsToggle,
}) => {
    return (
        <>
            <div
                className="d-flex flex-column sk-comment-body"
                id={`comment_${comment.id}`}
            >
                <div className="comment-body d-flex gap-2">
                    <UserLogo user={comment.author} size="small" />
                    <div className="d-flex flex-column flex-grow-1">
                        <div>
                            <span className="text-14 fw-semibold">
                                {comment.author.name}
                            </span>
                        </div>
                        <PostBodyComponent data={comment} textTruncate={true} />

                        {/* <div>
                            <span
                                className="text-break d-none d-md-block"
                                dangerouslySetInnerHTML={{
                                    __html: comment.body,
                                }}
                            />
                        </div>
                        <div>
                            <span
                                className="text-break d-block d-md-none text-14"
                                dangerouslySetInnerHTML={{
                                    __html: comment.body,
                                }}
                            />
                        </div> */}
                        {/* <AttachmentsComponent
                            attachments={comment.attachments}
                        /> */}
                        <FilePreview files={comment.attachments} />
                        <div className="d-flex gap-2 align-items-center">
                            <small className="text-gray-6-text">
                                {comment.formattedDateTime}
                            </small>
                            <button
                                className="btn p-0 text-14 border-0 sk-hover-gray-3 recomments-btn collapsed"
                                onClick={() => handleCommentsToggle(comment.id)}
                            >
                                Ответить
                            </button>

                            <LikeButton
                                data={comment}
                                model="Comment"
                                // isLikedByUser={comment.isLikedByUser(auth_id)}
                                usersWhoLiked={comment.likes?.map(
                                    (like) => like.user_id
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={
                        "comment-children " +
                        (isChildOfAChild ? "" : "ms-4 ps-3")
                    }
                >
                    {children?.length
                        ? children.map((child) => (
                              <CommentComponent
                                  comment={child}
                                  key={child.id}
                                  stena={stena}
                                  children={stena.comments.filter(
                                      (_child) => _child.parent_id === child.id
                                  )}
                                  isChildOfAChild={true}
                                  handleCommentsToggle={handleCommentsToggle}
                              />
                          ))
                        : ""}
                </div>
            </div>
        </>
    );
};

export default CommentComponent;
