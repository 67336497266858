import React from "react";
import UserLogo from "./Stena/UserLogo";
import { useSelector } from "react-redux";

function BurgerMenu() {
    //Кирилл: общее - необходимо разбить данный компонент (в части вывода (return) на несколько компонентов)
    //Попробуй тут воспроизвести следующий паттерн (данные и названия от балды у меня):
    // const menuElement = [
    //     {id: 1, name: "Мои мяуки", link: "/meow"},
    //     {id: 2, name: "Моя стена", link: "/wall"},
    // ]
    // ...
    // return (
    //     {menuElement.map((element) => (
    //         <Element key={element.id} element={element} />
    //     ))
    // }
    // )
    //И таким паттерном вывести все пункты меню и дропдауны. Условно, компонент должен быть строк на 50, остальное - более мелкие компоненты,
    //примем переиспользуемые компоненты (то есть, ты создаешь компонент типа Element - название может быть и другое - и для всех пункто его выводишь, передавая только инфу из массива)

    const currentUser = useSelector((state) => state.users?.currentUser);
    const meowcoins = currentUser?.meow || 0; // Получаем количество мяукоинов из currentUser
    const rediks = currentUser?.redik || 0; // Получаем количество редиков из currentUser
    const username = currentUser?.name
        ? currentUser.name
              .split(" ")
              .map((name, index) => (index === 0 ? name : name.charAt(0) + "."))
              .join(" ")
        : 0;

    return (
        <>
            <button
                className="btn d-block d-lg-none rounded-start bg-white border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#staticBackdrop"
                aria-controls="staticBackdrop"
            >
                <img className="" src="/img/sidebar-menu.svg" />
            </button>

            <div
                className="offcanvas offcanvas-start overflow-auto"
                // data-bs-backdrop="static"
                tabIndex="-1"
                id="staticBackdrop"
                aria-labelledby="staticBackdropLabel"
                style={{ width: "300px" }}
            >
                <button
                    type="button"
                    className="btn-close justify-content-end position-absolute top-0 end-0 mt-3 me-3"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
                <div className=" d-flex flex-column ">
                    <div className="container-fluid gap-2 ">
                        <div className="ps-2 pt-2 pe-2">
                            <div
                                className="accordion accordion-flush"
                                id="profileAccordion"
                            >
                                <div className="accordion-item ">
                                    <h2
                                        className="accordion-header"
                                        id="profileHeading"
                                    >
                                        <button
                                            className="btn border-0 dropdown-toggle d-flex gap-2  align-items-center ps-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#profileCollapse"
                                            aria-expanded="true"
                                            aria-controls="profileCollapse"
                                        >
                                            {currentUser?.id ? (
                                                <UserLogo
                                                    user={currentUser}
                                                    size="extra-small"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            Профиль
                                        </button>
                                    </h2>
                                    <div
                                        id="profileCollapse"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="profileHeading"
                                    >
                                        <div className="accordion-body d-flex flex-column gap-2">
                                            <a
                                                className="dropdown-item "
                                                href="/profile"
                                            >
                                                Профиль
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/UserBlocks"
                                            >
                                                Игра
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/Attachment"
                                            >
                                                Файлы
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/user/my/transactions"
                                            >
                                                Транзакции
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="{{ route('subscriptions.index') }}"
                                            >
                                                Управление подписками
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="{{ route('chat.index') }}"
                                            >
                                                Сообщения
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/emails"
                                            >
                                                Письма
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                                //Кирилл: тут совсем неправильно с точки зрения реакта:
                                                // onclick="event.preventDefault(); document.getElementById('logout-form').submit();"
                                            >
                                                Выход
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex gap-1 align-items-center">
                                <div className="btn border-0 d-flex gap-2 ps-0">
                                    <img
                                        className="w-20"
                                        src="/img/bell-28px.svg"
                                    />
                                    Уведомления
                                </div>
                                <div
                                    className="rounded-circle bg-primary ps-0"
                                    style={{ width: "8px", height: "8px" }}
                                ></div>
                            </div>

                            <div className="btn border-0 d-flex gap-2 ps-0">
                                <img
                                    className="w-20"
                                    src="/img/multi-bubble-light.svg"
                                />
                                Сообщения
                            </div>
                            <div className="btn border-0 d-flex gap-2 ps-0">
                                <img
                                    className="w-20"
                                    src="/img/group-light.svg"
                                />
                                Коллеги
                            </div>
                            <div className="btn border-0 d-flex gap-2 ps-0">
                                <img
                                    className="w-20"
                                    src="/img/meowmarket-light.svg"
                                />
                                МяуМаркет
                            </div>
                        </div>
                    </div>
                    <hr className="border-1 border-gray-4" />
                    <div className="container-fluid gap-2 ">
                        <div className="ps-2 pt-2 pe-2">
                            <div className="accordion accordion-flush">
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header">
                                        <button
                                            className="btn border-0 dropdown-toggle d-flex gap-2 align-items-center ps-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#megaphoneCollapse"
                                            aria-expanded="true"
                                            aria-controls="megaphoneCollapse"
                                        >
                                            <img
                                                className="w-20 me-2"
                                                src="/img/megaphone.svg"
                                            />
                                            Будь в курсе
                                        </button>
                                    </h2>
                                    <div
                                        id="megaphoneCollapse"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Приказы
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Блоги
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/digests"
                                            >
                                                Дайджесты
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/announcements"
                                            >
                                                Объявления
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/polls"
                                            >
                                                Опросы
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/edu/tests"
                                            >
                                                Админ Тесты
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="/calendars"
                                            >
                                                Кадендарь
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item  border-0">
                                    <h2 className="accordion-header">
                                        <button
                                            className="btn border-0 dropdown-toggle d-flex gap-2 align-items-center ps-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#graduationCollapse"
                                            aria-expanded="false"
                                            aria-controls="graduationCollapse"
                                        >
                                            <img
                                                className="w-20 me-2"
                                                src="/img/graduation-cap.svg"
                                            />
                                            Обучение и развитие
                                        </button>
                                    </h2>
                                    <div
                                        id="graduationCollapse"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <a
                                                className="dropdown-item"
                                                href="/Training"
                                            >
                                                Полезные материалы
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Настольные игры
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Мероприятия
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Библиотека
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Идеи
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item  border-0">
                                    <h2 className="accordion-header">
                                        <button
                                            className="btn border-0 dropdown-toggle d-flex gap-2 align-items-center ps-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#profileCircleCollapse"
                                            aria-expanded="false"
                                            aria-controls="profileCircleCollapse"
                                        >
                                            <img
                                                className="w-20 me-2"
                                                src="/img/profile-circle.svg"
                                            />
                                            Сервисы сотрудника
                                        </button>
                                    </h2>
                                    <div
                                        id="profileCircleCollapse"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Видеоконференция
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Прямая линия
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Бот Кошка Каролина
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                Линия доверия
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                            >
                                                МяуМаркет
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="border-1 border-gray-4" />
                <div className="container-fluid gap-2 ">
                    <div className="row align-items-center">
                        <div className="d-flex flex-column gap-2 ms-2">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center col-3 gap-1">
                                    <img
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        src="/img/meow.svg"
                                        alt="Мяу-коины"
                                    />
                                    {meowcoins}
                                </div>
                                <div className="d-flex align-items-start">
                                    <button className="btn btn-sm px-3 py-2 border-0 btn-light active justify-content-center d-block">
                                        <img
                                            src="/img/simple-cart.svg"
                                            className="me-2"
                                        />
                                        Потратить
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center col-3 gap-1">
                                    <img
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        src="/img/redik.svg"
                                        alt="Редики"
                                    />
                                    {rediks}
                                </div>
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-sm px-3 py-2 border-0 btn-light active justify-content-center d-block">
                                        <img
                                            src="/img/dice-six.svg"
                                            className="me-2"
                                        />
                                        Рандомное спасибо
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-4">
                                <div className="d-flex flex-column p-1">
                                    <div className="ps-2 pt-2 pb-2 text-secondary">
                                        <img
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                            }}
                                            className="me-2"
                                            src="/img/meow.svg"
                                            alt="Мяу-коины"
                                        />
                                        {meowcoins}
                                    </div>
                                    <div className="ps-2 pt-2 pb-2 text-secondary">
                                        <img
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                            }}
                                            className="me-2"
                                            src="/img/redik.svg"
                                            alt="Редики"
                                        />
                                        123
                                    </div>
                                </div>
                            </div>
                            <div className="col-8">
                                <button className="btn btn-sm px-2 border-0 btn-light active justify-content-center d-block mb-2">
                                    <img
                                        src="/img/simple-cart.svg"
                                        className="me-2"
                                    />
                                    Потратить
                                </button>
                                <button className="btn btn-sm px-2 border-0 btn-light active justify-content-center d-block">
                                    <img
                                        src="/img/dice-six.svg"
                                        className="me-2"
                                    />
                                    Рандомное спасибо
                                </button>
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
export default BurgerMenu;

// для гита
