const MeowSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <desc>Created with Pixso.</desc>
            <defs>
                <filter
                    id="filter_22_4928_dd"
                    x="0.000000"
                    y="4.450195"
                    width="16"
                    height="16"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0" dy="4" />
                    <feGaussianBlur stdDeviation="1.33333" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.18824 0 0 0 0 0.17647 0 0 0 0 0.14118 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect_dropShadow_1"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect_dropShadow_1"
                        result="shape"
                    />
                </filter>
            </defs>
            <path
                id="Vector"
                d="M8.30615 16C12.7119 16 16.3057 12.4062 16.3057 7.99951C16.3057 3.59277 12.7119 0 8.30615 0C3.90039 0 0.305664 3.59375 0.305664 7.99951C0.305664 12.4053 3.89941 16 8.30615 16Z"
                fill="#FB8500"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <g filter="url(#filter_22_4928_dd)">
                <path
                    id="Vector"
                    d="M6.63281 14.3618C6.63281 14.3286 6.6333 14.2544 6.6333 14.1528C6.63379 13.7705 6.63525 13.0005 6.63281 12.562C6.62988 11.9819 6.86133 11.5225 7.08154 11.3027C5.5791 11.1357 4 10.5635 4 8.01318C4 7.28662 4.26221 6.69287 4.69629 6.22705C4.62012 6.05908 4.3916 5.38232 4.75537 4.46533C4.75537 4.46533 5.32227 4.28711 6.61572 5.14795C7.15723 5 7.73193 4.92676 8.30713 4.92334C8.88232 4.92676 9.45703 5 9.99854 5.14795C11.2842 4.28711 11.8506 4.46533 11.8506 4.46533C12.2139 5.38232 11.9858 6.05908 11.918 6.22705C12.3496 6.69287 12.6118 7.28662 12.6118 8.01318C12.6118 10.5703 11.0303 11.1333 9.5249 11.2969C9.76172 11.4966 9.98145 11.9048 9.98145 12.5283C9.98145 13.4194 9.97314 14.1353 9.97314 14.3511C9.97314 14.5259 10.0483 14.6675 10.438 14.6675C10.438 14.6675 6.65771 14.6743 6.20117 14.6753C6.54395 14.6694 6.63281 14.5342 6.63281 14.3618ZM6.20117 14.6753C6.19336 14.6758 6.18506 14.6758 6.17676 14.6758L6.15625 14.6758C6.15625 14.6758 6.17188 14.6758 6.20117 14.6753Z"
                    fill="#F4CB01"
                    fillOpacity="1.000000"
                    fillRule="evenodd"
                />
            </g>
            <path
                id="Vector"
                d="M8.30615 0C3.89941 0 0.305664 3.59375 0.305664 7.99951C0.305664 12.4053 3.89941 16 8.30615 16C12.7129 16 16.3057 12.4062 16.3057 7.99951C16.3057 3.59277 12.7119 0 8.30615 0ZM8.30615 14.1069C4.94189 14.1069 2.19873 11.3638 2.19873 7.99951C2.19873 4.63525 4.94189 1.89209 8.30615 1.89209C11.6704 1.89209 14.4136 4.63623 14.4136 7.99951C14.4136 11.3628 11.6694 14.1069 8.30615 14.1069Z"
                fill="#FFB703"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
            <path
                id="Vector"
                d="M2.65381 2.34717L3.99121 3.68457C5.09717 2.57812 6.62402 1.89209 8.30615 1.89209L8.30615 0C6.10303 0 4.10303 0.898438 2.65381 2.34717ZM13.9585 13.6519L12.6211 12.3145C11.5146 13.4209 9.98779 14.1069 8.30615 14.1069L8.30615 16C10.5093 16 12.5093 15.1016 13.9585 13.6519L13.9585 13.6519ZM13.958 2.34766L12.6211 3.68457C13.7275 4.79102 14.4136 6.31787 14.4136 7.99951L16.3057 7.99951C16.3057 5.79688 15.4072 3.79688 13.958 2.34766ZM2.65332 13.6523L3.99072 12.3149C2.88428 11.2085 2.19873 9.68164 2.19873 7.99951L0.305664 7.99951C0.305664 10.2026 1.2041 12.2031 2.65332 13.6523Z"
                fill="#F4CB01"
                fillOpacity="1.000000"
                fillRule="nonzero"
            />
        </svg>
    );
};

export default MeowSVG;
