const RewardSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.51452 6.96325L12 1.33325M6.21075 7.0881L3.33333 1.33325M8.50769 6.6985L5.89 1.33325M9.57333 1.33325L8.88 2.99992M4 10.6666C4 12.8757 5.79086 14.6666 8 14.6666C10.2091 14.6666 12 12.8757 12 10.6666C12 8.45745 10.2091 6.66658 8 6.66658C5.79086 6.66658 4 8.45745 4 10.6666Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RewardSVG;
