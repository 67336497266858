import CloseSVG from "../Icons/CloseSVG";

const AwardsList = ({
    awardsForUsers,
    extraTypes,
    removeAwardFromUser,
    handleUserClick,
    handleAddMoreClick,
}) => {
    return (
        <div className="d-flex gap-1 align-items-top">
            <div>
                <img
                    className={"h-32 " + (extraTypes !== "badge" ? "w-32" : "")}
                    style={extraTypes === "badge" ? { width: "68px" } : {}}
                    src={awardsForUsers[0]?.image}
                    title={awardsForUsers[0]?.title}
                />
            </div>
            <div className="d-flex gap-1 flex-wrap">
                {awardsForUsers.map((award, index) => (
                    <div key={index} className="pb-1">
                        <span
                            role="button"
                            onClick={handleUserClick}
                            data-id={award.user.id}
                        >
                            {"@" + award.user?.name}
                        </span>{" "}
                        <CloseSVG onClick={() => removeAwardFromUser(award)} />
                    </div>
                ))}
                <span
                    className="bg-gray-3 px-1 rounded ms-1"
                    role="button"
                    onClick={handleAddMoreClick}
                >
                    + Выбрать еще
                </span>
            </div>
        </div>
    );
};

export default AwardsList;
