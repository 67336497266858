import React, { useState, useEffect, useRef } from "react";
import BurgerMenu from "./BurgerMenu";
import { current } from "@reduxjs/toolkit";

const SearchComponent = () => {
    // Стейт переменные
    const [query, setQuery] = useState("");
    const [model, setModel] = useState("all");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModelDropdown, setShowModelDropdown] = useState(false);
    const [showResultsDropdown, setShowResultsDropdown] = useState(false);
    const [moreResultsCount, setMoreResultsCount] = useState(0);
    const [hasMoreResults, setHasMoreResults] = useState(false);

    // Ссылка на элемент для обработки клика за его пределами
    const searchRef = useRef(null);

    // Обработчик клика за пределами компонента
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target)
            ) {
                setShowModelDropdown(false);
                setShowResultsDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // Функция для отправки запроса на сервер
    const fetchData = async () => {
        if (query.length >= 2) {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `/search?model=${model}&query=${encodeURIComponent(query)}`
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                // Обновленная логика для обработки результатов
                if (model === "all") {
                    // Соединяем результаты всех моделей в один массив
                    let combinedResults = [];
                    for (let key in data) {
                        if (data[key] && data[key].count && data[key].data) {
                            combinedResults = combinedResults.concat(
                                data[key].data
                            );
                        }
                    }
                    setResults(combinedResults);
                } else {
                    // Для конкретной модели
                    setResults(
                        data[model] && data[model].data ? data[model].data : []
                    );
                }

                // Обновите количество доступных результатов
                setMoreResultsCount(
                    data[model] && data[model].count
                        ? data[model].count - results.length
                        : 0
                );
                // Установите флаг, есть ли еще результаты
                setHasMoreResults(
                    data[model] && data[model].more ? data[model].more : false
                );

                setShowResultsDropdown(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setResults([]);
            setShowResultsDropdown(false);
        }
    };

    // Обработчик изменения запроса и модели
    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 500);

        return () => clearTimeout(timerId);
    }, [query, model]);

    // Маппинг для перевода моделей
    const modelTranslations = {
        all: "Везде",
        user: "Сотрудники",
        stena: "Стена",
        shop: "МяуМаркет",
        Training: "Обучение",
    };
    const modelTranslationsRU = {
        all: "порталу вместе с Кошкой Каролиной",
        user: "сотрудникам",
        stena: "стене",
        shop: "МяуМаркету",
        Training: "Обучению",
    };

    return (
        <div ref={searchRef} className="position-relative ">
            <div className="input-group sk-active-primary ">
                <button
                    className="btn btn-gray-3-bg d-none d-lg-flex border-gray-4-bg dropdown-toggle text-gray-6-text align-items-center"
                    type="button"
                    aria-expanded={showModelDropdown}
                    onClick={() => setShowModelDropdown(!showModelDropdown)}
                >
                    {modelTranslations[model]}
                </button>
                <ul
                    style={{ cursor: "pointer", zIndex: 9099999999 }}
                    className={`dropdown-menu${
                        showModelDropdown ? " show" : ""
                    }`}
                >
                    {Object.keys(modelTranslations).map((key) => (
                        <li key={key}>
                            <a
                                className="dropdown-item"
                                onClick={() => {
                                    setModel(key);
                                    setShowModelDropdown(false);
                                }}
                            >
                                {modelTranslations[key]}
                            </a>
                        </li>
                    ))}
                </ul>

                <BurgerMenu />
                <input
                    type="text"
                    className={
                        "form-control form-control-lg  " +
                        (window.innerWidth < 768
                            ? "rounded-end border-0"
                            : "border-gray-4") //костыль
                    }
                    aria-label="Поиск по сайту"
                    placeholder={`Поиск по ${modelTranslationsRU[model]} `}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />

                <span className="input-group-text d-none d-lg-flex align-items-center border-gray-4">
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (
                        <svg
                            className="d-none d-lg-block"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 17L21 21"
                                stroke="#8C8C8C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z"
                                stroke="#8C8C8C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </span>
            </div>
            {showResultsDropdown && (
                <div className="sk-search-inactive col-5 ps-0">
                    <div
                        className="dropdown-menu show w-100"
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                    >
                        {results.length > 0 ? (
                            results.map((result) => {
                                if (
                                    result &&
                                    result.id &&
                                    result.role_id != 3
                                ) {
                                    const url = `/${result.type}/${result.id}`;
                                    let imageUrl;

                                    if (result.type === "user") {
                                        imageUrl = result.avatar_url
                                            ? `/${result.avatar_url}`
                                            : null;
                                    } else if (result.type === "shop") {
                                        imageUrl = `/storage/${result.image}`;
                                    }

                                    return (
                                        <a
                                            key={result.id}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            className="dropdown-item text-overflow d-flex align-items-center"
                                            href={url}
                                        >
                                            {imageUrl ? (
                                                <div className="position-relative me-2">
                                                    <img
                                                        src={imageUrl}
                                                        alt={result.name}
                                                        className="rounded-circle mr-2"
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                    {result.position_name &&
                                                    (result.position_name
                                                        .toLowerCase()
                                                        .includes(
                                                            "руководитель"
                                                        ) ||
                                                        result.position_name
                                                            .toLowerCase()
                                                            .includes(
                                                                "директор"
                                                            )) ? (
                                                        <span
                                                            className="position-absolute col float-right align-self-end translate-middle badge rounded-circle bg-success"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                        >
                                                            ✓
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <div
                                                    className="flex-shrink-0 position-relative rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mr-2"
                                                    style={{
                                                        width: "48px",
                                                        height: "48px",
                                                    }}
                                                >
                                                    {result.short_name
                                                        ? result.short_name
                                                        : "стена"}
                                                    {result.position_name &&
                                                    (result.position_name
                                                        .toLowerCase()
                                                        .includes(
                                                            "руководитель"
                                                        ) ||
                                                        result.position_name
                                                            .toLowerCase()
                                                            .includes(
                                                                "директор"
                                                            )) ? (
                                                        <span
                                                            className="position-absolute col float-right align-self-end  translate-middle badge rounded-circle bg-success"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                        >
                                                            ✓
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}

                                            <div>
                                                <div
                                                    className="m-2 mb-1 text-wrap"
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.name,
                                                    }}
                                                />
                                                {result.department_name && (
                                                    <div className="ms-2">
                                                        {result.department_name}{" "}
                                                        - {result.position_name}
                                                    </div>
                                                )}

                                                {result.summ ? (
                                                    <>
                                                        <div className="p-1 text-primary">
                                                            <img
                                                                style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                }}
                                                                className="meow-coin"
                                                                src="/img/meow.svg"
                                                                alt="Meow Coin"
                                                            />
                                                            &nbsp;{result.summ}
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </a>
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <div className="dropdown-item">Нет результатов</div>
                        )}

                        {hasMoreResults && (
                            <div className="card card-body">
                                <a
                                    className="bange"
                                    href={`/isearch?model=${model}&query=${encodeURIComponent(
                                        query
                                    )}&page=2`}
                                >
                                    Показать еще результаты
                                </a>
                                <span>({moreResultsCount} оставшихся)</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchComponent;
