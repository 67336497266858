import { useState } from "react";
import BadgeCard from "./BadgeCard";

const BadgesListInModal = ({ badges = [], onBadgeClick }) => {
    const [selectedBadgeId, setSelectedBadgeId] = useState(null);
    const handleBadgeSelect = (badge) => {
        setSelectedBadgeId(badge.id);
        onBadgeClick(badge);
    };
    return (
        <div className="d-flex flex-wrap">
            {badges.length
                ? badges.map((badge) => (
                      <BadgeCard
                          key={badge.id}
                          badge={badge}
                          onBadgeClick={handleBadgeSelect}
                          selectedBadgeId={selectedBadgeId}
                      />
                  ))
                : ""}
        </div>
    );
};

export default BadgesListInModal;
