import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import StenaComponent from "./StenaComponent";
import { useSelector } from "react-redux";
import ReusableModal from "../ReusableModal";

const InfiniteScrollComponent = ({ hasMore, fetchMoreData, filter }) => {
    const [isModalOpen, setIsModalOpen] = useState(null);
    const { stenas } = useSelector((state) => state.stenas);
    // console.log("stenas", stenas);

    return (
        <div>
            <div className="d-none d-md-block">
                <InfiniteScroll
                    dataLength={stenas.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>Загружаем...</h4>}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Вот и всё ребята!</b>
                        </p>
                    }
                    className="d-flex flex-column gap-4"
                >
                    {stenas
                        .filter((stena) => {
                            if (filter) {
                                return stena[filter.field] === filter.value;
                            }
                            return stena;
                        })
                        .map((stena) => (
                            <React.Fragment key={stena.id}>
                                <StenaComponent
                                    key={"stena_" + stena.id}
                                    stena={stena}
                                    model={"Stena"}
                                    onOpenModal={() => setIsModalOpen(stena.id)}
                                />
                                {isModalOpen === stena.id ? (
                                    <ReusableModal
                                        key={stena.id}
                                        content={
                                            <StenaComponent
                                                key={"modal_" + stena.id}
                                                stena={stena}
                                                model={"Stena"}
                                                textTruncate={false}
                                            />
                                        }
                                        show={isModalOpen}
                                        onClose={() => setIsModalOpen(null)}
                                        large={true}
                                        footer={false}
                                        header={false}
                                    />
                                ) : (
                                    ""
                                )}
                            </React.Fragment>
                        ))}
                </InfiniteScroll>
            </div>

            <div className="d-block d-md-none">
                <InfiniteScroll
                    dataLength={stenas.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>Загружаем...</h4>}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Вот и всё ребята!</b>
                        </p>
                    }
                    className="d-flex flex-column gap-2"
                >
                    {stenas.map((stena) => (
                        <React.Fragment key={stena.id}>
                            <StenaComponent
                                key={"stena_" + stena.id}
                                stena={stena}
                                model={"Stena"}
                                onOpenModal={() => setIsModalOpen(stena.id)}
                            />
                            {isModalOpen === stena.id ? (
                                <ReusableModal
                                    key={stena.id}
                                    content={
                                        <StenaComponent
                                            key={"modal_" + stena.id}
                                            stena={stena}
                                            model={"Stena"}
                                            textTruncate={false}
                                        />
                                    }
                                    show={isModalOpen}
                                    onClose={() => setIsModalOpen(null)}
                                    large={true}
                                    footer={false}
                                    header={false}
                                />
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default InfiniteScrollComponent;
