function MenuBadgeHandler({ data }) {
    // Функция для обновления бейджа (метки с числом) для каждого пункта меню
    const updateBadge = (badgeId, newData) => {
        const badgeElement = document.getElementById(badgeId);
        if (badgeElement && newData !== undefined) {
            // Отображаем количество только если оно больше 0
            badgeElement.textContent = newData > 0 ? newData : "";
        }
    };

    // Обновляем бейджи для каждой категории, используя switch. Предположил, что в data передается какой-то data.type из клика
    switch (data.type) {
        case "orders-badge":
            updateBadge("orders-badge", data.orders); // Приказы
            break;
        case "blogs-badge":
            updateBadge("blogs-badge", data.blogs); // Блоги
            break;
        case "digests-badge":
            updateBadge("digests-badge", data.digests); // Дайджесты
            break;
        case "announcements-badge":
            updateBadge("announcements-badge", data.announcements); // Объявления
            break;
        case "polls-badge":
            updateBadge("polls-badge", data.polls); // Опросы
            break;
        case "calendar-badge":
            updateBadge("calendar-badge", data.calendar); // Календарь
            break;
        case "training-badge":
            updateBadge("training-badge", data.training); // Обучение и развитие
            break;
        case "resources-badge":
            updateBadge("resources-badge", data.resources); // Полезные материалы
            break;
        case "board-games-badge":
            updateBadge("board-games-badge", data.boardGames); // Настольные игры
            break;
        case "events-badge":
            updateBadge("events-badge", data.events); // Мероприятия
            break;
        case "library-badge":
            updateBadge("library-badge", data.library); // Библиотека
            break;
        case "ideas-badge":
            updateBadge("ideas-badge", data.ideas); // Идеи
            break;
        case "employee-services-badge":
            updateBadge("employee-services-badge", data.employeeServices); // Сервисы сотрудника
            break;
        case "video-conference-badge":
            updateBadge("video-conference-badge", data.videoConference); // Видеоконференция
            break;
        case "hotline-badge":
            updateBadge("hotline-badge", data.hotline); // Прямая линия
            break;
        case "carolina-cat-bot-badge":
            updateBadge("carolinaCatBot-badge", data.carolinaCatBot); // Бот Кошка Каролина
            break;
        case "trust-line-badge":
            updateBadge("trust-line-badge", data.trustLine); // Линия доверия
            break;
        case "mau-market-badge":
            updateBadge("mau-market-badge", data.mauMarket); // МяуМаркет
            break;
        default:
            break;
    }
}

export default MenuBadgeHandler;
