import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TransferCurrency = ({ recipientId }) => {
    const [showModal, setShowModal] = useState(false);
    const [meowAmount, setMeowAmount] = useState("");
    const [redikAmount, setRedikAmount] = useState("");
    const [maxMeow, setMaxMeow] = useState(0);
    const [maxRedik, setMaxRedik] = useState(0);

    useEffect(() => {
        // Подписываемся на изменения в DOM для авто-обновления доступных валют
        const observer = new MutationObserver(() => {
            setMaxMeow(
                parseInt(
                    document.getElementById("maucony-count").textContent,
                    10
                )
            );
            setMaxRedik(
                parseInt(
                    document.getElementById("rediki-count").textContent,
                    10
                )
            );
        });

        observer.observe(document.getElementById("maucony-count"), {
            childList: true,
        });
        observer.observe(document.getElementById("rediki-count"), {
            childList: true,
        });

        return () => observer.disconnect(); // Отписываемся при размонтировании компонента
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
        updateMaxAmounts();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setMeowAmount("");
        setRedikAmount("");
    };

    const updateMaxAmounts = () => {
        setMaxMeow(
            parseInt(document.getElementById("maucony-count").textContent, 10)
        );
        setMaxRedik(
            parseInt(document.getElementById("rediki-count").textContent, 10)
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "meowAmount")
            setMeowAmount(Math.max(0, Math.min(maxMeow, value)));
        if (name === "redikAmount")
            setRedikAmount(Math.max(0, Math.min(maxRedik, value)));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "/AccrualOfUserFundsForTheRecipient",
                {
                    recipient_id: recipientId,
                    meow: parseInt(meowAmount, 10) || 0,
                    redik: parseInt(redikAmount, 10) || 0,
                }
            );
            toast.success(response.data.message);
            handleCloseModal();
            updateMaxAmounts(); // Обновляем максимальные значения после успешной транзакции
        } catch (error) {
            toast.error(
                `Ошибка при выполнении перевода: ${
                    error.response?.data?.message || "Произошла ошибка"
                }`
            );
        }
    };

    return (
        <div>
            <button onClick={handleOpenModal} className="btn btn-primary">
                Перевести валюту
            </button>

            {showModal && (
                <div
                    className="modal show"
                    tabIndex="-1"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Перевод валюты</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={handleCloseModal}
                                >
                                    &times;
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="meowAmount">
                                            Мяукоины (доступно: {maxMeow}):
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="meowAmount"
                                            value={meowAmount}
                                            onChange={handleChange}
                                            min="0"
                                            max={maxMeow}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="redikAmount">
                                            Редики (доступно: {maxRedik}):
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="redikAmount"
                                            value={redikAmount}
                                            onChange={handleChange}
                                            min="0"
                                            max={maxRedik}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleCloseModal}
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Перевести
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransferCurrency;
