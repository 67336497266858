import Pusher from "pusher-js";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyUpdateHandler from "./CurrencyUpdateHandler";
import DynamicStatsHandler from "./DynamicStatsHandler";
import MenuBadgeHandler from "./MenuBadgeHandler";
import NotificationsListUpdate from "./NotificationsListUpdate";
import ToastEventHandler from "./ToastEventHandler";
import StaticEventHandler from "./StaticEventHandler";
import UserActivityTracker from "./UserActivityTracker";
import { useDispatch, useSelector } from "react-redux";
import {
    addComment,
    addLike,
    removeLike,
    addStena,
} from "../../redux/stenasSlice";
import GratitudeLeaders from "./GratitudeLeaders";

function WebSocketComponent() {
    const dispatch = useDispatch();
    const { Shop, Badge, Medal } = useSelector(
        (state) => state.extraTypes.extraTypes
    );
    useEffect(() => {
        Pusher.logToConsole = false;
        const pusher = new Pusher("AnyKeyYouLike", {
            cluster: "eu",
            wsHost: window.location.hostname,
            wsPort: 6001,
            forceTLS: false,
            disableStats: true,
            enabledTransports: ["ws", "wss"],
        });

        if (authUserId === null) {
            fetch("/get-current-user-id")
                .then((response) => response.json())
                .then((datauser) => {
                    const authUserId = datauser.userId;
                });
        }

        //  console.log("authUserId: " + authUserId);

        //Определяем личный канал юзера
        const UserChannel = pusher.subscribe("UserChannel." + authUserId);

        // личные евенты для authUserId

        fetch("/chat/getUserDialogIds")
            .then((response) => response.json())
            .then((data) => {
                // Subscribe to channels for each dialog ID
                data.dialogIds.forEach((dialogId) => {
                    const UserChannelChat = pusher.subscribe(
                        "ChatEvent." + dialogId
                    );

                    UserChannelChat.bind(
                        "App\\Events\\ChatEvent",
                        function (event) {
                            console.log(event);
                            if (event.type === "newDialog") {
                                // Handle newDialog event
                            } else if (event.type === "newMessage") {
                                const dialogElement = document.getElementById(
                                    `chat-dialog-${event.data.message.dialog_id}`
                                );
                                const dialogId = event.data.message.dialog_id;
                                const dialogElementdialogId =
                                    document.querySelector(
                                        `[data-dialog-id="${dialogId}"]`
                                    );
                                if (dialogElementdialogId) {
                                    // Обновление аватара, имени и текста диалога
                                    const avatarElement =
                                        dialogElementdialogId.querySelector(
                                            ".rounded-circle"
                                        );
                                    const nameElement =
                                        dialogElementdialogId.querySelector(
                                            ".mb-1"
                                        );
                                    const textElement =
                                        dialogElementdialogId.querySelector(
                                            ".text-muted"
                                        );

                                    avatarElement.src =
                                        event.data.sender.avatar &&
                                        event.data.sender.avatar.thumbnail_url
                                            ? event.data.sender.avatar
                                                  .thumbnail_url
                                            : "/img/sk-logo.svg";
                                    nameElement.textContent =
                                        event.data.sender.name;

                                    // Обрезаем текст сообщения до определенного количества символов (например, 30)
                                    const truncatedText =
                                        event.data.message.body.length > 28
                                            ? event.data.message.body.substring(
                                                  0,
                                                  28
                                              ) + "..."
                                            : event.data.message.body;
                                    textElement.textContent = truncatedText;
                                }

                                // ...

                                if (
                                    dialogElement &&
                                    event.data.sender &&
                                    event.data.message.sender_id !== authUserId
                                ) {
                                    // Создание внешнего контейнера для сообщения
                                    const messageContainer =
                                        document.createElement("div");
                                    messageContainer.key =
                                        event.data.message.id;
                                    messageContainer.className = `whatsapp-message-container ${
                                        event.data.message.sender_id ===
                                        authUserId
                                            ? "whatsapp-right"
                                            : "whatsapp-left"
                                    }`;

                                    // Создание и настройка элемента аватара, если отправитель не текущий пользователь
                                    if (
                                        event.data.message.sender_id !==
                                        authUserId
                                    ) {
                                        const avatarElement =
                                            document.createElement("img");
                                        avatarElement.src =
                                            event.data.sender.avatar &&
                                            event.data.sender.avatar
                                                .thumbnail_url
                                                ? event.data.sender.avatar
                                                      .thumbnail_url
                                                : "/img/sk-logo.svg";
                                        avatarElement.alt = `${event.data.sender.name}'s Avatar`;
                                        avatarElement.className = `whatsapp-avatar ${
                                            event.data.message.sender_id ===
                                            authUserId
                                                ? "whatsapp-avatar-right"
                                                : ""
                                        }`;
                                        messageContainer.appendChild(
                                            avatarElement
                                        );
                                    }

                                    // Создание контейнера для текста сообщения
                                    const messageTextContainer =
                                        document.createElement("div");
                                    messageTextContainer.className = `whatsapp-message-text ${
                                        event.data.message.sender_id ===
                                        authUserId
                                            ? "whatsapp-right-text"
                                            : ""
                                    }`;

                                    // Создание элемента для имени отправителя (если не текущий пользователь)
                                    if (
                                        event.data.message.sender_id !==
                                        authUserId
                                    ) {
                                        const senderNameElement =
                                            document.createElement("div");
                                        senderNameElement.className = "mb-1";
                                        const senderNameLink =
                                            document.createElement("a");
                                        senderNameLink.href = `/user/${event.data.sender.id}`;
                                        senderNameLink.className =
                                            "user-link d-block";
                                        senderNameLink.textContent =
                                            event.data.sender.name;
                                        senderNameElement.appendChild(
                                            senderNameLink
                                        );
                                        messageTextContainer.appendChild(
                                            senderNameElement
                                        );
                                    }

                                    // Создание элемента для текста сообщения
                                    const messageText =
                                        document.createElement("p");
                                    messageText.className = "mb-0";
                                    messageText.textContent =
                                        event.data.message.body;
                                    messageTextContainer.appendChild(
                                        messageText
                                    );

                                    // Добавление контейнера текста сообщения во внешний контейнер сообщения
                                    messageContainer.appendChild(
                                        messageTextContainer
                                    );

                                    // Добавление внешнего контейнера сообщения в диалог
                                    dialogElement.appendChild(messageContainer);
                                }

                                // ...

                                // alert(event.data.message.sender_id);
                            } else if (event.type === "typing") {
                                // Handle typing event
                            }
                        }
                    );
                });
            })
            .catch((error) => {
                console.error("Error fetching dialog IDs:", error);
            });

        UserChannel.bind(
            "App\\Events\\PrivateCurrencyUpdateEvent",
            function (data) {
                if (data.id === authUserId) {
                    CurrencyUpdateHandler({ data }); // валюта  //   medal.meow и medal.redik
                }
            }
        );

        UserChannel.bind(
            "App\\Events\\PrivateUserNewCommentEvent",
            function (data) {
                if (data.comment.author_id != authUserId) {
                    alert("Received a new comment: " + data.comment.id);
                }
            }
        );

        // личные евенты для authUserId

        // общие евенты
        const channel = pusher.subscribe("home");

        channel.bind("App\\Events\\NewMedalUserEvent", (data) => {
            if (data.user.id === authUserId) {
                //   ToastEventHandler({ data });
                CurrencyUpdateHandler({ data }); // валюта  //   medal.meow и medal.redik
            }
        });

        channel.bind("App\\Events\\GratitudeLeaders", (data) => {
            const GratitudeLeadersElement =
                document.getElementById("gratitudeLeaders");
            if (GratitudeLeadersElement) {
                GratitudeLeaders({ data });
            }
        });

        channel.bind("App\\Events\\StaticOnline", (data) => {
            StaticEventHandler({ data });
        });

        channel.bind("App\\Events\\PollEvent", (data) => {
            /// ну например. если автор был на двух страницах то на второй должен добавиться блок
            existingElement = document.getElementById(`poll_${data.userid}`);
            existingElementUserid = document.getElementById(
                `poll_${data.userid}_${data.poll.id}`
            );
            if (existingElement) {
                if (!existingElementUserid) {
                    existingElement.innerHTML = `
        <h2>${data.title}</h2>
        <p>Created: ${data.poll.id}</p>
        <p>Created: ${data.poll.created_at}</p>
        <p>Author: ${data.poll.creator_id}</p>
        <p>Anonymous: ${poll.poll.is_anonymous ? "Да" : "Нет"}</p>
      `;
                    document.querySelector(".container").appendChild(pollBlock);
                }
            }
        });

        channel.bind("App\\Events\\LikeEvent", (data) => {
            // LikeEventHandler({ data });
            if (data.action === "add") {
                dispatch(addLike({ data }));
                if (data.author === authUserId && data.author !== data.userid) {
                    ToastEventHandler({ data });
                }
            } else if (data.action === "remove") {
                dispatch(removeLike({ data }));
            }
        });

        channel.bind("App\\Events\\NewCommentEvent", (data) => {
            // CommentEventHandler({ data }); // комментарий
            dispatch(addComment({ data }));
        });

        channel.bind("App\\Events\\NewStenaEvent", (data) => {
            // console.log("дата после отработки сокета", data);
            if (data?.stena?.transaction?.shops_id && Shop.length) {
                const shop = Shop.find(
                    (item) => item.id === data.stena.transaction.shops_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, shop },
                    },
                };
                dispatch(addStena({ data: newData }));
            } else if (data?.stena?.transaction?.badges_id && Badge.length) {
                const badge = Badge.find(
                    (item) => item.id === data.stena.transaction.badges_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, badge },
                    },
                };
                dispatch(addStena({ data: newData }));
            } else if (data?.stena?.transaction?.medal_id && Medal.length) {
                const medal = Medal.find(
                    (item) => item.id === data.stena.transaction.medal_id
                );
                const newData = {
                    ...data,
                    stena: {
                        ...data.stena,
                        transaction: { ...data.stena.transaction, medal },
                    },
                };
                dispatch(addStena({ data: newData }));
            } else {
                dispatch(addStena({ data }));
            }
        });

        channel.bind("App\\Events\\CurrencyUpdateEvent", (data) => {
            CurrencyUpdateHandler({ data }); // валюта
            console.log(data);
        });

        channel.bind("App\\Events\\DailyStatsUpdateEvent", (data) => {
            DynamicStatsHandler({ data }); // статистика за сегодня
        });

        channel.bind("App\\Events\\MenuUpdateEvent", (data) => {
            MenuBadgeHandler({ data }); // банжи меню
        });
        channel.bind("App\\Events\\NotificationsListUpdate", (data) => {
            NotificationsListUpdate({ data }); // лента уведомлений
        });

        // // Установка интервала на отправку запроса каждую минуту
        // const interval = setInterval(() => {
        //     fetch('/beforeunload')
        //         .then(response => {
        //             if (!response.ok) {
        //                 throw new Error('Network response was not ok');
        //             }
        //             // Обработайте ответ, если необходимо
        //         })
        //         .catch(error => {
        //             console.error('There was a problem with the fetch operation:', error);
        //         });
        // }, 600000); // (10 минута)

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [Shop]);

    return (
        <div>
            <UserActivityTracker />{" "}
            {/* Добавьте компонент UserActivityTracker здесь */}
            <ToastContainer />
            {/* Остальные компоненты вашего приложения */}
        </div>
    );
}

export default WebSocketComponent;
