import React, { useState } from 'react';

const EventCreationPanel = ({ onEventCreate }) => {
  const [selectedRoom, setSelectedRoom] = useState('meetingRoom');
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [eventTitle, setEventTitle] = useState('');
  const [eventContent, setEventContent] = useState('');
  const [multipleEvents, setMultipleEvents] = useState(false);

  const handleCreateEvent = () => {
    if (selectedStartTime && selectedEndTime) {
      const newEvent = {
        title: eventTitle || (selectedRoom === 'meetingRoom' ? 'Забронировано' : selectedRoom),
        start: selectedStartTime,
        end: selectedEndTime,
        content: eventContent,
      };

      if (multipleEvents) {
        // Создаем несколько событий
        // Можно добавить логику для создания нескольких событий
      } else {
        onEventCreate(newEvent);
      }

      // Сбрасываем поля
      setSelectedRoom('meetingRoom');
      setSelectedStartTime(null);
      setSelectedEndTime(null);
      setEventTitle('');
      setEventContent('');
      setMultipleEvents(false);
    } else {
      alert('Пожалуйста, заполните все поля и выберите время.');
    }
  };

  return (
    <div className="col-3">
      <h3>Создать событие</h3>
      <div className="mb-3">
        <select className="form-select" onChange={(e) => setSelectedRoom(e.target.value)}>
          <option value="meetingRoom">Переговорная</option>
          <option value="vacation">Отпуск</option>
          <option value="sickLeave">Больничный</option>
        </select>
        <label className="form-label mt-3">Выберите начальное время:</label>
        <input type="datetime-local" className="form-control" onChange={(e) => setSelectedStartTime(new Date(e.target.value))} />
        <label className="form-label mt-3">Выберите конечное время:</label>
        <input type="datetime-local" className="form-control" onChange={(e) => setSelectedEndTime(new Date(e.target.value))} />
        <label className="form-label mt-3">Заголовок:</label>
        <input type="text" className="form-control" value={eventTitle} onChange={(e) => setEventTitle(e.target.value)} />
        <label className="form-label mt-3">Содержание:</label>
        <textarea className="form-control" value={eventContent} onChange={(e) => setEventContent(e.target.value)} />
        <div className="form-check mt-3">
          <input type="checkbox" className="form-check-input" id="multipleEvents" checked={multipleEvents} onChange={() => setMultipleEvents(!multipleEvents)} />
          <label className="form-check-label" htmlFor="multipleEvents">Создать несколько событий</label>
        </div>
        <button className="btn btn-primary mt-3" onClick={handleCreateEvent}>Создать</button>
      </div>
    </div>
  );
};

export default EventCreationPanel;
