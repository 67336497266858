import { useState } from "react";

const CloseBtnSVG = ({
    handleClick,
    absolute = true,
    backgroundStatic = "bg-secondary ",
    backgroundHover = "bg-gray-3 ",
    strokeStatic = "white",
    strokeHover = "black",
}) => {
    const [background, setBackground] = useState(backgroundStatic);
    const [stroke, setStroke] = useState(strokeStatic);
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#202227"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
            className={
                (background || "") +
                "top-0 end-0 m-2" +
                (absolute ? " position-absolute" : "")
            }
            onClick={handleClick}
            onMouseEnter={() => {
                setBackground(backgroundHover);
                setStroke(strokeHover);
            }}
            onMouseLeave={() => {
                setBackground(backgroundStatic);
                setStroke(strokeStatic);
            }}
        >
            <path
                d="M4.66657 4.66652L8 7.99996M11.3334 11.3334L8 7.99996M8 7.99996L11.3334 4.66652M8 7.99996L4.66657 11.3334"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloseBtnSVG;
