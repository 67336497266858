const SendMessageSVG = ({ handleClick, disabled, value, gray, user }) => {
    return (
        <button
            disabled={disabled}
            className="btn p-0 border-0 inside"
            onClick={() => handleClick(value, user)}
        >
            <svg
                className="inside"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="24"
                    height="24"
                    rx="4"
                    fill={gray ? "#BFBFBF" : "#BF1B2C"}
                    className="inside"
                />

                <path
                    d="M18.6667 12L6 17.3334L8.375 12L6 6.66669L18.6667 12ZM18.6667 12L8.33333 12"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="inside"
                />
            </svg>
        </button>
    );
};

export default SendMessageSVG;
