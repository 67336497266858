import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const UserBlocks = () => {
    const [users, setUsers] = useState([]);
    const [iusers, setIusers] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [activeCardIds, setActiveCardIds] = useState(new Set());
    const [guessedCount, setGuessedCount] = useState(0);
    const [hints, setHints] = useState({});
    const [gameFinished, setGameFinished] = useState(false); // Добавляем состояние для отслеживания завершения игры
    const [correctGuesses, setCorrectGuesses] = useState(0); // Добавляем состояние для правильных угадываний
    const [readyToPlay, setReadyToPlay] = useState(false); // Добавляем состояние готовности игрока

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (readyToPlay) { // Проверяем, готов ли игрок играть

                    const { data } = await axios('/users/userListHomeJson');
                    const filteredUsers = data.filter(user => user.photo !== null);
                    // Перемешиваем массив
                    const shuffledUsers = filteredUsers.sort(() => 0.5 - Math.random());
                    // Выбираем первые 9 пользователей
                    const randomUsers = shuffledUsers.slice(0, 9);
                    setUsers(randomUsers);
                    setIusers(filteredUsers);
                }
            } catch (error) {
                console.error("Ошибка при получении данных о пользователях:", error);
            }
        };

        fetchData();
    }, [readyToPlay]);



    const handleCardClick = (id) => {
        if (activeCardIds.size < 3 && !activeCardIds.has(id)) {
            const allSelected = Array.from(activeCardIds).every(activeId => inputValues[activeId] && inputValues[activeId].trim() !== '');
            if (!allSelected) {
                toast.error('Сначала завершите выбор для открытой карточки.');
                return;
            }
            setActiveCardIds(new Set(activeCardIds).add(id));
        }
    };


    const handleInputChange = (id, value) => {
        setInputValues({ ...inputValues, [id]: value });
    };

    const selectUser = (selectedUser, userId) => {
        setInputValues({ ...inputValues, [userId]: selectedUser.name });
        setGuessedCount(guessedCount + 1);
    };

    const handleSubmit = () => {
        if (guessedCount < 3) {
            toast.error('Пожалуйста, угадайте минимум трех сотрудников.');
            return;
        }

        let correctGuesses = 0; // Переменная объявлена в начале функции handleSubmit

        Object.keys(inputValues).forEach(userId => {
            const userGuess = inputValues[userId].toLowerCase().trim();
            const user = users.find(u => u.id === Number(userId)); // Если id в users числовой
            if (user && user.name.toLowerCase().trim() === userGuess) {
                correctGuesses += 1;
                setCorrectGuesses(correctGuesses);
            }
        });

        const token = document.head.querySelector(
            'meta[name="csrf-token"]'
        ).content;

        const sendTokenToServer = async () => {
            try {
                await axios.post('/user/shop/game/go', {
                    token: token,
                    correctGuesses: correctGuesses
                });
                setGameFinished(true); // Устанавливаем состояние завершения игры в true

                toast.success(`Вы угадали ${correctGuesses} из 3. Ваш выигрыш ${correctGuesses} реддиков`);
            } catch (error) {
                toast.error('Ошибка:', error);
            }
        };
        sendTokenToServer();
    };

    const buyHint = async (userId) => {
        const isConfirmed = window.confirm('Купить подсказку (Первая буква) за 1 Мяукоин?');
        if (!isConfirmed) {
            return; // Пользователь отменил покупку
        }

        try {
            // Отправляем запрос на сервер для покупки подсказки для конкретного пользователя
            const response = await axios.get('/user/shop/game/buy-hint', {});

            // Обработка ответа от сервера
            if (response.status === 200) {
                // Если покупка успешна, обновляем подсказку в состоянии
                const user = users.find(user => user.id === userId);
                if (user) {
                    const firstLetter = user.name.trim().charAt(0); // Получаем первую букву имени
                    setHints(prevHints => ({
                        ...prevHints,
                        [userId]: firstLetter
                    }));
                }
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Ошибка при покупке подсказки:', error);
            toast.error('Произошла ошибка при покупке подсказки');
        }
    };

    const handleStartGame = async () => {

        try {
            const response = await axios.get('/user/shop/game/nextgame', {});
            if (response.status === 200) {
                const user = users.find(user => user.id === userId);
                if (user) {
                    const firstLetter = user.name.trim().charAt(0);
                    setHints(prevHints => ({
                        ...prevHints,
                        [userId]: firstLetter
                    }));
                }
                toast.success(response.data.message);
                setReadyToPlay(true);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Ошибка при покупке:', error);
            toast.error('Произошла ошибка при покупке');
        }
    };


    return (
        <div >
            {!readyToPlay && (
                <div className="container mt-5 alert alert-info" role="alert">
                    <div className="m-2">
                        Стоимость игры стоит 1 <img className="meow-coin" src="/img/meow.svg" />.
                    </div>
                    Вы готовы к игре? <button className="btn btn-info text-white" onClick={handleStartGame}>Купить за 1 мяукоин попытку</button>
                </div>

            )}

{gameFinished ? (
    <div className="container mt-5 alert alert-info" role="alert">
        {correctGuesses <= 0 ? (
            <div>
                Игра завершена. Вы проиграли  <img className="img-2" src="/img/apple/64/1fae3.png" />
            </div>
        ) : (
            <div>
                Игра завершена победой! Вы получили {correctGuesses} <img className="img-2" src="/img/redik.svg" />
            </div>
        )}
    </div>
) : (
                <div className="container mt-5">
                    <div className="row">
                        {users.map((user) => (
                            <div key={user.id} className="col-4 mb-4 ">
                                <div className="card" onClick={() => handleCardClick(user.id)}>
                                    {activeCardIds.has(user.id) ? (
                                        <>
                                            <img
                                                src={user.photo}
                                                alt="User"
                                                className="object-fit-cover"
                                                style={{ width: '418px', height: '418px', objectFit: 'cover' }}
                                            />

                                            <div className="card-body">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Введите ФИО"
                                                    value={inputValues[user.id] || ''}
                                                    onChange={(e) => handleInputChange(user.id, e.target.value)}
                                                />

                                                {hints[user.id] && (
                                                    <div className="hint">Первая буква: {hints[user.id]}</div>
                                                )}
                                                {!hints[user.id] && guessedCount < 3 && (
                                                    <button className="btn btn-info mt-2" onClick={() => buyHint(user.id)}>
                                                        Купить подсказку (Первая буква) за 1 Мяукоин <img class="meow-coin" src="/img/meow.svg"></img>
                                                    </button>
                                                )}
                                                <ul className="list-group">
                                                    {iusers.filter(u => u.name.toLowerCase().includes(inputValues[user.id]?.toLowerCase() || '')).map(filteredUser => (
                                                        <li key={filteredUser.id} className="list-group-item list-group-item-action" onClick={() => selectUser(filteredUser, user.id)}>
                                                            {filteredUser.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="card-body d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                            <span>Открыть карточку</span>
                                            <svg
                                                version="1.1"
                                                id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512 512"
                                                xmlSpace="preserve"
                                                style={{ width: '80%', height: '80%' }}
                                            >
                                                <path style={{ fill: '#FFC850' }} d="M444.379,3.741c10.828-8.798,27.018-1.092,27.018,12.859v222.01l-182.26-107.699L444.379,3.741z" />
                                                <path style={{ fill: '#EBAF4B' }} d="M454.828,228.819l-110.973-65.574l92.462-104.241c6.465-7.288,18.511-2.716,18.511,7.027V228.819z" />
                                                <path style={{ fill: '#FFC850' }} d="M67.619,3.741C56.79-5.057,40.601,2.649,40.601,16.6v222.01l182.26-107.699L67.619,3.741z" />
                                                <path style={{ fill: '#EBAF4B' }} d="M57.17,228.819l110.973-65.574L75.681,59.004c-6.465-7.288-18.511-2.716-18.511,7.027 C57.17,66.031,57.17,228.819,57.17,228.819z" />
                                                <ellipse style={{ fill: '#FFDC64' }} cx="255.999" cy="292.46" rx="231.97" ry="219.54" />
                                                <path style={{ fill: '#FF8087' }} d="M289.137,429.155v16.569c0,18.302-14.836,33.138-33.138,33.138l0,0 c-18.302,0-33.138-14.836-33.138-33.138v-16.569l33.138-16.569L289.137,429.155z" />
                                                <path style={{ fill: '#5D5360' }} d="M274.293,343.862h-36.588c-7.899,0-12.273,9.157-7.307,15.3l18.295,22.634 c3.76,4.651,10.852,4.651,14.613,0l18.295-22.634C286.566,353.019,282.193,343.862,274.293,343.862z" />
                                                <g>
                                                    <path style={{ fill: '#E1A546' }} d="M479.673,437.439c-1.286,0-2.593-0.299-3.815-0.934c-50.092-26.047-128.491-41.524-129.28-41.678 c-4.49-0.874-7.419-5.226-6.545-9.717c0.878-4.494,5.186-7.427,9.717-6.545c3.301,0.643,81.515,16.076,133.754,43.239 c4.057,2.112,5.639,7.111,3.527,11.173C485.555,435.813,482.667,437.439,479.673,437.439z" />
                                                    <path style={{ fill: '#E1A546' }} d="M496.255,379.451c-0.712,0-1.436-0.093-2.156-0.287c-46.435-12.483-130.87-10.113-131.703-10.077 c-4.652,0.134-8.398-3.459-8.531-8.03c-0.138-4.575,3.459-8.394,8.03-8.531c3.56-0.113,87.736-2.476,136.509,10.635 c4.417,1.189,7.035,5.732,5.849,10.153C503.257,377.012,499.912,379.447,496.255,379.451z" />
                                                </g>
                                                <path style={{ fill: '#FFC850' }} d="M313.991,495.431c-128.112,0-231.967-98.291-231.967-219.54c0-89.035,56.034-165.634,136.518-200.081 C108.248,92.762,24.032,183.285,24.032,292.46C24.032,413.709,127.887,512,255.999,512c34.037,0,66.328-6.995,95.449-19.459 C339.25,494.416,326.748,495.431,313.991,495.431z" />
                                                <g>
                                                    <path style={{ fill: '#E1A546' }} d="M32.324,437.439c-2.993,0-5.882-1.622-7.358-4.462c-2.112-4.061-0.53-9.061,3.527-11.173 c52.24-27.163,130.453-42.596,133.754-43.239c4.494-0.902,8.839,2.055,9.717,6.545c0.874,4.49-2.055,8.843-6.545,9.717 c-0.789,0.154-79.189,15.631-129.28,41.678C34.917,437.14,33.611,437.439,32.324,437.439z" />
                                                    <path style={{ fill: '#E1A546' }} d="M15.743,379.451c-3.657,0-7.002-2.439-7.997-6.137c-1.185-4.421,1.432-8.964,5.849-10.153 c48.777-13.115,132.941-10.736,136.509-10.635c4.571,0.138,8.167,3.956,8.03,8.531c-0.138,4.571-4.098,8.196-8.531,8.03 c-0.849-0.028-85.297-2.407-131.703,10.077C17.179,379.358,16.455,379.451,15.743,379.451z" />
                                                </g>
                                                <path style={{ fill: '#4B3F4E' }} d="M160.727,321.456L160.727,321.456c-15.948,0-28.996-13.048-28.996-28.996v-16.569 c0-15.948,13.048-28.996,28.996-28.996l0,0c15.948,0,28.996,13.048,28.996,28.996v16.569 C189.723,308.407,176.675,321.456,160.727,321.456z" />
                                                <path style={{ fill: '#5D5360' }} d="M160.727,246.895c-1.418,0-2.778,0.221-4.142,0.421v41.002c0,9.151,7.418,16.569,16.569,16.569 s16.569-7.418,16.569-16.569v-12.427C189.723,259.943,176.674,246.895,160.727,246.895z" />
                                                <circle style={{ fill: '#FFFFFF' }} cx="160.729" cy="267.61" r="12.427" />
                                                <path style={{ fill: '#4B3F4E' }} d="M351.271,321.456L351.271,321.456c-15.948,0-28.996-13.048-28.996-28.996v-16.569 c0-15.948,13.048-28.996,28.996-28.996l0,0c15.948,0,28.996,13.048,28.996,28.996v16.569 C380.267,308.407,367.219,321.456,351.271,321.456z" />
                                                <path style={{ fill: '#5D5360' }} d="M351.271,246.895c-1.418,0-2.778,0.221-4.142,0.421v41.002c0,9.151,7.418,16.569,16.569,16.569 s16.569-7.418,16.569-16.569v-12.427C380.267,259.943,367.219,246.895,351.271,246.895z" />
                                                <circle style={{ fill: '#FFFFFF' }} cx="351.269" cy="267.61" r="12.427" />
                                                <path style={{ fill: '#4B3F4E' }} d="M262.408,382.15l-18.295-36.215c-0.332-0.658-0.518-1.378-0.769-2.074h-5.639 c-7.899,0-12.273,9.157-7.308,15.3l18.295,22.634c3.55,4.39,9.981,4.485,13.863,0.587 C262.511,382.297,262.453,382.239,262.408,382.15z" />
                                                <path style={{ fill: '#E6646E' }} d="M255.999,412.586l-33.138,16.569v16.569c0,2.629,0.383,5.154,0.961,7.606 c8.337-1.034,16.389-3.449,23.892-7.153v7.831c0,4.575,3.709,8.285,8.285,8.285c4.576,0,8.285-3.709,8.285-8.285v-7.83 c7.504,3.704,15.556,6.119,23.892,7.152c0.578-2.452,0.961-4.978,0.961-7.606v-16.569L255.999,412.586z" />
                                                <path style={{ fill: '#EBAF4B' }} d="M297.422,437.439c-11.719,0-23.013-3.483-32.653-10.073c-5.162-3.527-12.374-3.527-17.544,0 c-9.636,6.59-20.93,10.073-32.649,10.073c-14.259,0-27.993-5.275-38.672-14.85c-3.406-3.058-3.689-8.297-0.635-11.703 s8.281-3.689,11.703-0.635c13.911,12.483,35.683,13.847,50.905,3.434c10.841-7.403,25.408-7.403,36.241,0 c15.226,10.408,37.001,9.041,50.913-3.43c3.402-3.05,8.636-2.775,11.699,0.639c3.054,3.406,2.767,8.645-0.639,11.699 C325.41,432.168,311.681,437.439,297.422,437.439z" />
                                            </svg>

                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {guessedCount >= 3 && (
                        <button className="btn btn-primary" onClick={handleSubmit}>Отправить ответы</button>
                    )}
                </div>
            )}
        </div>



    );
};

export default UserBlocks;
