import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // Импортируйте FullCalendar
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";

import EventCreationPanel from "./EventCreationPanel"; // Импортируйте EventCreationPanel

const MeetingRoomBooking = () => {
    const [selectedRoom, setSelectedRoom] = useState("meetingRoom"); // Добавляем selectedRoom
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [events, setEvents] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleTimeSelection = (info) => {
        const { start, end } = info;

        if (
            selectedRoom === "meetingRoom" ||
            selectedRoom === "vacation" ||
            selectedRoom === "sickLeave"
        ) {
            if (isTimeAvailable(start, end)) {
                const newEvent = {
                    title:
                        selectedRoom === "meetingRoom"
                            ? "Забронировано"
                            : selectedRoom,
                    start,
                    end,
                };

                setEvents([...events, newEvent]);
            } else {
                alert(
                    "Выбранное время недоступно. Пожалуйста, выберите другое время."
                );
            }
        } else {
            alert("Выберите корректный тип бронирования.");
        }
    };

    const isTimeAvailable = (start, end) => {
        return true; // Заглушка, замените логикой
    };

    // Функция для создания события и добавления его в календарь
    const handleEventCreate = (newEvent) => {
        setEvents([...events, newEvent]);
    };

    // Функция для редактирования события
    const handleEventEdit = (updatedEvent) => {
        // Добавьте логику для редактирования события
    };

    // Функция для удаления события
    const handleEventDelete = () => {
        // Добавьте логику для удаления события
    };

    const handleEventClick = (clickInfo) => {
        // Откройте модальное окно или выполняйте другие действия при клике на событие
        // Можете использовать clickInfo.event для доступа к информации о событии
        setSelectedEvent(clickInfo.event);
        setShowInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Календарь</h1>
            <div className="row">
                <EventCreationPanel onEventCreate={handleEventCreate} />{" "}
                {/* Передаем функцию для создания события */}
                <div className="col-9">
                    <div>
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            header={{
                                left: "prev,next today",
                                center: "title",
                                right: "customMonth,dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            editable={true}
                            selectable={true}
                            select={handleTimeSelection}
                            events={events}
                            eventClick={handleEventClick}
                            locale="ru"
                            locales={ruLocale}
                            views={{
                                month: {
                                    titleFormat: {
                                        month: "long",
                                        year: "numeric",
                                    },
                                },
                                week: {
                                    titleFormat: {
                                        month: "long",
                                        year: "numeric",
                                        day: "numeric",
                                    },
                                },
                                day: {
                                    titleFormat: {
                                        month: "long",
                                        year: "numeric",
                                        day: "numeric",
                                    },
                                },
                            }}
                            customButtons={{
                                customMonth: {
                                    text: "Месяц",
                                    click: () => {
                                        // Переключение на вид месяца
                                        calendarRef.current
                                            .getApi()
                                            .changeView("dayGridMonth");
                                    },
                                },
                                todayButton: {
                                    text: "Сегодня",
                                    click: () => {
                                        // Переключение на текущую дату
                                        calendarRef.current.getApi().today();
                                    },
                                },
                            }}
                        />
                    </div>
                    {selectedEvent && (
                        <div
                            className="modal"
                            tabIndex="-1"
                            role="dialog"
                            style={{
                                display: showInfoModal ? "block" : "none",
                            }}
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            Информация о событии
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={handleCloseInfoModal}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Название: {selectedEvent.title}</p>
                                        {/* Здесь вы можете добавить другие поля для информации о событии */}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleCloseInfoModal}
                                        >
                                            Закрыть
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={handleEventDelete}
                                        >
                                            Удалить
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleEventEdit}
                                        >
                                            Редактировать
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MeetingRoomBooking;
