import React, { useState, useRef, useEffect } from "react";
import ArrowLeftSVG from "./Icons/ArrowLeftSVG";
import ArrowRightSVG from "./Icons/ArrowRightSVG";

const ReusableModal = ({
    title,
    content,
    show,
    onClose = () => {},
    firstBtn,
    secondBtn,
    footer = true,
    header = true,
    large = false,
    images = [],
}) => {
    const [isModalOpen, setIsModalOpen] = useState(show);
    const [currentIndex, setCurrentIndex] = useState(0);
    const modalBackdropRef = useRef(null);

    // console.log("content", content);

    useEffect(() => {
        if (isModalOpen) {
            modalBackdropRef.current.focus();
        }
    }, [isModalOpen]);

    useEffect(() => {
        setIsModalOpen(show);
    }, [show]);

    const closeModal = () => {
        setIsModalOpen(false);
        onClose();
    };

    const handleBackdropClick = (e) => {
        if (modalBackdropRef.current === e.target) {
            closeModal();
        }
    };

    const goToPrevImage = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const goToNextImage = () => {
        setCurrentIndex((prevIndex) =>
            Math.min(prevIndex + 1, images.length - 1)
        );
    };

    return (
        <>
            {isModalOpen && (
                <div
                    className="modal fade show d-block inside"
                    tabIndex="-1"
                    role="dialog"
                    onClick={handleBackdropClick}
                    ref={modalBackdropRef}
                    onKeyDown={(key) => {
                        if (images.length) {
                            if (key.key === "ArrowLeft") {
                                goToPrevImage();
                            } else if (key.key === "ArrowRight") {
                                goToNextImage();
                            }
                        }
                        if (key.key === "Escape") {
                            closeModal();
                        }
                    }}
                >
                    <div
                        className={
                            "modal-dialog modal-dialog-scrollable " +
                            (large ? "modal-lg" : "")
                        }
                        role="document"
                        style={
                            content.type === "img"
                                ? { maxWidth: "fit-content" }
                                : {}
                        }
                    >
                        <div
                            className="modal-content"
                            style={
                                content.type === "img"
                                    ? { maxWidth: "fit-content" }
                                    : {}
                            }
                        >
                            {header && (
                                <div className="modal-header p-4 pb-2">
                                    <h5 className="modal-title">{title}</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        aria-label="Close"
                                        onClick={closeModal}
                                    ></button>
                                </div>
                            )}

                            <div
                                className={
                                    "modal-body overflow-x-hidden overflow-auto " +
                                    (content.type === "img"
                                        ? "p-0"
                                        : "p-4 pt-2")
                                }
                            >
                                <div
                                    className="position-relative lh-sm"
                                    // data-bs-theme="dark"
                                >
                                    {!header && (
                                        <button
                                            type="button"
                                            className={
                                                "btn-close position-absolute top-0 " +
                                                (content.type === "img"
                                                    ? "start-100  translate-middle"
                                                    : "end-0")
                                            }
                                            aria-label="Close"
                                            onClick={closeModal}
                                        ></button>
                                    )}
                                    {images.length ? (
                                        <img
                                            src={
                                                images[currentIndex].dataset
                                                    .srcset
                                            }
                                            alt={images[currentIndex].alt}
                                            className="w-100"
                                            style={{
                                                objectFit: "scale-down",
                                                objectPosition: "center",
                                                maxHeight:
                                                    "calc(100vh - 200px)",
                                            }}
                                        />
                                    ) : (
                                        content
                                    )}
                                </div>
                            </div>
                            {footer && (
                                <div className="modal-footer d-flex gap-1 align-items-center">
                                    {firstBtn && (
                                        <button
                                            type="button"
                                            className={firstBtn.class}
                                            onClick={firstBtn.handleClick}
                                            disabled={firstBtn.disabled}
                                        >
                                            {firstBtn.title}
                                        </button>
                                    )}
                                    {secondBtn && (
                                        <button
                                            type="button"
                                            className={secondBtn.class}
                                            onClick={secondBtn.handleClick}
                                            disabled={secondBtn.disabled}
                                        >
                                            {secondBtn.title}
                                        </button>
                                    )}
                                </div>
                            )}
                            {/* Добавляем стрелки для пролистывания галереи */}
                            {images.length > 0 && (
                                <>
                                    <button
                                        type="button"
                                        className="p-0 btn btn-link position-absolute top-50 start-0 translate-middle-y"
                                        onClick={goToPrevImage}
                                        // style={{ left: "10px" }}
                                        disabled={currentIndex === 0}
                                    >
                                        <ArrowLeftSVG />
                                    </button>
                                    <button
                                        type="button"
                                        className="p-0 btn btn-link position-absolute top-50 end-0 translate-middle-y"
                                        onClick={goToNextImage}
                                        // style={{ right: "10px" }}
                                        disabled={
                                            currentIndex === images.length - 1
                                        }
                                    >
                                        <ArrowRightSVG />
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default ReusableModal;
