import { useState } from "react";

const SmileSVG = ({ handleClick }) => {
    const [stroke, setStroke] = useState("#BFBFBF");

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
            role="button"
            onMouseEnter={() => setStroke("#8C8C8C")}
            onMouseLeave={() => setStroke("#BFBFBF")}
        >
            <g clipPath="url(#clip0_2177_270)">
                <path
                    d="M10.0002 18.3327C5.39779 18.3327 1.66683 14.6017 1.66683 9.99935C1.66683 5.39698 5.39779 1.66602 10.0002 1.66602C14.6025 1.66602 18.3335 5.39698 18.3335 9.99935C18.3335 14.6017 14.6025 18.3327 10.0002 18.3327Z"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.75 12.084C13.75 12.084 12.5 13.7507 10 13.7507C7.5 13.7507 6.25 12.084 6.25 12.084"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.9168 7.49935C12.6867 7.49935 12.5002 7.3128 12.5002 7.08268C12.5002 6.85256 12.6867 6.66602 12.9168 6.66602C13.1469 6.66602 13.3335 6.85256 13.3335 7.08268C13.3335 7.3128 13.1469 7.49935 12.9168 7.49935Z"
                    fill="#BFBFBF"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.08333 7.49935C6.85321 7.49935 6.66667 7.3128 6.66667 7.08268C6.66667 6.85256 6.85321 6.66602 7.08333 6.66602C7.31345 6.66602 7.5 6.85256 7.5 7.08268C7.5 7.3128 7.31345 7.49935 7.08333 7.49935Z"
                    fill="#BFBFBF"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2177_270">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SmileSVG;
