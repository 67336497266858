import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTransition, animated } from 'react-spring';

const Leaderboard = () => {
  const [leadersData, setLeadersData] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [category, setCategory] = useState('totalRating');
  const [sortBy, setSortBy] = useState('totalRating');
  const [showTopTen, setShowTopTen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedDepartment, setSelectedDepartment] = useState('all'); // Фильтр по отделам
  const [departments, setDepartments] = useState([]); // Список отделов

  useEffect(() => {
    axios.get('/users/userHomeRaintig')
      .then(response => {
        const leadersArray = Object.values(response.data);
        setLeadersData(leadersArray);

        // Получите список уникальных отделов из данных пользователей
        const uniqueDepartments = [...new Set(leadersArray.map(leader => leader.department))];

        // Добавьте "Все отделы" как первый элемент в список отделов
        setSelectedDepartment('all');
        setDepartments(['all', ...uniqueDepartments]);
      })
      .catch(error => console.error(error));

    axios.get('/users/userHomeRaintigUser')
      .then(response => {
        setCurrentUserData(response.data[0]);
      })
      .catch(error => console.error(error));
  }, []);

  const filteredLeaders = leadersData
    .filter(leader => (selectedDepartment === 'all' || leader.department === selectedDepartment) && (category === 'totalRating' || leader[category] > 0))
    .sort((a, b) => b[sortBy] - a[sortBy]);

  const totalPages = Math.ceil(filteredLeaders.length / perPage);
  const paginatedLeaders = filteredLeaders.slice((currentPage - 1) * perPage, currentPage * perPage);

  const transitions = useTransition(showTopTen ? paginatedLeaders : filteredLeaders, {
    keys: leader => leader.id,
    from: { opacity: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.9)' },
  });

  const renderAvatar = (leader) => {
    if (leader.photo) {
      return <img src={`/${leader.photo}`} alt={leader.name} className="rounded-circle w-48 h-48 object-fit-cover" />;
    }
    return (
      <div className="rounded-circle w-48 h-48 bg-primary text-white d-flex align-items-center justify-content-center">
        {leader.name.split(' ').map(n => n[0]).join('')}
      </div>
    );
  };

  const renderLeaderboardEntry = (leader, rank) => (
    <div className="d-flex align-items-center mb-3">
      <div className="mr-3">{rank}</div>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {renderAvatar(leader)}
            <div className="ml-3">{leader.name}</div>
          </div>
          <div>{leader.totalRating}</div>
        </div>
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{ width: `${(leader.totalRating / filteredLeaders[0].totalRating) * 100}%` }}>
            {leader.totalRating}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container my-5">
      <h1 className="text-center">Лидеры</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={`nav-link ${showTopTen ? 'active' : ''}`} onClick={() => setShowTopTen(true)}>Топ-10</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${!showTopTen ? 'active' : ''}`} onClick={() => setShowTopTen(false)}>Все лидеры</a>
        </li>
        <li className="nav-item">
          <select className="form-control" onChange={(e) => setSelectedDepartment(e.target.value)}>
            {departments.map((department, index) => (
              <option key={index} value={department}>{department}</option>
            ))}
          </select>
        </li>
        <li className="nav-item">
          <select className="form-control" onChange={(e) => setSortBy(e.target.value)}>
            <option value="totalRating">Общий рейтинг</option>
            <option value="medalsCount">Медали</option>
            <option value="gratitudesCount">Благодарности</option>
            <option value="badgesCount">Бейджи</option>
            <option value="likesCount">Лайки</option>
            <option value="shopsCount">Магазины</option>
          </select>
        </li>
      </ul>
      <div>
        {showTopTen ? (
          transitions((style, leader, _, index) => (
            <animated.div style={style} key={leader.id}>
              {renderLeaderboardEntry(leader, index + 1)}
            </animated.div>
          ))
        ) : (
          <div>
            <div className="row">
              {transitions((style, leader) => (
                <div className="col-md-4 mb-3" key={leader.id}>
                  <animated.div className="card" style={style}>
                    <div className="card-header">
                      {leader.photo ? (
                        <img src={`/${leader.photo}`} alt={leader.name} className="rounded-circle w-48 h-48 object-fit-cover" />
                      ) : (
                        <div className="rounded-circle w-48 h-48 bg-primary text-white d-flex align-items-center justify-content-center">
                          {leader.name.split(' ').map(n => n[0]).join('')}
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{leader.name}</h5>
                      <p className="card-text">{leader.department || 'Отдел не указан'}</p>
                      <div>Медали: {leader.medalsCount}</div>
                      <div>Благодарности: {leader.gratitudesCount}</div>
                      <div>Бейджи: {leader.badgesCount}</div>
                      <div>Лайки: {leader.likesCount}</div>
                      <div>Магазины: {leader.shopsCount}</div>
                      <div>Общий рейтинг: {leader.totalRating}</div>
                    </div>
                  </animated.div>
                </div>
              ))}
            </div>
            <div className="pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Предыдущая
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => setCurrentPage(i + 1)}
                  className={i + 1 === currentPage ? 'active' : ''}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Следующая
              </button>
            </div>
            <div className="page-info">
              Страница {currentPage} из {totalPages}
            </div>
          </div>
        )}
      </div>
      {currentUserData && (
        <div className="mt-4">
          <h4>Ваши данные:</h4>
          <div className="d-flex align-items-center">
            {currentUserData.photo ? (
              <img src={`/${currentUserData.photo}`} alt={currentUserData.name} className="rounded-circle w-48 h-48 object-fit-cover" />
            ) : (
              <div className="rounded-circle w-48 h-48 bg-primary text-white d-flex align-items-center justify-content-center">
                {currentUserData.name.split(' ').map(n => n[0]).join('')}
              </div>
            )}
            <div className="ml-3">
              <h5>{currentUserData.name}</h5>
              <p>{currentUserData.department || 'Отдел не указан'}</p>
              <div>Медали: {currentUserData.medalsCount}</div>
              <div>Благодарности: {currentUserData.gratitudesCount}</div>
              <div>Бейджи: {currentUserData.badgesCount}</div>
              <div>Лайки: {currentUserData.likesCount}</div>
              <div>Магазины: {currentUserData.shopsCount}</div>
              <div>Общий рейтинг: {currentUserData.totalRating}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
