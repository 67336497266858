import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import EmojiPicker, { SuggestionMode } from "emoji-picker-react";

const SmilesPopover = ({ onEmojiClick, target, handlePopoverMouseLeave }) => {
    const [popperRef, setPopperRef] = useState(null);
    const [showPopover, setShowPopover] = useState(false);

    const targetRef = useRef(target.current);
    const { styles, attributes } = usePopper(targetRef.current, popperRef, {
        placement: "bottom",
        modifiers:
            window.innerWidth > 768
                ? [{ name: "offset", options: { offset: [-70, 20] } }]
                : [{ name: "offset", options: { offset: [-150, 20] } }],
    });

    const categories = [
        {
            category: "suggested",
            name: "Популярные",
        },
        {
            category: "smileys_people",
            name: "Смайлы и люди",
        },
        {
            category: "animals_nature",
            name: "Природа",
        },
        {
            category: "food_drink",
            name: "Еда и напитки",
        },
        {
            category: "travel_places",
            name: "Путешествия и места",
        },
        {
            category: "activities",
            name: "Занятия",
        },
        {
            category: "objects",
            name: "Объекты",
        },
        {
            category: "symbols",
            name: "Символы",
        },
        {
            category: "flags",
            name: "Флаги",
        },
    ];

    const getEmojiUrl = (unified, emojiStyle) => {
        return `/img/apple/64/${unified}.png`;
    };

    return (
        <>
            <div
                className={`bs-popover-auto popover border-0 ${
                    showPopover ? "show" : ""
                }`}
                style={styles.popper}
                {...attributes.popper}
                ref={setPopperRef}
                role="button"
                onMouseLeave={handlePopoverMouseLeave}
            >
                <EmojiPicker
                    onEmojiClick={onEmojiClick}
                    getEmojiUrl={getEmojiUrl}
                    lazyLoadEmojis={true}
                    previewConfig={{ showPreview: false }}
                    searchDisabled={true}
                    categories={categories}
                    suggestedEmojisMode={SuggestionMode.FREQUENT}
                    reactionsDefaultOpen={window.innerWidth < 768}
                />
            </div>
        </>
    );
};

export default SmilesPopover;
