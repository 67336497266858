import UserLogo from "../Stena/UserLogo";

const SuggestionsList = ({
    filteredUsers = [],
    currentUser,
    handleUserSelect,
}) => {
    return (
        <div className="suggestions-list dropdown-menu show border-0 position-relative sk-dropdown-skroll inside">
            {filteredUsers.length &&
                filteredUsers
                    .filter((u) => u.id !== currentUser?.id)
                    .map((user) => (
                        <div
                            key={user.id}
                            className="dropdown-item border-top py-2 d-flex align-items-center inside"
                            role="button"
                            onClick={() => handleUserSelect(user)}
                            data-bs-auto-close="outside"
                        >
                            <UserLogo user={user} size="small" />
                            <p className="ms-1 mb-0 inside">
                                {user.name}
                                <br />
                                <span className="small text-gray-6-bg inside">
                                    {user.user_details?.department?.name}
                                </span>
                            </p>
                        </div>
                    ))}
        </div>
    );
};

export default SuggestionsList;
