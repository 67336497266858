import { useState } from "react";
import { useSelector } from "react-redux";
import MeowSVG from "../../Icons/MeowSVG";

const SecondStage = ({
    item = {},
    selectedUser = {},
    inputRef,
    activeInputState,
    setActiveInputState,
    handleConfirm,
    onReturnBtnClick,
    presentCostOfGifts,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [quantity, setQuantity] = useState(1);
    const currentUser = useSelector((state) => state.users?.currentUser);

    function handleQuantityChange(direction) {
        if (
            direction === "up" &&
            (quantity + 1) * item.summ <
                currentUser?.meow * 1 - presentCostOfGifts
        ) {
            setQuantity((prevState) => prevState + 1);
        } else if (direction === "down" && quantity > 1) {
            setQuantity((prevState) => prevState - 1);
        }
    }

    return (
        <div>
            <div className="d-md-block d-none">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{ height: "402px" }}
                        />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <h6>{item.name}</h6>
                        <div
                            dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <div>
                            Получатель:{" "}
                            <span className="text-primary">
                                {selectedUser.name}
                            </span>
                        </div>
                        <div>
                            <textarea
                                placeholder="При необходимости введите комментарий для администратора..."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                className="form-control"
                                ref={activeInputState ? inputRef : null}
                                onClick={() => setActiveInputState(true)}
                            ></textarea>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex gap-1 align-items-center text-muted">
                                    <div>
                                        <span>Количество</span>
                                    </div>

                                    <div className="d-flex align-items-center gap-1">
                                        <button
                                            className="btn btn-sm border-0 p-0 m-0 text-muted"
                                            onClick={() =>
                                                handleQuantityChange("down")
                                            }
                                        >
                                            -
                                        </button>
                                        <span>{quantity}</span>
                                        <button
                                            className="btn btn-sm border-0 p-0 m-0 text-muted"
                                            onClick={() =>
                                                handleQuantityChange("up")
                                            }
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex gap-1 align-items-center">
                                    <span>Спишется</span>
                                    <span>{item.summ * quantity}</span>
                                    <MeowSVG />
                                </div>
                            </div>
                            <div className="d-flex">
                                <button
                                    className="btn btn-sm border-0"
                                    onClick={onReturnBtnClick}
                                >
                                    Назад
                                </button>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                        handleConfirm({
                                            ...item,
                                            quantity,
                                            totalSumm: item.summ * quantity,
                                            comment: inputValue,
                                        })
                                    }
                                >
                                    Подтвердить покупку
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" d-md-none d-block">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{ height: "402px" }}
                        />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <h6>{item.name}</h6>
                        <div
                            dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <div>
                            Получатель:{" "}
                            <span className="text-primary">
                                {selectedUser.name}
                            </span>
                        </div>
                        <div>
                            <textarea
                                placeholder="При необходимости введите комментарий для администратора..."
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                className="form-control"
                                ref={activeInputState ? inputRef : null}
                                onClick={() => setActiveInputState(true)}
                            ></textarea>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex gap-1 align-items-center text-muted">
                                <div>
                                    <span>Количество</span>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                    <button
                                        className="btn btn-sm border-0 p-0 m-0 text-muted"
                                        onClick={() =>
                                            handleQuantityChange("down")
                                        }
                                    >
                                        -
                                    </button>
                                    <span>{quantity}</span>
                                    <button
                                        className="btn btn-sm border-0 p-0 m-0 text-muted"
                                        onClick={() =>
                                            handleQuantityChange("up")
                                        }
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span>Спишется</span>
                                <span>{item.summ * quantity}</span>
                                <MeowSVG />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-sm border-0"
                            onClick={onReturnBtnClick}
                        >
                            Назад
                        </button>
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                                handleConfirm({
                                    ...item,
                                    quantity,
                                    totalSumm: item.summ * quantity,
                                    comment: inputValue,
                                })
                            }
                        >
                            Подтвердить покупку
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondStage;
