const BadgeSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6666 12.6668H3.33331C2.22874 12.6668 1.33331 11.7714 1.33331 10.6668V5.3335C1.33331 4.22893 2.22874 3.3335 3.33331 3.3335H12.6666C13.7712 3.3335 14.6666 4.22893 14.6666 5.3335V6.00016V10.6668C14.6666 11.7714 13.7712 12.6668 12.6666 12.6668Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.33331 11C5.78103 11 5.33331 10.5523 5.33331 10V6C5.33331 5.44772 5.78103 5 6.33331 5H8.14575C8.866 5 9.41477 5.14286 9.79205 5.42857C10.1693 5.70857 10.358 6.08571 10.358 6.56C10.358 6.88 10.2837 7.15429 10.135 7.38286C9.9864 7.60571 9.78633 7.78 9.53481 7.90571C9.28901 8.02571 9.02034 8.08571 8.72881 8.08571L8.88315 7.77714C9.22042 7.77714 9.52338 7.84 9.79205 7.96571C10.0607 8.08571 10.2722 8.26286 10.4266 8.49714C10.5866 8.73143 10.6666 9.02286 10.6666 9.37143C10.6666 9.88571 10.4694 10.2857 10.075 10.5714C9.68058 10.8571 9.09466 11 8.31724 11H6.33331ZM6.448 10.1257H8.24864C8.66593 10.1257 8.98605 10.0571 9.20898 9.92C9.43192 9.78286 9.54339 9.56286 9.54339 9.26C9.54339 8.96286 9.43192 8.74571 9.20898 8.60857C8.98605 8.46571 8.66593 8.39429 8.24864 8.39429H6.36225V7.52857H8.0257C8.41441 7.52857 8.71166 7.46 8.91745 7.32286C9.12895 7.18571 9.23471 6.98 9.23471 6.70571C9.23471 6.42571 9.12895 6.21714 8.91745 6.08C8.71166 5.94286 8.41441 5.87429 8.0257 5.87429H6.448V10.1257Z"
                fill="white"
            />
        </svg>
    );
};

export default BadgeSVG;
