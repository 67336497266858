const SearchSVG = ({ active }) => {
    return (
        <svg
            width="24.000000"
            height="24.000000"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clip3512_11482">
                    <rect
                        id="search"
                        width="24.000000"
                        height="24.000000"
                        fill="white"
                        fillOpacity="0"
                    />
                </clipPath>
            </defs>
            <g clipPath="url(#clip3512_11482)">
                <path
                    id="Vector"
                    d="M17 17L21 21"
                    stroke={active ? "#bf1b2c" : "#8C8C8C"}
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                />
                <path
                    id="Vector"
                    d="M3 11C3 6.58154 6.58179 3 11 3C15.4182 3 19 6.58154 19 11C19 13.2051 18.1077 15.2021 16.6643 16.6494C15.2161 18.1016 13.2129 19 11 19C6.58179 19 3 15.4185 3 11Z"
                    stroke={active ? "#bf1b2c" : "#8C8C8C"}
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default SearchSVG;
