import React, { useState, useEffect, useRef } from "react";

const DigestList = () => {
    const [digests, setDigests] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDigest, setSelectedDigest] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [authorFilter, setAuthorFilter] = useState("Все авторы");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [page, setPage] = useState(1);
    const pageSize = 10; // Количество дайджестов на странице
    const listRef = useRef(null);
    const shadowDomRef = useRef(null);

    useEffect(() => {
        if (selectedDigest && modalShow && shadowDomRef.current) {
            const shadowRoot =
                shadowDomRef.current.shadowRoot ||
                shadowDomRef.current.attachShadow({ mode: "open" });
            shadowRoot.innerHTML = selectedDigest.body;
        }
    }, [selectedDigest, modalShow]);

    // Загрузка данных дайджеста
    useEffect(() => {
        const fetchDigests = async () => {
            setIsLoading(true);
            try {
                const response = await fetch("/digests/json"); // Путь к вашему API
                if (response.ok) {
                    const data = await response.json();
                    setDigests(data);
                } else {
                    throw new Error("Network response was not ok");
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
            setIsLoading(false);
        };

        fetchDigests();
    }, []);

    // Функция для фильтрации дайджестов

    const getFilteredDigests = () => {
        return digests
            .filter((digest) => {
                // Фильтрация по автору
                return (
                    authorFilter === "Все авторы" ||
                    digest.author?.name === authorFilter
                );
            })
            .filter((digest) => {
                // Фильтрация по дате
                const createdDate = new Date(digest.created_at);
                return (
                    (!startDate || createdDate >= new Date(startDate)) &&
                    (!endDate || createdDate <= new Date(endDate))
                );
            })
            .filter((digest) => {
                // Фильтрация по строке поиска
                const searchTermLower = searchTerm.toLowerCase();
                const titleMatch = digest.title
                    .toLowerCase()
                    .includes(searchTermLower);
                const bodyMatch = digest.body
                    .toLowerCase()
                    .includes(searchTermLower);
                return titleMatch || bodyMatch;
            })
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Сортировка по дате
    };

    const filteredDigests = getFilteredDigests();
    const totalPages = Math.ceil(filteredDigests.length / pageSize);
    const paginatedDigests = filteredDigests.slice(
        (page - 1) * pageSize,
        page * pageSize
    );

    const handleDigestClick = (digest) => {
        setSelectedDigest(digest);
        setModalShow(true);

        // Ждем, пока компонент обновится и отобразит модальное окно
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const resetFilters = () => {
        setSearchTerm("");
        setAuthorFilter("Все авторы");
        setStartDate("");
        setEndDate("");
        setPage(1); // Вернуться на первую страницу после сброса
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Поиск по дайджестам..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    {/* <select 
  className="form-select mb-2"
  value={authorFilter}
  onChange={(e) => setAuthorFilter(e.target.value)}
>
  <option value="Все авторы">Все авторы</option>
 
  {authors.map((author, index) => (
    <option key={index} value={author}>
      {author}
    </option>
  ))}
</select> */}

                    {/* Фильтры по дате */}
                    <div className="mb-2">
                        <input
                            type="date"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="mb-2">
                        <input
                            type="date"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>

                    {/* Кнопка для сброса фильтров */}
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => {
                            setSearchTerm("");
                            setAuthorFilter("Все авторы");
                            setStartDate("");
                            setEndDate("");
                        }}
                    >
                        Сбросить фильтры
                    </button>
                </div>
                <div className="col-sm-9" ref={listRef}>
                    {isLoading ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                    Загрузка...
                                </span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="list-group">
                                {paginatedDigests.map((digest) => (
                                    <button
                                        key={digest.id}
                                        className="list-group-item list-group-item-action"
                                        onClick={() =>
                                            handleDigestClick(digest)
                                        }
                                    >
                                        <h5 className="mb-1">
                                            {digest.title}{" "}
                                        </h5>
                                        <small>
                                            Автор:{" "}
                                            {digest.author?.name ||
                                                "Неизвестен"}
                                        </small>
                                        <p className="mb-1">
                                            {digest.formattedDateTime}
                                        </p>
                                    </button>
                                ))}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    {Array.from(
                                        { length: totalPages },
                                        (_, i) => (
                                            <li
                                                key={i}
                                                className={`page-item ${
                                                    page === i + 1
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <button
                                                    className="page-link"
                                                    onClick={() =>
                                                        handlePageChange(i + 1)
                                                    }
                                                >
                                                    {i + 1}
                                                </button>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </nav>
                        </>
                    )}
                </div>
            </div>

            {selectedDigest && (
                <div
                    className={`modal fade ${modalShow ? "show" : ""}`}
                    style={{ display: modalShow ? "block" : "none" }}
                    tabIndex="-1"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {selectedDigest.title}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseModal}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div ref={shadowDomRef}></div>{" "}
                                {/* Элемент для Shadow DOM */}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleCloseModal}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DigestList;
