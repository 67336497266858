const Stepper = ({ steps = [] }) => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center w-100 pe-4 d-none d-md-flex">
                {steps.map((step, index) => (
                    <div
                        key={step.id}
                        className="step-item d-flex flex-column justify-content-center align-items-center flex-grow-1"
                    >
                        <div
                            className={
                                "step-text " +
                                (step.completed
                                    ? "text-secondary"
                                    : "text-gray-4")
                            }
                        >
                            {step.title}
                        </div>
                        <div className="d-flex align-items-center w-100">
                            <div
                                className="progress w-100"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ height: "4px" }}
                            >
                                <div
                                    className={
                                        "progress-bar w-100 " +
                                        (step.completed
                                            ? "bg-secondary"
                                            : "bg-gray-4")
                                    }
                                ></div>
                            </div>
                            {index < steps.length - 1 && (
                                <div
                                    className={
                                        "step-circle rounded-circle d-flex align-items-center justify-content-center " +
                                        (step.completed
                                            ? "bg-secondary"
                                            : "bg-gray-4")
                                    }
                                    style={{ width: 8, height: 8 }}
                                ></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center align-items-center w-100 d-md-none ">
                {steps.map((step, index) => (
                    <div
                        key={step.id}
                        className="step-item d-flex flex-column justify-content-center align-items-center flex-grow-1"
                    >
                        <div
                            className={
                                "step-text fs-6 " +
                                (step.completed
                                    ? "text-secondary "
                                    : "text-gray-4")
                            }
                        >
                            {step.title}
                        </div>
                        <div className="d-flex align-items-center w-100 ">
                            <div
                                className="progress w-100"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ height: "4px" }}
                            >
                                <div
                                    className={
                                        "progress-bar w-100 " +
                                        (step.completed
                                            ? "bg-secondary"
                                            : "bg-gray-4")
                                    }
                                ></div>
                            </div>
                            {index < steps.length - 1 && (
                                <div
                                    className={
                                        "step-circle rounded-circle d-flex align-items-center justify-content-center " +
                                        (step.completed
                                            ? "bg-secondary"
                                            : "bg-gray-4")
                                    }
                                    style={{ width: 8, height: 8 }}
                                ></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Stepper;
