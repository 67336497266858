import { useState } from "react";
import MedalCard from "./MedalCard";

const MedalsListInModal = ({ medals = [], onMedalClick }) => {
    const [selectedMedalId, setSelectedMedalId] = useState(null);
    const handleMedalSelect = (medal) => {
        setSelectedMedalId(medal.id);
        onMedalClick(medal);
    };
    return (
        <div className="d-flex flex-wrap">
            {medals.length
                ? medals.map((medal) => (
                      <MedalCard
                          key={medal.id}
                          medal={medal}
                          onMedalClick={handleMedalSelect}
                          selectedMedalId={selectedMedalId}
                      />
                  ))
                : ""}
        </div>
    );
};

export default MedalsListInModal;
