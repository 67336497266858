import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import InfiniteScroll from 'react-infinite-scroll-component';
import { format, isSameDay } from 'date-fns';

const MessagesList = ({ dialog, dialogId }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [authUserId, setAuthUserId] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const messagesEndRef = useRef(null);
    const fileInputRef = useRef(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [viewedMessageIds, setViewedMessageIds] = useState([]);

    useEffect(() => {
        const previousDialogElement = document.getElementById(`chat-dialog-${dialogId}`);
        if (previousDialogElement) {
            previousDialogElement.innerHTML = '';
        }
        setMessages([]);
        setPage(1);
   
     }, [dialog]);

    useEffect(() => {
        fetchMessages();
        setViewedMessageIds([]); // Очистить массив просмотренных сообщений
    }, [dialog, page]);

    const fetchMessages = async () => {
        try {
            setLoading(true);
            setIsLoadingMore(true);
            const response = await axios.get(
                `/chat/dialogs/${dialog.id}/messages?page=${page}&viewed=${viewedMessageIds.join(',')}`
            );
            setMessages(prevMessages => [...response.data.messages.data.reverse(), ...prevMessages]);
            setAuthUserId(response.data.authUserId);
            setHasMore(response.data.messages.last_page > page);
            setLoading(false);
            setIsLoadingMore(false);

            // Обновите массив viewedMessageIds, добавив идентификаторы просмотренных сообщений
            setViewedMessageIds(prevIds => [
                ...prevIds,
                ...response.data.messages.data
                    .filter(message => message.sender_id !== authUserId)
                    .map(message => message.id),
            ]);
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
            setLoading(false);
            setIsLoadingMore(false);
        }
    };

    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (!newMessage.trim()) return;

        try {
            const response = await axios.post("/chat/messages", {
                dialog_id: dialog.id,
                body: newMessage,
            });
            setMessages(messages => [...messages, response.data.messageData]);
            setNewMessage("");
            scrollToBottom();
        } catch (error) {
            console.error("Ошибка при отправке сообщения:", error);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (page === 1) {
            scrollToBottom();
        }
    }, [messages]);

    return (
        <div className={`messages-container`} style={{ maxHeight: '500px', overflow: 'auto' }}>
            <InfiniteScroll
                dataLength={messages.length}
                next={() => setPage(page + 1)}
                hasMore={hasMore}
                loader={isLoadingMore && <div className="text-center">Загрузка...</div>}
                inverse={true}
                scrollableTarget="messages-container"
            >
            {messages.reduce((acc, message, index, array) => {
    const messageDate = new Date(message.created_at);
    const lastMessageDate = index > 0 ? new Date(array[index - 1].created_at) : null;
    const isCurrentUser = message.sender_id === authUserId;

    if (index === 0 || (lastMessageDate && !isSameDay(messageDate, lastMessageDate))) {
        acc.push(
            <div key={message.id + "-date"} className="push-whatsapp-date">
                {format(messageDate, "dd.MM.yyyy") === format(new Date(), "dd.MM.yyyy") ? "Сегодня" : format(messageDate, "dd.MM.yyyy")}
            </div>
        );
    }

    acc.push(
        <div 
            key={message.id} 
            className={`whatsapp-message-container ${isCurrentUser ? "whatsapp-right" : "whatsapp-left"}`}
        >
            {message.sender_id !== authUserId && (
                <img
                    src={message.sender.avatar ? message.sender.avatar.thumbnail_url : "/img/sk-logo.svg"}
                    alt={`${message.sender.name}'s Avatar`}
                    className={`whatsapp-avatar ${isCurrentUser ? "whatsapp-avatar-right" : ""}`}
                /> 
            
            )}
            <div className={`whatsapp-message-text ${isCurrentUser ? "whatsapp-right-text" : ""}`}>
       
            {
  isCurrentUser
    ? null
    : <div className="mb-1"><a href={`/user/${message.sender.id}`} className="user-link d-block">{message.sender.name}</a></div>
}
 
         {message.body} <c className="whatsapp-time"> {format(messageDate, "HH:mm")}</c>
            </div>
        </div>
    );
    return acc;
}, [])}

                <div ref={messagesEndRef} />
         
      
            </InfiniteScroll>

            <div className="infinite-chat-dialog"  id={`chat-dialog-${dialogId}`}></div>

            <div id={`chat-userTyping-dialog-${dialogId}`}> </div>
            <form onSubmit={handleSendMessage} encType="multipart/form-data">
                <div  
                className="input-group">
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Написать сообщение..."
                        className="form-control"
                        style={{ resize: "none" }} // Disable textarea resize
                    />
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*, video/*, audio/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={() => fileInputRef.current.click()} >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="button"><path d="M1.66663 15.5V7.77778C1.66663 6.70389 2.53718 5.83333 3.61107 5.83333C4.2231 5.83333 4.79941 5.54518 5.16663 5.05556L6.90329 2.74C7.01661 2.58892 7.19444 2.5 7.38329 2.5H12.6166C12.8055 2.5 12.9833 2.58892 13.0966 2.74L14.8333 5.05556C15.2005 5.54518 15.7768 5.83333 16.3888 5.83333C17.4627 5.83333 18.3333 6.70389 18.3333 7.77778V15.5C18.3333 16.6046 17.4379 17.5 16.3333 17.5H3.66663C2.56206 17.5 1.66663 16.6046 1.66663 15.5Z" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.99996 14.1667C11.8409 14.1667 13.3333 12.6743 13.3333 10.8333C13.3333 8.99238 11.8409 7.5 9.99996 7.5C8.15901 7.5 6.66663 8.99238 6.66663 10.8333C6.66663 12.6743 8.15901 14.1667 9.99996 14.1667Z" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                        </button>
                        <button type="submit" className="btn btn-primary">
                            <svg className="inside" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="4" fill="#BF1B2C" className="inside"></rect><path d="M18.6667 12L6 17.3334L8.375 12L6 6.66669L18.6667 12ZM18.6667 12L8.33333 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" className="inside"></path></svg>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MessagesList;
