// components/ChatContainer.jsx
import React, { useState } from "react";
import DialogsList from "./DialogsList"; // Компонент для списка диалогов
import MessagesList from "./MessagesList"; // Компонент для списка сообщений
import NewDialogForm from "../NewDialogForm"; // Компонент формы для создания нового диалога

const ChatContainer = () => {
    const [selectedDialog, setSelectedDialog] = useState(null);
    const [showNewDialogForm, setShowNewDialogForm] = useState(false);

    // Функция для обработки выбора диалога
    const handleSelectDialog = (dialog) => {
        setSelectedDialog(dialog);
        setShowNewDialogForm(false); // Скрыть форму создания диалога при выборе другого диалога
    };

    // Функция для обработки создания нового диалога
    const handleDialogCreated = (newDialog) => {
        setSelectedDialog(newDialog); // Выбрать новый диалог
        setShowNewDialogForm(false); // Скрыть форму создания диалога
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 overflow-auto border shadow">
                    <button
                        onClick={() => setShowNewDialogForm(true)}
                        className="btn btn-primary mb-3"
                    >
                        Создать новый диалог
                    </button>
                    <DialogsList onSelectDialog={handleSelectDialog} />
                </div>
                <div className="col-md-8 overflow-auto border shadow">
                    {showNewDialogForm ? (
                        <NewDialogForm onDialogCreated={handleDialogCreated} />
                    ) : selectedDialog ? (
                        <MessagesList
                            dialog={selectedDialog}
                            dialogId={selectedDialog.id}
                        />
                    ) : (
                        <div className="text-center mt-5">
                            <p className="mb-3">
                                Выберите диалог или создайте новый.
                            </p>
                            <button
                                onClick={() => setShowNewDialogForm(true)}
                                className="btn btn-primary"
                            >
                                Создать новый диалог
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatContainer;
