import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Кирилл: ну тут в целом мне особо и нечего добавить, данные в react-toastify вроде ок прокинуты.
//Ну стили потом поправивим, когда Влад нарисует.

function ToastEventHandler({ data }) {
    const message =
        data.action === "add"
            ? `${data.userName || "Пользователь"} поставил лайк!`
            : `${data.userName || "Пользователь"} убрал лайк!`;

    toast(
        <div style={{ textDecoration: "none", color: "inherit" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <img
                    src={data.userAvatar || "/img/sk-logo.svg"}
                    alt="User Avatar"
                    style={{ marginRight: "10px", borderRadius: "50%" }}
                />
                <div>
                    <div>
                        <strong>{message}</strong>
                    </div>
                    <div>{data.title}</div>
                    <a href={`/?get=${data.id}`}>Посмотреть</a>
                </div>
            </div>
        </div>,
        {
            position: "bottom-left",
            autoClose: 2500,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 60,
            hideProgressBar: false,
            newestOnTop: true,
        }
    );

    return (
        <div>
            <ToastContainer />
        </div>
    );
}

export default ToastEventHandler;
