import React from "react";

const EyeSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clip72_8100">
                    <rect
                        id="eye"
                        width="16"
                        height="16"
                        fill="white"
                        fillOpacity="0"
                    />
                </clipPath>
            </defs>
            <g clipPath="url(#clip72_8100)">
                <path
                    id="Vector 4"
                    d="M2 8.6665C4.3999 3.33301 11.6001 3.33301 14 8.6665"
                    stroke="#8C8C8C"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                />
                <path
                    id="Vector"
                    d="M8 11.3335C9.10449 11.3335 10 10.438 10 9.3335C10 8.229 9.10449 7.3335 8 7.3335C6.89551 7.3335 6 8.229 6 9.3335C6 10.438 6.89551 11.3335 8 11.3335Z"
                    stroke="#8C8C8C"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default EyeSVG;
