import React, { useRef } from "react";

function AudioPlayer({ file }) {
    const audioRef = useRef(null);
    return (
        <div
            className={file.original_file_path ? "file_original_file_path" : ""}
            data-path={file.original_file_path}
            data-type="audio"
        >
            {
                <audio controls ref={audioRef}>
                    <source
                        src={
                            file instanceof File
                                ? URL.createObjectURL(file)
                                : file.original_file_path
                        }
                        type={file.type}
                    />
                    Ваш браузер не поддерживает аудио элемент.
                </audio>
            }
        </div>
    );
}

export default AudioPlayer;
