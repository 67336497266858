const RandomSVG = () => {
    return (
        <svg
            width="32.000000"
            height="24.000000"
            viewBox="0 0 32 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clip4623_6241">
                    <rect
                        id="dice-six"
                        width="16.000000"
                        height="16.000000"
                        transform="translate(8.000000 4.000000)"
                        fill="white"
                        fillOpacity="0"
                    />
                </clipPath>
            </defs>
            <rect
                id="random-thx"
                rx="8.000000"
                width="32.000000"
                height="24.000000"
                fill="#BFBFBF"
                fillOpacity="1.000000"
            />
            <g clipPath="url(#clip4623_6241)">
                <path
                    id="Vector"
                    d="M10 17.39C10 17.73 10.26 18 10.6 18L21.4 18C21.73 18 22 17.73 22 17.39L22 6.6C22 6.26 21.73 6 21.4 6L10.6 6C10.26 6 10 6.26 10 6.6L10 17.39Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                />
                <path
                    id="Vector"
                    d="M12.99 9.33C12.81 9.33 12.66 9.18 12.66 9C12.66 8.81 12.81 8.66 12.99 8.66C13.18 8.66 13.33 8.81 13.33 9C13.33 9.18 13.18 9.33 12.99 9.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M12.99 9.33C13.18 9.33 13.33 9.18 13.33 9C13.33 8.81 13.18 8.66 12.99 8.66C12.81 8.66 12.66 8.81 12.66 9C12.66 9.18 12.81 9.33 12.99 9.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector"
                    d="M18.99 9.33C18.81 9.33 18.66 9.18 18.66 9C18.66 8.81 18.81 8.66 18.99 8.66C19.18 8.66 19.33 8.81 19.33 9C19.33 9.18 19.18 9.33 18.99 9.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M18.99 9.33C19.18 9.33 19.33 9.18 19.33 9C19.33 8.81 19.18 8.66 18.99 8.66C18.81 8.66 18.66 8.81 18.66 9C18.66 9.18 18.81 9.33 18.99 9.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector"
                    d="M12.99 12.33C12.81 12.33 12.66 12.18 12.66 12C12.66 11.81 12.81 11.66 12.99 11.66C13.18 11.66 13.33 11.81 13.33 12C13.33 12.18 13.18 12.33 12.99 12.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M12.99 12.33C13.18 12.33 13.33 12.18 13.33 12C13.33 11.81 13.18 11.66 12.99 11.66C12.81 11.66 12.66 11.81 12.66 12C12.66 12.18 12.81 12.33 12.99 12.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector"
                    d="M18.99 12.33C18.81 12.33 18.66 12.18 18.66 12C18.66 11.81 18.81 11.66 18.99 11.66C19.18 11.66 19.33 11.81 19.33 12C19.33 12.18 19.18 12.33 18.99 12.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M18.99 12.33C19.18 12.33 19.33 12.18 19.33 12C19.33 11.81 19.18 11.66 18.99 11.66C18.81 11.66 18.66 11.81 18.66 12C18.66 12.18 18.81 12.33 18.99 12.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector"
                    d="M12.99 15.33C12.81 15.33 12.66 15.18 12.66 15C12.66 14.81 12.81 14.66 12.99 14.66C13.18 14.66 13.33 14.81 13.33 15C13.33 15.18 13.18 15.33 12.99 15.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M12.99 15.33C13.18 15.33 13.33 15.18 13.33 15C13.33 14.81 13.18 14.66 12.99 14.66C12.81 14.66 12.66 14.81 12.66 15C12.66 15.18 12.81 15.33 12.99 15.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector"
                    d="M18.99 15.33C18.81 15.33 18.66 15.18 18.66 15C18.66 14.81 18.81 14.66 18.99 14.66C19.18 14.66 19.33 14.81 19.33 15C19.33 15.18 19.18 15.33 18.99 15.33Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M18.99 15.33C19.18 15.33 19.33 15.18 19.33 15C19.33 14.81 19.18 14.66 18.99 14.66C18.81 14.66 18.66 14.81 18.66 15C18.66 15.18 18.81 15.33 18.99 15.33Z"
                    stroke="#FFFFFF"
                    strokeOpacity="1.000000"
                    strokeWidth="1.000000"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default RandomSVG;
