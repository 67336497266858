const ColoredCircleSmall = ({ imageHref, title }) => {
    return (
        <svg
            width="32.000000"
            height="32.000000"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs />
            <path
                id="Ellipse 1830"
                d="M27.31 4.68C28.84 6.21 30 7.98 30.78 9.87C31.61 11.88 32 13.95 32 16L30.4 16C30.4 14.04 30.01 12.18 29.3 10.48C28.55 8.68 27.48 7.11 26.18 5.81L27.31 4.68Z"
                fill="#F4CB01"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1831"
                d="M16 0C20.41 0 24.41 1.79 27.31 4.68L26.18 5.81C23.37 3 19.68 1.6 16 1.59L16 0Z"
                fill="#FFB703"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1832"
                d="M4.68 4.68C6.21 3.15 7.98 1.99 9.87 1.21C11.88 0.38 13.95 -0.01 16 0L16 1.59C14.04 1.59 12.18 1.98 10.48 2.69C8.68 3.44 7.11 4.51 5.81 5.81L4.68 4.68Z"
                fill="#F4CB01"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1833"
                d="M0 16C0 13.83 0.43 11.76 1.21 9.87C2.04 7.87 3.23 6.12 4.68 4.68L5.81 5.81C4.43 7.19 3.39 8.78 2.69 10.48C1.94 12.29 1.59 14.16 1.59 16L0 16Z"
                fill="#FFB703"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1834"
                d="M4.68 27.31C3.15 25.78 1.99 24.01 1.21 22.12C0.38 20.11 -0.01 18.04 0 16L1.59 16C1.59 17.95 1.98 19.81 2.69 21.51C3.44 23.31 4.51 24.88 5.81 26.18L4.68 27.31Z"
                fill="#F4CB01"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1835"
                d="M16 32C13.83 32 11.76 31.56 9.87 30.78C7.87 29.95 6.12 28.76 4.68 27.31L5.81 26.18C7.19 27.56 8.78 28.6 10.48 29.3C12.29 30.05 14.16 30.4 16 30.4L16 32Z"
                fill="#FFB703"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1836"
                d="M27.31 27.31C25.78 28.84 24.01 30 22.12 30.78C20.11 31.61 18.04 32 16 32L16 30.4C17.95 30.4 19.81 30.01 21.51 29.3C23.31 28.55 24.88 27.48 26.18 26.18L27.31 27.31Z"
                fill="#F4CB01"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            <path
                id="Ellipse 1837"
                d="M32 16C32 18.16 31.56 20.23 30.78 22.12C29.95 24.12 28.76 25.87 27.31 27.31L26.18 26.18C27.56 24.8 28.6 23.21 29.3 21.51C30.05 19.7 30.4 17.83 30.4 16L32 16Z"
                fill="#FFB703"
                fillOpacity="1.000000"
                fillRule="evenodd"
            />
            {imageHref ? (
                <image
                    href={imageHref}
                    className="w-20 h-20"
                    x="6"
                    y="6"
                    alt="Подарок"
                >
                    <title>{title || ""}</title>
                </image>
            ) : (
                ""
            )}
        </svg>
    );
};

export default ColoredCircleSmall;
