import { handleFileDownload } from "../../../functions";

const XlsSVG = ({ file }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
            onClick={() => handleFileDownload(file)}
        >
            <g clipPath="url(#clip0_2693_1121)">
                <path
                    d="M0.762695 3.1545V36.764L24.3988 40V36.5467C24.4517 36.5504 24.504 36.5546 24.5578 36.5546H36.668C38.0842 36.5546 39.2367 35.4017 39.2367 33.9858V5.91223C39.2367 4.49612 38.0842 3.3434 36.668 3.3434H24.5578C24.504 3.3434 24.4517 3.34859 24.3988 3.3518V0L0.762695 3.1545ZM24.3988 24.2438H29.7582V26.629H24.3988V24.2438ZM24.3988 21.1246V18.7391H29.7582V21.1246H24.3988ZM6.8686 23.9489L6.20919 22.4917C5.94205 21.9352 5.77186 21.5249 5.57074 21.0679L5.5491 21.0675C5.40157 21.5166 5.22277 21.9166 5.00377 22.4519L4.42321 23.8288L2.66938 23.7432L4.64003 19.9282L2.73833 16.1878L4.50527 16.1152L5.11825 17.5087C5.3277 17.9768 5.48595 18.357 5.65505 18.7948H5.67587C5.84627 18.2932 5.98459 17.9413 6.16632 17.4826L6.78169 16.0218L8.71384 15.9417L6.60809 19.8889L8.82799 24.0446L6.8686 23.9489ZM14.9395 24.3438L9.76928 24.0912V15.8981L11.5828 15.8232V22.5924L14.9392 22.6993V24.3438H14.9395ZM18.3663 24.6445C17.3062 24.5907 16.2812 24.2792 15.777 23.9829L16.1875 22.3617C16.7369 22.6512 17.593 22.9551 18.4918 22.9853C19.4786 23.0182 20.0077 22.6441 20.0077 22.0488C20.0077 21.4805 19.5498 21.1489 18.4081 20.7461C16.872 20.218 15.895 19.4079 15.895 18.1361C15.895 16.6444 17.1832 15.4473 19.3934 15.3527C20.4844 15.3061 21.3044 15.4903 21.898 15.7141L21.393 17.4198C20.9953 17.2509 20.2961 17.0096 19.3505 17.0338C18.4224 17.0579 17.9784 17.4676 17.9784 17.931C17.9784 18.5009 18.5061 18.7483 19.7353 19.182C21.4672 19.778 22.3028 20.6365 22.3028 21.9503C22.303 23.5146 21.0108 24.7778 18.3663 24.6445ZM24.5578 4.81132H36.6681C37.2752 4.81132 37.7689 5.30532 37.7689 5.91223V33.9858C37.7689 34.5929 37.2752 35.0867 36.6681 35.0867H24.5578C24.5034 35.0867 24.4511 35.0788 24.3988 35.0709V26.9961H36.211V15.6198H24.3988V4.82798C24.4511 4.82006 24.5034 4.81132 24.5578 4.81132ZM35.8441 18.7391V21.1245H30.1255V18.7391H35.8441ZM30.1255 18.3721V15.9867H35.8441V18.3721H30.1255ZM35.8441 24.2438V26.629H30.1255V24.2438H35.8441Z"
                    fill="#8C8C8C"
                />
            </g>
            <defs>
                <clipPath id="clip0_2693_1121">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default XlsSVG;
