import React, { useState } from "react";

function CommentsButton({
    model,
    data,
    totalCommentsCount,
    handleCommentsToggle,
    isCommentsExpanded,
}) {
    return (
        <div
            className={`d-flex align-items-center gap-2 sk-bg-gray-2 rounded px-2 py-1 comments-btn ${
                isCommentsExpanded ? "expanded" : ""
            }`}
            role="button"
            onClick={() => handleCommentsToggle()}
        >
            <div>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_2008_12724)">
                        <path
                            d="M9.99984 18.3327C14.6022 18.3327 18.3332 14.6017 18.3332 9.99935C18.3332 5.39698 14.6022 1.66602 9.99984 1.66602C5.39746 1.66602 1.6665 5.39698 1.6665 9.99935C1.6665 11.5172 2.07231 12.9403 2.78136 14.166L2.08317 17.916L5.83317 17.2178C7.05889 17.9269 8.48197 18.3327 9.99984 18.3327Z"
                            stroke="#828282"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2008_12724">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <span className="text-muted">
                <div id={`App_Models_${model}-comment-${data.id}-commentCount`}>
                    {totalCommentsCount}
                </div>
            </span>
        </div>
    );
}

export default CommentsButton;
