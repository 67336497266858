const TextSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 3.1V16.9C17.5 17.2314 17.2314 17.5 16.9 17.5H3.1C2.76863 17.5 2.5 17.2314 2.5 16.9V3.1C2.5 2.76863 2.76863 2.5 3.1 2.5H16.9C17.2314 2.5 17.5 2.76863 17.5 3.1Z"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83337 7.50004V5.83337L14.1667 5.83337V7.50004"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 5.83337V14.1667M10 14.1667H8.33337M10 14.1667H11.6667"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TextSVG;
