import React from "react";

const Cross = () => {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.83321 0.832805L5 4.9996M9.16679 9.16638L5 4.9996M5 4.9996L9.16679 0.832805M5 4.9996L0.83321 9.16638"
                stroke="#202227"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Cross;
