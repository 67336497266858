import React, { useEffect } from "react";

// Функция для анимации изменения значения
export const animateValueChange = (elementId, start, end, duration) => {
    const element = document.getElementById(elementId);
    if (!element) return;

    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        element.textContent = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};

function StaticEventHandler({ data }) {
    const updateCount = (elementId, dataKey) => {
        const element = document.getElementById(elementId);
        if (element) {
            const currentValue = parseInt(element.textContent) || 0;
            const newValue = data[dataKey];

            // Проверяем, не равно ли новое значение null
            if (newValue !== null) {
                const numericNewValue = typeof newValue === "number" ? newValue : 0;
                const duration = Math.abs(numericNewValue - currentValue) * 50;

                if (currentValue !== numericNewValue) {
                    animateValueChange(elementId, currentValue, numericNewValue, duration);
                }
            }
        }
    };

    // Обновляем значение уникальных пользователей за сегодня
    updateCount("unique_today", "unique_today");

    // Обновляем значение пользователей онлайн
    updateCount("online_users", "online_users");
   
    // Обновляем значение благодарностей
    updateCount("thanks_given", "thanks_given");
    updateCount("total_amount", "total_amount");
    return null;
}

export default StaticEventHandler;
