import React, { useEffect } from "react";

function LikeHandlerComponent() {
    // Функция для обработки клика
    const handleLikeClick = async (type, id) => {
        try {
            // Здесь мы делаем GET-запрос к вашему серверу
            const response = await fetch(`/like/${type}/${id}`);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            console.log("Like was successful for", type, "ID:", id);
        } catch (error) {
            console.error("Failed to send like for", type, "ID:", id, error);
        }
    };

    useEffect(() => {
        // Добавляем функцию в глобальный объект window
        window.handleLikeClick = handleLikeClick;

        return () => {
            // Удаляем функцию при размонтировании компонента
            delete window.handleLikeClick;
        };
    }, []);

    return null; // Компонент не отображает ничего сам по себе
}

export default LikeHandlerComponent;
