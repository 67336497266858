import { useState } from "react";
import MeowSVG from "../Icons/MeowSVG";

const MedalCard = ({ medal, onMedalClick, selectedMedalId }) => {
    const [hover, setHover] = useState(false);
    const selected = medal.id === selectedMedalId;

    return (
        <div
            className={
                "medal_card d-flex p-2 my-2 gap-2 col-6 rounded " +
                (hover && !selected ? "bg-gray-2" : "") +
                (selected ? "bg-disabled" : "")
            }
            key={medal.id}
            role="button"
            onClick={() => {
                onMedalClick(medal);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div>
                <img
                    className="w-48 h-48"
                    src={medal.image}
                    alt={medal.title}
                />
            </div>
            <div className="d-flex flex-column">
                <div>
                    <span className="d-md-none" style={{ fontSize: "14px" }}>
                        {medal.title}
                    </span>
                    <span className="d-none d-md-inline">{medal.title}</span>
                </div>
                <div className="d-flex gap-1 pt-1">
                    <MeowSVG />
                    <small className="text-gray-6-text text-primary">
                        {medal.meow}{" "}
                        <span class="d-none d-md-inline">Мяукоинов</span>
                    </small>
                </div>
            </div>
        </div>
    );
};

export default MedalCard;
