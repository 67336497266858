import { handleFileDownload } from "../../../functions";

const PptSVG = ({ file }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
            onClick={() => handleFileDownload(file)}
        >
            <g clipPath="url(#clip0_2716_1336)">
                <path
                    d="M24.3988 36.5464C24.4517 36.5493 24.504 36.5539 24.5578 36.5539H36.668C38.0846 36.5539 39.2367 35.4013 39.2367 33.9851V5.91188C39.2367 4.49578 38.0846 3.34306 36.668 3.34306H24.5578C24.5044 3.34306 24.4517 3.34832 24.3988 3.35078V0L0.762695 3.15443V36.764L24.3988 40V36.5464ZM24.5578 4.81132H36.6681C37.2752 4.81132 37.7689 5.30532 37.7689 5.91223V33.9858C37.7689 34.5925 37.2752 35.0867 36.6681 35.0867H24.5578C24.5038 35.0867 24.4511 35.0781 24.3988 35.0702V21.7923C25.0761 24.3686 27.4141 26.2711 30.2029 26.2711C33.5215 26.2711 36.2115 23.5812 36.2115 20.263H30.2029V14.2541C27.4139 14.2541 25.0757 16.1569 24.3988 18.7334V4.82736C24.4511 4.81972 24.5034 4.81132 24.5578 4.81132ZM7.94807 20.2831C7.39031 20.8379 6.57266 21.0822 5.62815 21.0748C5.41959 21.073 5.23254 21.0604 5.0876 21.0371V23.7295L3.54083 23.6905V16.3387C4.01892 16.2385 4.69499 16.1538 5.6586 16.1283C6.64679 16.1023 7.3587 16.2879 7.8402 16.6858C8.30484 17.0635 8.61854 17.6984 8.61854 18.4532C8.61888 19.2084 8.38042 19.8495 7.94807 20.2831ZM14.4762 20.2945C13.8692 20.874 12.9782 21.1281 11.9503 21.1206C11.7238 21.1191 11.5201 21.106 11.3632 21.0815V23.8898L9.68026 23.8467V16.1834C10.2006 16.0781 10.9353 15.9896 11.9837 15.9618C13.059 15.9339 13.8344 16.1258 14.3592 16.5404C14.8645 16.9346 15.2072 17.5974 15.2072 18.3851C15.2072 19.1732 14.9464 19.843 14.4762 20.2945ZM22.1797 17.3521L19.8379 17.3904V24.1056L17.9415 24.0572V17.4208L15.7539 17.4568V15.9217L22.1797 15.755V17.3521Z"
                    fill="#8C8C8C"
                />
                <path
                    d="M5.743 17.4168C5.41954 17.4227 5.20163 17.4598 5.08789 17.4957V19.7192C5.2219 19.7525 5.388 19.7635 5.61738 19.7629C6.46651 19.7613 6.9958 19.2964 6.9958 18.5159C6.99587 17.8139 6.5414 17.4028 5.743 17.4168ZM12.0754 17.3055C11.7242 17.3115 11.4868 17.3507 11.3639 17.3878V19.7066C11.5099 19.7416 11.6902 19.7525 11.9393 19.7525C12.8641 19.751 13.4401 19.266 13.4401 18.4509C13.4401 17.7184 12.9441 17.2902 12.0754 17.3055ZM31.0189 19.3147V19.3165H37.0278C37.0278 17.6267 36.3267 16.1016 35.2033 15.0101L31.0189 19.3147ZM30.9551 18.259L35.2034 14.0105C34.0086 12.815 32.5177 12.2323 30.9509 12.2546L30.9538 18.2575L30.9551 18.259Z"
                    fill="#8C8C8C"
                />
            </g>
            <defs>
                <clipPath id="clip0_2716_1336">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PptSVG;
