import React, { useEffect, useRef, useState } from "react";
import ColoredCircle from "../Icons/ColoredCircle";
import { useSelector } from "react-redux";
import { generateColorFromId } from "../../functions";
import ColoredCircleSmall from "../Icons/ColoredCircleSmall";

function PostBodyComponent({
    data,
    attachmentsCount,
    onOpenModal,
    textTruncate,
}) {
    const users = useSelector((state) => state.users.users);
    const [isTruncated, setIsTruncated] = useState(textTruncate);
    const textRef = useRef(null);
    const maxHeight = 403;
    const { transaction, stena_transaction } = data;

    let postContent = null;

    useEffect(() => {
        if (textTruncate) {
            setIsTruncated(textRef.current.scrollHeight > maxHeight);
        }
    }, []);

    function getUserName(userId) {
        return users.find((u) => u.id * 1 === userId * 1)?.name;
    }

    function getUser(userId) {
        const user = users.find((u) => u.id * 1 === userId * 1);
        if (user) {
            return user;
        }
    }

    function returnUserAvatar(user_id) {
        const user = getUser(user_id);
        if (user) {
            if (user.avatar) {
                return (
                    <img
                        src={
                            user.avatar.thumbnail_url[0] === "/"
                                ? user.avatar.thumbnail_url
                                : `/${user.avatar.thumbnail_url}`
                        }
                        alt="user avatar"
                        className="rounded-circle object-fit-cover w-32 h-32 d-none d-lg-block"
                        width="32"
                        height="32"
                    />
                );
            } else {
                return (
                    <span
                        className={
                            "rounded-circle object-fit-cover text-white w-32 h-32 d-flex justify-content-center align-items-center d-none d-lg-flex"
                        }
                        style={{
                            backgroundColor: generateColorFromId(user_id),
                        }}
                    >
                        {user.short_name}
                    </span>
                );
            }
        }
    }

    switch (data.extra_types) {
        case "random":
            // Add logic for 'random' case here
            postContent = (
                <div dangerouslySetInnerHTML={{ __html: data.body }} />
            );
            break;
        case "thanks":
            // Add logic for 'thanks' case here if needed
            if (transaction && transaction.type === "gratitude") {
                postContent = (
                    <div dangerouslySetInnerHTML={{ __html: data.body }} />
                );
            }
            break;
        case "gift":
            // console.log("data", data);
            const postContentBody = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.body,
                    }}
                />
            );
            if (transaction?.type === "shop" && transaction?.shop?.id) {
                postContent = (
                    <div className="d-flex flex-column gap-2">
                        {stena_transaction?.length
                            ? stena_transaction.map((s_t) => (
                                  <div
                                      className="d-flex gap-2 align-items-center"
                                      key={s_t.transaction_id}
                                  >
                                      <ColoredCircleSmall
                                          imageHref={
                                              s_t.transaction?.shop?.image ||
                                              transaction?.shop?.image
                                          }
                                          title={
                                              s_t.transaction?.shop?.name ||
                                              transaction?.shop?.name
                                          }
                                      />

                                      <div className="d-flex flex-column gap-2">
                                          <div className="d-flex gap-1 flex-wrap">
                                              <a
                                                  href={"/user/" + s_t?.user_id}
                                                  className="d-flex align-items-center gap-1"
                                              >
                                                  {/* {returnUserAvatar(
                                                      s_t.user_id
                                                  )} */}
                                                  <span>
                                                      {"@" +
                                                          getUser(s_t.user_id)
                                                              ?.name}
                                                  </span>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : ""}
                        <div>{postContentBody}</div>
                    </div>
                );
            }
            break;
        case "reward":
            // console.log(data);
            postContent = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.body,
                    }}
                />
            );
            if (
                transaction?.type === "medal_awarded" &&
                transaction?.medal_id
            ) {
                postContent = (
                    <div className="d-flex gap-2 align-items-top">
                        <img
                            className="rounded-circle w-48 h-48 object-fit-cover"
                            style={{ borderColor: "#FFB703" }}
                            src={transaction?.medal?.image}
                            alt="Медаль"
                            title={transaction?.medal?.name}
                        />
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1 flex-wrap">
                                {stena_transaction?.length
                                    ? stena_transaction.map((s_t) => (
                                          <a
                                              href={"/user/" + s_t?.user_id}
                                              key={s_t.transaction_id}
                                              className="d-flex align-items-center gap-1"
                                          >
                                              {returnUserAvatar(s_t.user_id)}
                                              <span>
                                                  {"@" +
                                                      getUser(s_t.user_id)
                                                          ?.name}
                                              </span>
                                          </a>
                                      ))
                                    : ""}
                            </div>
                            <div>{postContent}</div>
                        </div>
                    </div>
                );
            }
            break;
        case "badge":
            postContent = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.body,
                    }}
                />
            );
            if (transaction?.type === "badges" && transaction?.badge?.id) {
                postContent = (
                    <div className="d-flex gap-2 align-items-top">
                        <img
                            className="object-fit-cover h-32"
                            style={{
                                borderColor: "#FFB703",
                                width: "120px",
                                // height: "56px",
                            }}
                            src={transaction?.badge?.image}
                            alt={transaction?.badge?.title}
                            title={transaction?.badge?.title}
                        />
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1 flex-wrap">
                                {stena_transaction?.length
                                    ? stena_transaction.map((s_t) => (
                                          <a
                                              href={"/user/" + s_t?.user_id}
                                              key={s_t.transaction_id}
                                              className="d-flex align-items-center gap-1"
                                          >
                                              {returnUserAvatar(s_t.user_id)}
                                              <span>
                                                  {"@" +
                                                      getUser(s_t.user_id)
                                                          ?.name}
                                              </span>
                                          </a>
                                      ))
                                    : ""}
                            </div>
                            <div>{postContent}</div>
                        </div>
                    </div>
                );
            }
            break;
        case "bage":
            postContent = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.body,
                    }}
                />
            );
            if (transaction?.type === "badges" && transaction?.badge?.id) {
                postContent = (
                    <div className="d-flex flex-wrap gap-2 align-items-top">
                        <img
                            className="object-fit-cover"
                            style={{
                                borderColor: "#FFB703",
                                width: "120px",
                                height: "56px",
                            }}
                            src={transaction?.badge?.image}
                            alt={transaction?.badge?.title}
                            title={transaction?.badge?.title}
                        />
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-1 flex-wrap">
                                {stena_transaction?.length
                                    ? stena_transaction.map((s_t) => (
                                          <a
                                              href={"/user/" + s_t?.user_id}
                                              key={s_t.transaction_id}
                                              className="d-flex align-items-center gap-1"
                                          >
                                              {returnUserAvatar(s_t.user_id)}
                                              <span>
                                                  {"@" +
                                                      getUser(s_t.user_id)
                                                          ?.name}
                                              </span>
                                          </a>
                                      ))
                                    : ""}
                            </div>
                            <div>{postContent}</div>
                        </div>
                    </div>
                );
            }
            break;
        // case 'mention':
        //   // Add logic for 'mention' case here if needed
        //   postContent = (
        //     // Add your HTML structure for 'mention' case here
        //   );
        //   break;
        default:
            // Add default case here
            postContent = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.body,
                    }}
                />
            );
            break;
    }

    function onReadMoreBtnClick() {
        if (onOpenModal) {
            onOpenModal();
        } else {
            setIsTruncated(false);
        }
    }

    return (
        <div ref={textRef}>
            <div
                className="sk_post_body d-none d-md-block"
                style={
                    isTruncated
                        ? { maxHeight: maxHeight + "px", overflow: "hidden" }
                        : {}
                }
            >
                {postContent}
            </div>
            <div
                className="sk_post_body d-block d-md-none text-14"
                style={
                    isTruncated
                        ? { maxHeight: maxHeight + "px", overflow: "hidden" }
                        : {}
                }
            >
                {postContent}
            </div>
            {isTruncated && textTruncate && (
                <button
                    className="btn p-0 border-0 text-primary"
                    onClick={onReadMoreBtnClick}
                    style={{ fontSize: "1rem" }}
                >
                    Читать полностью
                </button>
            )}
        </div>
    );
}

export default PostBodyComponent;
