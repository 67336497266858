import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import CheckCircleSVG from "./Icons/CheckCircleSVG";
import { useSelector } from "react-redux";
import Cross from "./Icons/CrossSVG";

const Notifications = ({ target, handlePopoverMouseLeave, show = false }) => {
    const [popperRef, setPopperRef] = useState(null);
    const [showPopover, setShowPopover] = useState(show);
    const handleDelete = (NotificationsId) => {
        console.log("delete", NotificationsId);
        const newNotices = notices.filter(
            (n) => n.sender_id !== NotificationsId
        );
        setNotices(newNotices);
    };
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const initialNotices = [
        {
            sender_id: 1,
            text: "Большое спасибо за помощь на складе",
            date: "25 декабря 2023",
        },
        {
            sender_id: 2,
            text: "Держи вкусняшку :)",
            date: "25 декабря 2023",
        },
        {
            sender_id: 3,
            text: "За работу над порталом",
            date: "25 декабря 2023",
        },
        {
            sender_id: 4,
            text: "С Днём Рождения!",
            date: "25 декабря 2023",
        },
        {
            text: "Вам начислено 50 Реддиков!",
            date: "25 декабря 2023",
        },
    ];
    const [notices, setNotices] = useState(initialNotices);
    const users = useSelector((state) => state.users.users);

    const targetRef = useRef(target);
    const { styles, attributes } = usePopper(targetRef.current, popperRef, {
        placement: "bottom",
    });
    const additionalStyles = {
        maxWidth: "436px", // Ваше новое значение для maxWidth
    };

    // Объединяем основные стили с дополнительными
    const popoverStyles = {
        ...styles.popper,
        ...additionalStyles,
        lineHeight: "1.2",
    };

    const handleClosePopover = () => {
        setShowPopover(false);
    };

    function clearAllNotices() {
        setNotices([]);
    }

    const handlePopoverToggle = ({ target }) => {
        if (!target.closest(".notif-popover")) {
            setShowPopover(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handlePopoverToggle);
    }, []);

    function getUserName(id) {
        let name = "Неизвестный пользователь";
        const user = users.find((user) => user.id === id);
        if (user) {
            name = user.name;
        }
        return name;
    }

    function getUserAvatar(id) {
        let avatar = "https://www.w3schools.com/howto/img_avatar.png";
        const user = users.find((user) => user.id === id);
        if (user) {
            avatar = user.avatar?.thumbnail_url || avatar;
        }
        return avatar;
    }

    return (
        <>
            {showPopover ? (
                <div
                    className={`notif-popover bs-popover-auto popover border-1 border-gray-3 m-4 p-2 ${
                        showPopover ? "show" : ""
                    }`}
                    style={{ ...popoverStyles, backgroundColor: "white" }}
                    {...attributes.popper}
                    ref={setPopperRef}
                    role=""
                    onMouseLeave={handlePopoverMouseLeave}
                    onMouseEnter={() => setIsHovered(true)} // Устанавливаем isHovered в true при наведении на родительский div
                >
                    <div className="d-flex flex-column gap-4 p-2">
                        <div className="d-flex justify-content-between align-items-center gap-4">
                            <h5 className="m-0 p-0">
                                Уведомления (демо-данные)
                            </h5>
                            {notices.length ? (
                                <button
                                    className="notif-popover btn p-0 m-0 btn-sm border-0 text-primary"
                                    onClick={clearAllNotices}
                                >
                                    Скрыть всё
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className="d-flex flex-column gap-2"
                            onMouseEnter={() => setIsHovered(true)} // Устанавливаем isHovered в true при наведении на родительский div
                        >
                            {notices.map((notice, index) => (
                                <div
                                    className="d-flex align-items-center  gap-2  mb-2"
                                    key={index}
                                    onMouseEnter={() => setHoveredIndex(index)} // Устанавливаем индекс элемента, на который наведен курсор
                                    onMouseLeave={() => setHoveredIndex(null)} // Сбрасываем индекс, когда курсор уходит с элемента
                                >
                                    <img
                                        src={getUserAvatar(notice.sender_id)}
                                        className="w-48 h-48 rounded-circle object-fit-cover"
                                    />
                                    <div className="d-flex flex-column">
                                        {notice.sender_id && (
                                            <span className="text-primary">
                                                {getUserName(notice.sender_id)}
                                            </span>
                                        )}
                                        <span className="">
                                            {notice.text && (
                                                <>
                                                    {notice.text}
                                                    <div>
                                                        <small className="text-muted">
                                                            {notice.date}
                                                        </small>
                                                    </div>
                                                </>
                                            )}
                                        </span>
                                    </div>
                                    {hoveredIndex === index && ( // Рендерим кнопку с крестиком только если текущий индекс равен индексу элемента, на который наведен курсор
                                        <button
                                            className="btn-sm rounded position-absolute end-0 me-2 py-0 px-1 bg-gray-3 border-0    "
                                            onClick={() =>
                                                handleDelete(notice.sender_id)
                                            }
                                        >
                                            <Cross />
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                        {notices.length === 0 && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <span className="text-muted p-2 ">
                                    Уведомлений нет
                                </span>
                                <CheckCircleSVG />
                            </div>
                        )}
                        <div className="d-flex justify-content-center">
                            {notices.length ? (
                                <span
                                    className="text-primary p-2 "
                                    role="button"
                                >
                                    Смотреть все
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default Notifications;

{
    /* <style>.bs-popover-bg {background-color: #FEF3F3}</style>; */
}
