import axios from "axios";
import ReusableCommentInput from "../../ReusableCommentInput.jsx";
import { useState } from "react";

const CommentInput = ({
    postId,
    commentId,
    _token,
    startingInputValue = "",
    setIsCommentsExpanded = () => {},
}) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const token =
        _token || document.querySelector("meta[name=csrf-token]").content;

    function sendComment(data, selectedFiles) {
        const formData = new FormData();
        selectedFiles.forEach((file) => formData.append("files[]", file));
        formData.append("body", data);
        formData.append("commentable_id", postId); // не нужна
        //юзер ид кому, редик сколько, текст сообщения,
        //user_id - кому
        //redik
        //body - текст без юзеров
        //extra_types = thanks
        formData.append("commentable_type", "App\\Models\\Stena"); // не нужна
        if (commentId) {
            // не нужна
            formData.append("parent_id", commentId); // не нужна
        } // не нужна
        formData.append("_token", token);
        axios.post("/comments", formData, {
            //http://127.0.0.1:8000/stenas
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        setIsCommentsExpanded();
        setIsInputFocused(false);
    }

    return (
        <>
            <ReusableCommentInput
                startingInputValue={startingInputValue}
                handleSendBtnClick={sendComment}
                fileLimit={2}
                postId={postId}
                setIsInputFocused={setIsInputFocused}
                isInputFocused={isInputFocused}
            />
        </>
    );
};

export default CommentInput;
