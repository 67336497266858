const GratitudeSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.8 11.3332L14 4.6665L9.8 6.6665L8 4.6665L6.2 6.6665L2 4.6665L3.2 11.3332H12.8Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GratitudeSVG;
