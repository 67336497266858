// resources/js/app.js
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import WebSocketComponent from "./components/soket/WebSocketComponent.jsx";
import PostField from "./components/PostField/index.jsx";
import InfiniteScrollComponent from "./components/Stena/InfiniteScrollComponent.jsx";
import UserRandom from "./components/UserRandom.jsx";
import ShopContainer from "./components/Trash/Shop.jsx";
import Leaderboard from "./components/Leaderboard.jsx";
import SearchComponent from "./components/SearchComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUserId, fetchUsers } from "./redux/usersSlice.js";
import { Provider } from "react-redux";
import store from "./redux/store";
import { fetchMoreData } from "./redux/stenasSlice.js";
import MeetingRoomBooking from "./components/Trash/MeetingRoomBooking.jsx";
import TransferCurrency from "./components/Trash/TransferCurrency.jsx";
import StenaPlaceholder from "./components/Stena/StenaPlaceholder.jsx";
import UserBlocks from "./components/UserBlocks.jsx";
import { fetchExtraTypes } from "./redux/extraTypesSlice.js";
import ReusableModal from "./components/ReusableModal.jsx";
import LikeHandlerComponent from "./components/Trash/LikeHandlerComponent.jsx";
import DigestList from "./components/Trash/DigestList.jsx";
import ChatContainer from "./components/Trash/ChatContainer.jsx";
import SettingsPopover from "./components/SettingsPopover.jsx";
import * as bootstrap from "bootstrap";
import Notifications from "./components/Notifications";
import MBTI from "./components/MBTI.jsx";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import SubscribePopover from "./components/SubscribePopover.jsx";
import SubscribeButton from "./components/SubscribeButton.jsx";

const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

const UserBlocksElement = document.getElementById("UserBlocks");
if (UserBlocksElement) {
    createRoot(UserBlocksElement).render(<UserBlocks />);
}

const TransferCurrencyElement = document.getElementById("TransferCurrency");
if (TransferCurrencyElement) {
    const recipientId = TransferCurrencyElement.getAttribute("data-user-id");
    createRoot(TransferCurrencyElement).render(
        <TransferCurrency recipientId={recipientId} />
    );
}

const MeetingRoomBookingElement = document.getElementById("MeetingRoomBooking");
if (MeetingRoomBookingElement) {
    createRoot(MeetingRoomBookingElement).render(<MeetingRoomBooking />);
}

const SearchComponentElementDesktop = document.getElementById(
    "SearchComponentDesktop"
);
if (SearchComponentElementDesktop) {
    createRoot(SearchComponentElementDesktop).render(
        <Provider store={store}>
            <SearchComponent />
        </Provider>
    );
}

const SearchComponentElementMobile = document.getElementById(
    "SearchComponentMobile"
);
if (SearchComponentElementMobile) {
    createRoot(SearchComponentElementMobile).render(
        <Provider store={store}>
            <SearchComponent />
        </Provider>
    );
}

const shopContainerElement = document.getElementById("shop-container");
if (shopContainerElement) {
    createRoot(shopContainerElement).render(<ShopContainer />);
}

const LeaderboardContainerElement = document.getElementById(
    "Leaderboard-container"
);
if (LeaderboardContainerElement) {
    createRoot(LeaderboardContainerElement).render(<Leaderboard />);
}

const chatContainerElement = document.getElementById("chat-container");
if (chatContainerElement) {
    createRoot(chatContainerElement).render(<ChatContainer />);
}

if (document.getElementById("digests-root")) {
    ReactDOM.render(<DigestList />, document.getElementById("digests-root"));
}

if (document.getElementById("UserRandom")) {
    const root = createRoot(document.getElementById("UserRandom"));
    root.render(<UserRandom />);
}

if (document.getElementById("LikeHandlerComponent")) {
    const root = createRoot(document.getElementById("LikeHandlerComponent"));
    root.render(<LikeHandlerComponent />);
}

if (document.getElementById("soket")) {
    const root = createRoot(document.getElementById("soket"));

    root.render(
        <Provider store={store}>
            <WebSocketComponent />
        </Provider>
    );
}

if (document.getElementById("post_field")) {
    createRoot(document.getElementById("post_field")).render(
        <Provider store={store}>
            <PostField />
        </Provider>
    );
}

//         // Обработка клика по имени пользователя внутри окна ввода комментария
document.addEventListener("click", (e) => {
    if (e.target.classList?.contains("reply-click")) {
        e.preventDefault();
        // Получаем значение атрибута href
        const href = e.target.getAttribute("href");

        // Извлекаем айди из href
        const commentId = href.split("_")[1];

        // Находим элемент с соответствующим айди
        const commentElement = document.querySelector(
            `#comment_${commentId} .comment-body`
        );
        const likeElement = document.querySelector(
            `#comment_${commentId} .comment-body .like-button`
        );

        // Если элемент найден, добавляем ему класс .bg-disabled на 3 секунды
        if (commentElement) {
            commentElement.classList.add("bg-disabled");
            likeElement.classList.add("bg-disabled");

            setTimeout(() => {
                commentElement.classList.remove("bg-disabled");
                likeElement.classList.remove("bg-disabled");
            }, 3000); // Удаляем класс через 3 секунды
        }
    }
});

// Делегирование событий для элементов с data-srcset
document.addEventListener("click", function (event) {
    if (event.target && event.target.dataset?.srcset) {
        const element = event.target;
        const srcset = element.dataset.srcset;
        const altText = element.alt;
        const imgElement = document.createElement("img");
        imgElement.src = srcset;
        imgElement.alt = altText;
        if (event.target.closest(".gallery")) {
            const images = event.target
                .closest(".gallery")
                .querySelectorAll("img");
            if (images.length > 1) {
                showModal(imgElement, images);
            } else {
                showModal(imgElement);
            }
        } else {
            showModal(imgElement);
        }
    } else if (event.target && event.target.dataset?.mbti) {
        const element = event.target;
        const mbti = element.dataset.mbti;
        if (mbti) {
            console.log(mbti);
            showModal(<MBTI type={mbti} />);
        }
    }
});

let root;

// Функция для открытия модального окна
function showModal(el, images = [], title = false) {
    // console.log(el);
    if (!root) {
        root = createRoot(document.getElementById("modalInput"));
    } else {
        root.unmount();
        root = createRoot(document.getElementById("modalInput"));
    }

    const content = React.isValidElement(el) ? (
        <div dangerouslySetInnerHTML={{ __html: renderToString(el) }} />
    ) : (
        <div>{el.innerHTML}</div>
    );

    // console.log(content);

    root.render(
        <ReusableModal
            content={
                el.tagName === "IMG" ? (
                    <img
                        className="w-100"
                        src={el.src}
                        alt={el.alt}
                        style={{ maxHeight: "90vh", maxWidth: "fit-content" }}
                    />
                ) : (
                    content
                )
            }
            show={true}
            onClose={() => {}}
            header={title ? true : false}
            footer={false}
            large={true}
            images={images}
            title={title}
        />
    );
}

document.addEventListener("click", function (event) {
    // console.log(event.target);

    if (event.target && event.target.closest("#stena_settings")) {
        root = createRoot(document.getElementById("popoverInput"));
        root.render(
            <Provider store={store}>
                <SettingsPopover target={event.target} show={true} />
            </Provider>
        );
    }
    if (event.target && event.target.closest(".sk-notification-icon")) {
        root = createRoot(document.getElementById("popoverInput"));
        root.render(
            <Provider store={store}>
                <Notifications
                    target={event.target}
                    show={true}
                    id={Notifications.sender_id}
                />
            </Provider>
        );
    }
});

document.addEventListener("click", function (event) {
    //костыль: устраняем странный косяк с работой категории suggested в смайлах
    const emojis = document.querySelector(".epr-body");
    if (emojis) {
        const smiles = emojis.querySelectorAll(
            `[data-name="smileys_people"]`
        )[0];
        if (smiles) {
            const smilesClasses = smiles.className;
            const suggestedInLocalStorage = JSON.parse(
                localStorage.getItem("epr_suggested")
            );
            const suggestedSmiles = emojis.querySelectorAll(
                `[data-name="suggested"]`
            )[0];
            if (suggestedSmiles && suggestedInLocalStorage) {
                suggestedSmiles.className = smilesClasses;
            }
        }
    }
});

const subscribeBtn = document.getElementById("subscribe-btn");
if (subscribeBtn) {
    root = createRoot(subscribeBtn);
    root.render(
        <Provider store={store}>
            <SubscribeButton />
        </Provider>
    );
}

const App = () => {
    const [hasMore, setHasMore] = useState(true); // Есть ли еще данные для загрузки
    const [showStenaComponent, setShowStenaComponent] = useState(false); // Флаг, чтобы отслеживать открытие
    const dispatch = useDispatch();
    // const { users } = useSelector((state) => state.users);
    // const { currentUser } = useSelector((state) => state.users);
    const { stenas } = useSelector((state) => state.stenas);
    const pageInUseSelector = useSelector((state) => state.stenas.page);
    const [page, setPage] = useState(pageInUseSelector || 0);
    const [fetchFlag, setFetchFlag] = useState(true);

    useEffect(() => {
        if (stenas.length) {
            setShowStenaComponent(true);
        }
    }, [stenas]);

    useEffect(() => {
        const fetchUsersData = async () => {
            await dispatch(fetchUsers());
            await dispatch(fetchCurrentUserId());
            await dispatch(fetchExtraTypes());
        };
        fetchUsersData();

        if (fetchFlag) {
            fetchData();
            setFetchFlag(false);
        }
    }, [dispatch, fetchFlag]);

    const fetchData = async () => {
        try {
            const res = await dispatch(fetchMoreData(page + 1, hasMore));
            const { data } = res.payload;
            if (data.length) {
                if (data.length === 0 || data.length < 10) {
                    // Предположим, что каждая страница содержит 10 записей
                    setHasMore(false); // Если данных нет, останавливаем загрузку
                }
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {}
    };

    useEffect(() => {
        const stenaDiv = document.getElementById("stena");
        // Проверяем, существует ли уже корень для stenaDiv
        if (stenaDiv && !stenaDiv._reactRootContainer) {
            const stenaRoot = createRoot(stenaDiv);
            // Сохраняем ссылку на корень в свойстве DOM элемента для последующего доступа
            stenaDiv._reactRootContainer = stenaRoot;
        }
        if (stenaDiv) {
            const filter = stenaDiv.getAttribute("data-filter")
                ? JSON.parse(stenaDiv.getAttribute("data-filter"))
                : null;

            if (showStenaComponent) {
                // Создаем корень React и рендерим компонент внутри div#stena
                stenaDiv._reactRootContainer.render(
                    <>
                        {stenas.length ? (
                            <>
                                <Provider store={store}>
                                    <InfiniteScrollComponent
                                        hasMore={hasMore}
                                        fetchMoreData={() => setFetchFlag(true)}
                                        filter={filter}
                                    />
                                </Provider>
                            </>
                        ) : (
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                                <StenaPlaceholder key={i} />
                            ))
                        )}
                    </>
                );
            } else {
                stenaDiv._reactRootContainer.render(
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                        <StenaPlaceholder key={i} />
                    ))
                );
            }
        }
    }, [showStenaComponent]);
};

export default App;

const appContainer = document.getElementById("app");
if (appContainer) {
    const appRoot = createRoot(appContainer);
    appRoot.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    );
}
