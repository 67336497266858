import React from "react";
import GratitudeSVG from "../Icons/GratitudeSVG";
import GiftSVG from "../Icons/GiftSVG";
import BadgeSVG from "../Icons/BadgeSVG";
import RewardSVG from "../Icons/RewardSVG";
import RandomSVG from "../Icons/RandomSVG";

function BadgeComponent({ extraTypes, center = true }) {
    let badgeStyle = {};
    let badgeText = "";

    switch (extraTypes) {
        case "random":
            badgeStyle = { backgroundColor: "#BFBFBF" };
            badgeText = (
                <>
                    <RandomSVG />
                    <span className="ps-1 d-none d-md-inline">
                        Случайная благодарность
                    </span>
                </>
            );
            break;
        case "thanks":
            badgeStyle = { backgroundColor: "#F7B801" };
            badgeText = (
                <>
                    <GratitudeSVG />
                    <span className="ps-1 d-none d-md-inline">
                        Благодарность
                    </span>
                </>
            );
            break;
        case "gift":
            badgeStyle = { backgroundColor: "#F18701" };
            badgeText = (
                <>
                    <GiftSVG />
                    <span className="ps-1 d-none d-md-inline">Подарок</span>
                </>
            );
            break;
        case "reward":
            badgeStyle = { backgroundColor: "#7678ED" };
            badgeText = (
                <>
                    <RewardSVG />
                    <span className="ps-1 d-none d-md-inline">Медаль</span>
                </>
            );
            break;
        case "badge":
            badgeStyle = { backgroundColor: "#5F55B1" };
            badgeText = (
                <>
                    <BadgeSVG />
                    <span className="ps-1 d-none d-md-inline">Бейдж</span>
                </>
            );
            break;
        case "bage":
            badgeStyle = { backgroundColor: "#5F55B1" };
            badgeText = (
                <>
                    <BadgeSVG />
                    <span className="ps-1 d-none d-md-inline">Бейдж</span>
                </>
            );
            break;
        default:
            break;
    }

    return (
        <div className={"d-flex " + center ? "align-items-center" : ""}>
            <span className="badge text-18 fw-normal fs-6" style={badgeStyle}>
                {badgeText}
            </span>
        </div>
    );
}

export default BadgeComponent;
