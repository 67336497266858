const RedikSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group 1">
                <path
                    id="Vector"
                    d="M8.00048 15.9999C12.4062 15.9999 16 12.4061 16 7.99946C16 3.59278 12.4062 0 8.00048 0C3.59474 0 0 3.59375 0 7.99946C0 12.4052 3.59377 15.9999 8.00048 15.9999Z"
                    fill="#AB091A"
                />
                <path
                    id="Vector_2"
                    d="M8.00048 0C3.59377 0 0 3.59375 0 7.99946C0 12.4052 3.59377 15.9999 8.00048 15.9999C12.4072 15.9999 16 12.4061 16 7.99946C16 3.59278 12.4062 0 8.00048 0ZM8.00048 14.1069C4.63636 14.1069 1.89297 11.3635 1.89297 7.99943C1.89297 4.63532 4.63636 1.89196 8.00048 1.89196C11.3646 1.89196 14.108 4.63629 14.108 7.99943C14.108 11.3626 11.3636 14.1069 8.00048 14.1069Z"
                    fill="#BF1B2C"
                />
                <path
                    id="Vector_3"
                    d="M2.34816 2.34718L3.68539 3.68438C4.79174 2.57802 6.31852 1.892 8.00052 1.892V0C5.79739 0 3.79742 0.89826 2.34816 2.34718ZM13.6528 13.6517L12.3156 12.3145C11.2091 13.421 9.68213 14.1069 8.00045 14.1069V15.9999C10.2035 15.9999 12.2036 15.1013 13.6528 13.6518L13.6528 13.6517ZM13.6524 2.3476L12.3154 3.68458C13.4219 4.79103 14.108 6.31789 14.108 7.99946H16C16 5.79662 15.1015 3.79675 13.6524 2.3476ZM2.34771 13.6522L3.68516 12.3147C2.57881 11.2083 1.89294 9.68149 1.89294 7.99943H0C0 10.2028 0.898452 12.2029 2.34771 13.6522Z"
                    fill="#D85050"
                />
                <path
                    id="Vector_4"
                    d="M10.5755 11.0308H5.41479C5.29998 11.0308 5.18832 10.9933 5.09674 10.9241C5.00517 10.8548 4.93869 10.7576 4.90741 10.6472L3.93304 7.20769C3.90757 7.11776 3.90646 7.02268 3.92982 6.93219C3.95318 6.84169 4.00017 6.75903 4.06597 6.69266C4.13178 6.62629 4.21404 6.5786 4.30433 6.55448C4.39463 6.53035 4.48971 6.53065 4.57985 6.55535L6.17491 6.99263L7.58335 5.17205C7.63263 5.10836 7.69585 5.0568 7.76815 5.02132C7.84045 4.98585 7.91991 4.96741 8.00045 4.96741C8.08098 4.96741 8.16045 4.98585 8.23275 5.02132C8.30505 5.0568 8.36826 5.10836 8.41755 5.17205L9.82599 6.99263L11.421 6.55535C11.5112 6.53059 11.6065 6.53029 11.6969 6.5545C11.7873 6.57872 11.8696 6.62656 11.9354 6.69312C12.0013 6.75968 12.0482 6.84256 12.0713 6.93324C12.0945 7.02393 12.0932 7.11915 12.0674 7.20913L11.0825 10.6486C11.051 10.7587 10.9844 10.8556 10.8929 10.9245C10.8015 10.9935 10.69 11.0308 10.5755 11.0308Z"
                    fill="#D85050"
                />
            </g>
        </svg>
    );
};
export default RedikSVG;
