const Dog = ({ handleClick }) => {
    return (
        <svg
            onClick={handleClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
        >
            <path
                d="M14.5833 15.937C13.3159 16.9169 11.726 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.5833 12.5 14.5833 12.5 11.6667C12.5 10 12.5 6.66667 12.5 6.66667"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 9.99935V8.74935C12.5 7.59876 11.3807 6.66602 10 6.66602C8.61929 6.66602 7.5 7.59876 7.5 8.74935V9.99935"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 10.0007V11.2507C12.5 12.4012 11.3807 13.334 10 13.334C8.61929 13.334 7.5 12.4012 7.5 11.2507V10.0007"
                stroke="#BFBFBF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default Dog;
