import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "../axios/middleware";

// Создаем асинхронное действие для загрузки данных
export const fetchMoreData = createAsyncThunk(
    "stenas/fetchMoreData",
    async (page, { getState }) => {
        try {
            const state = getState();
            const hasMore = state.stenas.hasMore;
            const response = await axios.get(`/stenas/paginate?page=${page}`);
            const { data } = response.data;

            if (data.length === 0 || data.length < 10) {
                // Предположим, что каждая страница содержит 10 записей
                return { data, hasMore: false };
            }

            return { data, hasMore, page };
        } catch (error) {
            throw error;
        }
    }
);

export const addComment = createAction("stenas/addComment");
export const addStena = createAction("stenas/addStena");
export const addLike = createAction("stenas/addLike");
export const removeLike = createAction("stenas/removeLike");
export const addFilter = createAction("stenas/addFilter");

const initialState = {
    stenas: [], // Данные стены (с фильтрацией)
    allStenas: [], // Данные стены без фильтрации
    page: 0, // Текущая страница
    hasMore: true, // Флаг, указывающий, есть ли еще данные для загрузки
    loading: false, // Флаг загрузки
    error: null, // Ошибка (если есть)
    filters: localStorage.getItem("settings")
        ? JSON.parse(localStorage.getItem("settings"))
        : [
              {
                  id: 1,
                  name: "Случайные благодарности",
                  type: "random",
                  checked: true,
              },
              {
                  id: 2,
                  name: "Медали",
                  type: "reward",
                  checked: true,
              },
              {
                  id: 3,
                  name: "Бейджи",
                  type: "badge",
                  checked: true,
              },
              {
                  id: 4,
                  name: "Подарки",
                  type: "gift",
                  checked: true,
              },
              {
                  id: 5,
                  name: "Благодарности",
                  type: "thanks",
                  checked: true,
              },
              {
                  id: 6,
                  name: "Отправлять сообщения с Ctrl+Enter",
                  checked: true,
              },
          ],
};

const stenasSlice = createSlice({
    name: "stenas",
    initialState,
    reducers: {
        setStenas: (state, action) => {
            const { stenas, filters } = action.payload;
            state.allStenas = stenas;
            state.filters = filters;
            state.stenas = applyFilters(stenas, filters);
        },
        addFilter: (state, action) => {
            const { data } = action.payload || [];
            state.filters = toPlainArray(data);
            state.stenas = applyFilters(state.allStenas, toPlainArray(data));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMoreData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchMoreData.fulfilled, (state, action) => {
                state.loading = false;
                const filters = toPlainArray(state.filters); // Преобразуем Proxy в обычный массив
                if (action.payload.page === 1) {
                    // Если это первая страница, просто устанавливаем новые данные
                    state.allStenas = action.payload.data;
                    state.stenas = applyFilters(action.payload.data, filters);
                } else if (action.payload.page > state.page) {
                    // Если это не первая страница, добавляем новые данные к существующим
                    state.allStenas = state.allStenas.concat(
                        action.payload.data
                    );
                    state.stenas = applyFilters(state.allStenas, filters);
                    state.page = action.payload.page;
                }
                state.hasMore = action.payload.hasMore;
            })
            .addCase(fetchMoreData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.hasMore = false;
            })
            .addCase(addStena, (state, action) => {
                const { data } = action.payload;
                state.stenas = [
                    {
                        ...data.stena,
                        user: data.user,
                        formattedDateTime: data.stenaformattedDateTime,
                        likes: [],
                        comments: [],
                    },
                    ...state.stenas,
                ];
                state.allStenas = state.stenas;
                state.stenas = applyFilters(state.stenas, state.filters);
            })
            .addCase(addComment, (state, action) => {
                const { data } = action.payload;
                const post = state.stenas.find(
                    (post) => post.id * 1 === data.commentableId * 1
                );
                if (post) {
                    post.comments.push({
                        ...data.comment,
                        author: data.authorName,
                        attachments: data.attachments,
                        formattedDateTime: data.formattedDateTime,
                    });
                }
            })
            .addCase(addLike, (state, action) => {
                const { data } = action.payload;

                if (data) {
                    if (data.type === "Stena") {
                        const post = state.stenas.find(
                            (p) => p.id * 1 === data.id * 1
                        );
                        if (post) {
                            post.likes.push({
                                ...data,
                                id: data.id,
                                user_id: data.userid,
                                likeable_type: "App\\Models\\Stena",
                                likeable_id: data.id,
                            });
                        }
                    } else if (data.type === "Comment") {
                        let comment;
                        for (let i = 0; i < state.stenas.length; i++) {
                            const p = state.stenas[i];
                            const c = p.comments.find(
                                (c) => c.id * 1 === data.id * 1
                            );
                            if (c) {
                                comment = c;
                                break;
                            }
                        }
                        if (comment) {
                            comment.likes.push({
                                ...data,
                                id: data.id,
                                user_id: data.userid,
                                likeable_type: "App\\Models\\Stena",
                                likeable_id: data.id,
                            });
                        }
                    }
                }
            })
            .addCase(removeLike, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    stenas: state.stenas.map((post) => {
                        if (
                            post.id * 1 === action.payload.data.id * 1 &&
                            action.payload.data.type === "Stena"
                        ) {
                            return {
                                ...post,
                                likes: post.likes.filter(
                                    (like) =>
                                        like.likeable_id !==
                                            action.payload.data.id &&
                                        like.user_id !==
                                            action.payload.data.userid
                                ),
                            };
                        } else {
                            const updatedComments = post.comments.map(
                                (comment) => {
                                    if (
                                        comment.id * 1 ===
                                            action.payload.data.id * 1 &&
                                        action.payload.data.type === "Comment"
                                    ) {
                                        return {
                                            ...comment,
                                            likes: comment.likes.filter(
                                                (like) =>
                                                    like.likeable_id !==
                                                        action.payload.data
                                                            .id &&
                                                    like.user_id !==
                                                        action.payload.data
                                                            .userid
                                            ),
                                        };
                                    } else {
                                        return comment;
                                    }
                                }
                            );

                            return {
                                ...post,
                                comments: updatedComments,
                            };
                        }
                    }),
                };
            })
            .addCase(addFilter, (state, action) => {
                const { data } = action.payload || [];

                function filterStenas(stena) {
                    const filter = data.find(
                        (f) => f.type === stena.extra_types && f.checked
                    );
                    return filter;
                }

                // Если нет активных фильтров, вернуть все посты
                if (data.every((f) => !f.checked)) {
                    state.stenas = state.allStenas;
                } else {
                    state.stenas = state.allStenas.filter((stena) =>
                        filterStenas(stena)
                    );
                }
            });
    },
});

function applyFilters(stenas, filters) {
    // Преобразуем все элементы в обычные объекты
    stenas = JSON.parse(JSON.stringify(stenas));
    filters = JSON.parse(JSON.stringify(filters)).filter((f) => f.type);
    filters.push({
        id: 6,
        name: "Бейджи",
        type: "bage",
        checked: filters.find((f) => f.type === "badge").checked,
    });

    // Если нет активных фильтров, вернуть все посты
    if (filters.every((f) => !f.checked)) {
        return stenas.filter((stena) => !stena.extra_types);
    }

    // Фильтруем записи, если хотя бы один фильтр активен
    return stenas.filter((stena) => {
        return (
            !stena.extra_types || // Включаем посты без extra_types
            filters.some((filter) => {
                return (
                    stena.extra_types &&
                    stena.extra_types.toLowerCase() ===
                        filter.type.toLowerCase() &&
                    filter.checked
                );
            })
        );
    });
}

function toPlainArray(proxyArray) {
    return JSON.parse(JSON.stringify(proxyArray));
}

export const selectStenas = (state) => state.stenas.stenas;
export default stenasSlice.reducer;
