import axios from "axios";

const getExpireDate = () => {
    const now = new Date();
    now.setHours(now.getHours() + 24);
    return now;
};

axios.interceptors.request.use(
    async (config) => {
        const storageKey = `${config.url}_data`; // Ключ для локального хранилища
        const storedData = JSON.parse(localStorage.getItem(storageKey));

        if (storedData && storedData.expire_date) {
            const expireDate = new Date(storedData.expire_date);
            if (expireDate > new Date()) {
                // Если срок действия не истек, добавляем expire_date к запросу
                config.params = {
                    ...config.params,
                    expire_date: storedData.expire_date,
                };
            } else {
                // Если срок действия истек, удаляем данные из хранилища
                localStorage.removeItem(storageKey);
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Функция для обработки ответа от сервера
axios.interceptors.response.use(
    (response) => {
        if (
            response.config.url &&
            response.data.message !== "Данные не изменились"
        ) {
            // Если сервер вернул новые данные, сохраняем их в локальном хранилище
            const storageKey = `${response.config.url}_data`;
            const dataToStore = {
                data: response.data,
                expire_date: getExpireDate().toISOString(), // Сохраняем срок действия
            };
            // localStorage.setItem(storageKey, JSON.stringify(dataToStore));
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
